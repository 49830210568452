import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cropper from 'react-easy-crop';
import Slider from '@material-ui/core/Slider';
import Button from "@material-ui/core/Button";
import axios, { doGet, doPut } from "../../../axios-main";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ImageComponent from "../../../components/UI/ImageComponent/index";
import { MdRotateLeft, MdRotateRight } from "react-icons/md";
import { getToken } from "../../functions";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import Error from "../Alert/Error";
import LinearProgress from '@material-ui/core/LinearProgress';

class comp extends Component {
    state = {
        uploading: false,
        uploaded: 0,
        file_size: 0,
        loading: false,
        hasFile: false,
        image: null,
        crop: { x: 0, y: 0 },
        zoom: 1.5,
        aspect: 1,
        rotation: 0,
        readyImage: null,
        croppedAreaPixels: null,
        extra_large: null,
        identity: {},
        large: null,
        medium: null,
        small: null,
        upload: null,
        enabled: false,
        message: '',
    };

    reset = () => {
        this.setState({
            ...this.state,
            uploading: false,
            uploaded: 0,
            file_size: 0,
            loading: false,
            hasFile: false,
            image: null,
            crop: { x: 0, y: 0 },
            zoom: 1.5,
            aspect: 1,
            rotation: 0,
            readyImage: null,
            croppedAreaPixels: null,
            extra_large: null,
            identity: {},
            large: null,
            medium: null,
            small: null,
            upload: null,
            enabled: false,
            message: '',
        })
    };

    select_file = () => {
        document.getElementById("image_upload").click();
    };

    handleChange = (ev) => {
        if (this.props.can_update) {
            this.handleFile(ev.target.files[0]);
        }
    }

    showCroppedImage = async () => {
        try {
            const croppedImage = await this.getCroppedImg(
                this.state.image,
                this.state.croppedAreaPixels,
                this.state.rotation
            );

            this.submitFile(croppedImage);
            this.setState({ ...this.state, readyImage: croppedImage });
        } catch (e) {
            console.error(e)
        }
    };

    rotate_right = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, rotation: this.state.rotation + 90 });
    }

    rotate_left = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, rotation: this.state.rotation - 90 });
    }

    cancelCroppedImage = (event) => {
        event.stopPropagation();
        this.reset();
    }

    updateUploadProgress(uploaded) {
        this.setState({ ...this.state, uploaded: uploaded });
    }

    submitFile(croppedImage) {
        this.setState({ ...this.state, uploading: true });

        setTimeout(() => {
            let params = { include_upload: this.props.can_update }
            const { extras } = this.props
            params = { ...params, ...extras }

            const qs = Object.keys(params).map(k => k + '=' + params[k]).join('&')

            doGet(this.props.token, this.props.url + '?' + qs, (r) => {
                let salt = r.data.salt;
                var options = {
                    headers: {
                        'Content-Type': 'image/png'
                    },
                    onUploadProgress: (progressEvent) => {
                        this.updateUploadProgress(progressEvent.loaded);
                    },
                };

                fetch(croppedImage)
                    .then(res => res.blob())
                    .then(blob => {
                        const file = new File([blob], "upload");

                        this.setState({ ...this.state, file_size: file.size })

                        axios.put(r.data.upload, file, options)
                            .then(res => {
                                let put = { salt: salt };

                                put = { ...put, ...extras }

                                doPut(this.props.token, this.props.url, put, (r) => {
                                    setTimeout(() => {
                                        this.setState({
                                            ...this.state,
                                            extra_large: r.data.extra_large,
                                            identity: r.data.identity,
                                            large: r.data.large,
                                            medium: r.data.medium,
                                            small: r.data.small,
                                            upload: r.data.upload,
                                            image: r.data.extra_large,
                                            hasFile: false,
                                            loading: false
                                        });
                                        this.props.onComplete(r.data);
                                        this.setState({
                                            ...this.state,
                                            uploading: false,
                                            uploaded: 0,
                                            file_size: 0,
                                            loading: false,
                                            crop: { x: 0, y: 0 },
                                            zoom: 1.5,
                                            aspect: 1,
                                            rotation: 0,
                                        })
                                    }, 0);
                                }, (r) => {
                                    this.setState({ ...this.state, uploaded: 0, file_size: 0, uploading: false });
                                    console.log('ERROR 3', r);
                                });
                            })
                            .catch(err => {
                                this.setState({ ...this.state, uploaded: 0, file_size: 0, uploading: false });
                                console.log('ERROR 2', err);
                            });
                    });
            }, (r) => {
                this.setState({ ...this.state, uploaded: 0, file_size: 0, uploading: false });
                console.log('ERROR 1', r);
            });
        }, 0)
    }

    onCropChange = crop => {
        this.setState({ crop });
    }

    onRotate = rotate => {
        this.setState({ ...this.state, rotation: rotate });
    }

    onCropComplete = (croppedArea, croppedAreaPixels) => {
        this.setState({ ...this.state, croppedAreaPixels: croppedAreaPixels });

        try {
            this.checkImage();
        } catch (e) {
            console.log(e);
        }
    }

    checkImage = async () => {
        try {
            const croppedImage = await this.getCroppedImg(
                this.state.image,
                this.state.croppedAreaPixels,
                this.state.rotation
            );

            const image = await this.createImage(croppedImage);

            if (image) {
                if ((image.width < 100) || (image.height < 100)) {
                    this.setState({ ...this.state, enabled: false, message: 'Please zoom out.' });
                } else if ((image.width > 20000) || (image.height > 20000)) {
                    this.setState({ ...this.state, enabled: false, message: 'Please zoom in.' });
                } else {
                    this.setState({ ...this.state, enabled: true, message: '' });
                }
            }
        } catch (e) {
            setTimeout(() => {
                this.checkImage();
            }, 500);
        }
    }

    onZoomChange = async zoom => {
        this.setState({ zoom })
        console.log(zoom)
    }

    createImage = url =>
        new Promise((resolve, reject) => {
            const image = new Image()
            image.addEventListener('load', () => resolve(image))
            image.addEventListener('error', error => reject(error))
            image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
            image.src = url
        });

    getRadianAngle = (degreeValue) => {
        return (degreeValue * Math.PI) / 180
    }

    getCroppedImg = async (imageSrc, pixelCrop, rotation) => {
        const image = await this.createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const safeArea = Math.max(image.width, image.height) * 2;

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea
        canvas.height = safeArea

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2)
        ctx.rotate(this.getRadianAngle(rotation))
        ctx.translate(-safeArea / 2, -safeArea / 2)

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width * 0.5,
            safeArea / 2 - image.height * 0.5
        )
        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
            0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
        );

        // As Base64 string
        // return canvas.toDataURL();

        // As a blob
        return new Promise(resolve => {
            canvas.toBlob(file => {
                resolve(URL.createObjectURL(file))
            })
        })
    };

    handleFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ ...this.state, hasFile: true, image: reader.result });
        };
        reader.onerror = error => console.log('ERROR IMAGE', error);
    }

    handleDrop = (e) => {
        this.setState({ ...this.state, error: null });
        let file = null;
        for (let i = 0; i < e.length; i++) {
            if (!file && (e[i].type === 'image/jpeg' ||
                e[i].type === 'image/jpg' ||
                e[i].type === 'image/gif' ||
                e[i].type === 'image/png')) {
                file = e[i];
            }
        }

        if (file) {
            this.handleFile(file);
        } else {
            this.setState({ ...this.state, error: 'Please upload a png, jpg or gif type image.' });
        }
    }

    load_image() {

        let params = { include_upload: this.props.can_update }
        const { extras } = this.props
        params = { ...params, ...extras }

        const qs = Object.keys(params).map(k => k + '=' + params[k]).join('&')

        this.setState({
            ...this.state,
            loading: false,
        });

        doGet(this.props.token, this.props.url + "?" + qs,
            s => {
                this.setState({
                    ...this.state,
                    extra_large: s.data.extra_large,
                    identity: s.data.identity,
                    large: s.data.large,
                    medium: s.data.medium,
                    small: s.data.small,
                    upload: s.data.upload,
                    image: s.data.extra_large,
                    loading: false
                });
            },
            e => {
                console.log('ERROR', e);
                console.log('ERROR', e.response);
                console.log('ERROR', e.response.data);
                // this.load_image();
            });
    }

    componentDidMount() {
        this.load_image();
    }

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let form = (
            <DragAndDrop text_color={'red'} background_color={'black'}
                style={{ padding: '5px 0' }} handleDrop={this.handleDrop}>
                {error}
                <input id="image_upload" style={{ display: 'none' }} onChange={this.handleChange} type="file"
                    accept="image/gif, image/jpeg, image/png" />

                <div style={{ padding: '5px 0' }}>
                    {this.props.can_update && <Button variant="contained" color="primary"
                        onClick={this.select_file}> Upload New Image </Button>}
                </div>

                {!this.state.loading && <ImageComponent first={this.props.first_name} last={this.props.last_name} image_url={this.state.large} size={400} theme={this.props.theme} />}
            </DragAndDrop>
        )

        const is = 25;
        let color;
        if (this.props.text_color) {
            color = this.props.text_color.main;
        }

        let progress = 0

        if (this.state.uploaded && this.state.file_size) {
            progress = (this.state.uploaded / this.state.file_size) * 100
        }

        if (this.state.hasFile) {
            form = (
                <div>
                    <div style={{ display: 'flex', padding: '10px 0' }}>
                        <div style={{ flex: '1 1 auto', textAlign: "center", position: "relative", width: "90%", height: "60vh" }}>
                            <Cropper
                                image={this.state.image}
                                crop={this.state.crop}
                                zoom={this.state.zoom}
                                aspect={this.state.aspect}
                                rotation={this.state.rotation}
                                minZoom={0.5}
                                restrictPosition={false}
                                onCropChange={this.onCropChange}
                                onCropComplete={this.onCropComplete}
                                onZoomChange={this.onZoomChange}
                                onRotationChange={this.onRotate}
                                showGrid={false}
                                maxZoom={5}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textAlign: 'center', padding: 10 }}>
                            <MdRotateLeft size={is} color={color} style={{ paddingTop: 0, cursor: 'pointer' }}
                                onClick={this.rotate_left} />
                        </div>
                        <div style={{ flex: 1, width: "100%", alignItems: 'center' }}>
                            <Slider onChange={(event, zoom) => {
                                this.onZoomChange(zoom)
                            }}
                                value={this.state.zoom}
                                min={0.5}
                                max={6}
                                step={0.1} />
                        </div>
                        <div style={{ textAlign: 'center', padding: 10 }}>
                            <MdRotateRight size={is} color={color} style={{ paddingTop: 0, cursor: 'pointer' }}
                                onClick={this.rotate_right} />
                        </div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {(!this.state.uploading) && <Button variant="contained" color="secondary"
                            onClick={this.cancelCroppedImage}> CANCEL </Button>}
                        {(!this.state.uploading && this.state.enabled) && <Button style={{ marginLeft: 10 }} variant="contained" color="primary"
                            onClick={this.showCroppedImage}> SUBMIT </Button>}
                        <div style={{ marginLeft: 10, flex: '1 1 auto' }}>
                            {this.state.message && <div style={{ color: 'red', padding: 5, flex: '1 1 auto' }}>1{this.state.message}2</div>}
                            {(this.state.uploading) &&
                                <LinearProgress style={{ width: '100%' }} variant="determinate" value={progress} />
                            }
                        </div>
                    </div>
                </div>
            )
        }

        if (this.state.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(comp);
