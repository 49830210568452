import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    team_member: null,
    loading: false
};

const selectMemberMemberReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        member_team: action.member_team,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_MEMBER_TEAM:
            return selectMemberMemberReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
