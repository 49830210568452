import React, {Component} from 'react';
import {connect} from "react-redux";

import {withRouter} from "react-router-dom";

class chat extends Component {
    render() {
        return (
            <div>
                <h1>CHATS</h1>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(chat));
