import React, { Component } from 'react'
import { connect } from "react-redux"
import * as actions from "../../../../../store/actions"
import { doPost } from '../../../../../axios-main';
import {
    FaAngleDoubleRight,
    FaAngleRight,
    FaArrowLeft,
    FaArrowRight,
    FaFastBackward,
    FaForward,
    FaPause,
    FaPlay,
    FaVolumeUp,
    FaVolumeMute,
    FaStop,
    FaUndo,
    FaArrowCircleRight,
} from "react-icons/fa"

import styles from './VideoFile.module.css'
import { getToken, pushPath, getLocalDate } from "../../../../../components/functions"
import { withRouter } from "react-router-dom"

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class view extends Component {
    _isMounted = false
    _seeking = false
    _pauseStart = 0
    _playStart = 0
    _pauseTime = 0
    _playTime = 0

    state = {
        video_file_id: null,
        video_file: null,
        video_url: null,
        play: false,
        play_label: "Play",
        timeout: 0,
        start: 0,
        src: '',
        duration: 0,
        playback: 'stop',
        playbackEl: null,
        fit: true,
        video_width: '100%',
        loading: true,
        selected: 0,
    }

    constructor(props, context) {
        super(props, context)

        this.play = this.play.bind(this)
    }

    selectedChange = (event) => {
        event.preventDefault();
        this.setState({ ...this.state, selected: event.target.value });
    }

    selectedSubmit = (event) => {
        event.preventDefault();
        this.props.selected(this.state.selected)
    }

    onPlayback = (playback) => {
        this.setState({ ...this.state, playback: playback, playbackEl: null });
        localStorage.setItem('shwa-playback', playback)
    }

    onPlaybackMenu = (event) => {
        this.setState({ ...this.state, playbackEl: event.currentTarget });
    }

    onPlaybackMenuClose = () => {
        this.setState({ ...this.state, playbackEl: null });
    };

    getContainerHeight = () => {
        if (this.state.video_file?.height > this.state.video_file?.width) {
            return window.innerHeight
        } else {
            return null
        }
    }

    updateViewStats = () => {
        let pause = (this._pauseTime / 1000)
        let play = (this._playTime / 1000)

        if (pause > 0 || play > 0) {
            doPost(this.props.token, '/videos/stats', {
                date: getLocalDate(new Date()),
                pause_seconds: pause,
                play_seconds: play,
                video_id: this.props.match.params.video_id,
                season_index: this.props.season.season_index
            },
                (s) => {
                    this._playTime -= (play * 1000);
                    if (this._playTime < 0) {
                        this._playTime = 0;
                    }
                    this._pauseTime -= (pause * 1000);
                    if (this._pauseTime < 0) {
                        this._pauseTime = 0;
                    }
                },
                (e) => {
                    console.log(e)
                })
        }
    }

    componentWillUnmount() {
        this._isMounted = false

        this.updateViewStats();
        clearInterval(this._interval)
    }

    componentDidMount() {
        this._isMounted = true

        this.setVideo(this.props.match.params.video_file_id)

        this._interval = setInterval(() => this.updateViewStats(), 60000)

        this.setState({ ...this.state, playback: localStorage.getItem('shwa-playback') ? localStorage.getItem('shwa-playback') : 'stop', selected: Number(this.props.match.params.video_file_id) + 1 })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.video_file_id !== prevProps.match.params.video_file_id) {
            if (this._isMounted) {
                this.setVideo(this.props.match.params.video_file_id)
            }
        }
    }

    calculate_progress() {
        const s = isNaN(this.state.start) ? 0 : this.state.start
        let t = isNaN(this.player.currentTime) ? 0 : this.player.currentTime

        if (t < (s / 1000)) {
            t = s / 1000
            this.player.play()
            this.setState({ ...this.state, 'play': true })
        }

        const cd = isNaN(this.player.duration) ? 0 : this.player.duration
        const p = (t - s)
        const r = cd - p

        this.setState({ ...this.state, current: this.player.currentTime, progress: { used: `${(p / cd) * 100}`, remaining: `${(r / cd) * 100}` } })
    }

    read_time = (src) => {
        setTimeout(() => {
            if (this.player && this.player.src.trim() === src.trim()) {
                const s = this.start
                if (this.player.currentTime < s / 1000) {
                    this.player.currentTime = s / 1000
                    this.player.play()
                    this.setState({ ...this.state, 'play': true })
                }

                this.calculate_progress()

                const e = this.player.duration
                if (this.player.currentTime >= e) {
                    if (this.state.playback === 'loop') {
                        this.player.currentTime = 0
                        this.player.play()
                        this.setState({ ...this.state, 'play': true })
                        this.read_time(src)
                    }

                    if (this.state.playback === 'stop') {
                        this.player.pause()
                        this.setState({ ...this.state, 'play': false })
                    }

                    if (this.state.playback === 'next') {
                        this.props.next(null)
                    }
                } else {
                    if (this.state.play || this.state.playing) {
                        setTimeout(() => {
                            this.read_time(src)
                        }, 24)
                    }
                }
            } else {
                console.log('src does not match...DONE')
                this.calculate_progress()
            }
        }, 0)
    }

    clear = () => {
        this.setState({ ...this.state, 'video_url': null })
    }

    setVideo = (video_file_id) => {
        this.props.video.files = this.props.video.files.sort((a, b) => {
            return (b.sort) > a.sort ? -1 : 1
        })

        this.setState({ ...this.state, video_width: this.player.clientWidth, video_height: this.player.clientHeight })
        let i = setInterval(() => {
            if (this.props.video.video_id === this.props.match.params.video_id) {
                clearInterval(i)

                let vf

                if (video_file_id) {
                    vf = this.props.video.files[video_file_id]
                } else {
                    if (this.props.video.files && this.props.video.files[0]) {
                        pushPath(this.props, `videos/${this.props.video.video_id}/0`)
                    }
                }

                if (vf) {
                    if (this.player) {
                        this.setTime(0)
                    }

                    const wasPlaying = this.state.play;

                    this.setState({
                        ...this.state,
                        video_url: vf.url,
                        video_file_id: vf.video_file_index,
                        video_file: vf,
                        current: 0,
                        play: false,
                        progress: { used: 0, remaining: 0 },
                        loading: true,
                        selected: Number(video_file_id) + 1
                    })

                    if (wasPlaying) {
                        setTimeout(() => this.play(null), 0);
                    }
                }
            }
        }, 0)
    }

    seek = (e) => {
        e.stopPropagation()
        this.setTime((this.player.duration) * ((e.clientX - this.progress.getBoundingClientRect().left) / this.progress.getBoundingClientRect().width))
    }

    setTime = (time) => {
        // if (this.state.play) {
        //     this.setState({
        //         ...this.state,
        //         play: false,
        //     });
        // }

        this.player.currentTime = time

        setTimeout(() => {
            if (!this.state.play) {
                this.read_time(this.player.src)
            }
        }, 0)
    }

    previous = (e) => {
        // e.stopPropagation()
        // this.props.previous()
        // let index
        // if (this.props.video && this.props.video.files && (this.props.video.files.length > 0)) {
        //     index = this.props.video.files.findIndex(f => Number(f.id) === Number(vf.id))
        //     if (index === 0) {
        //         this.props.selectVideoFile(this.props.video.files[this.props.video.files.length - 1])
        //     } else {
        //         this.props.selectVideoFile(this.props.video.files[index - 1])
        //     }
        // }
    }

    prevent = (e) => {
        e.stopPropagation()
    }

    next = (e) => {
        // e.stopPropagation()

        // this.props.next();
        // let index

        // if (this.props.video && this.props.video.files && (this.props.video.files.length > 0)) {
        //     index = this.props.video.files.findIndex(f => Number(f.video_file_index) === Number(this.state.video_file_id))
        //     if (index === this.props.video.files[this.props.video.files.length - 1]) {
        //         console.log('HERE', index)
        //         this.props.selectVideoFile(this.props.video.files[0])
        //     } else {
        //         console.log('THERE', index)
        //         this.props.selectVideoFile(this.props.video.files[index + 1])
        //     }
        // }
    }

    fullscreen = (e) => {
        e.stopPropagation()
        if (!this.state.fullscreen) {
            this.container.requestFullscreen()
            this.setState({ ...this.state, "fullscreen": true })
        } else {
            document.exitFullscreen()
            this.setState({ ...this.state, "fullscreen": false })
        }
    }

    play = (e) => {
        if (e) {
            e.stopPropagation()
        }

        let currentTime = new Date().getTime()
        let delta

        clearInterval(this.state.interval)
        this.player.playbackRate = 1.0

        if (this.player.paused) {
            if (this._pauseStart > 0) {
                delta = currentTime - this._pauseStart
                if (delta > 0) {
                    if (delta > 300000) {
                        delta = 300000
                    }
                    this._pauseTime += delta
                }
                this._pauseStart = 0
            }

            this._playStart = currentTime
            this.setState({ ...this.state, "play_label": "Pause" })
            this.player.play()
            this.setState({ ...this.state, 'play': true })
        } else {
            if (this._playStart > 0) {
                delta = currentTime - this._playStart
                if (delta > 0) {
                    this._playTime += delta
                }
                this._playStart = 0
            }

            this._pauseStart = currentTime

            this.setState({ ...this.state, "play_label": "Play" })
            this.player.pause()
            this.setState({ ...this.state, 'play': false })
        }

        this.read_time(this.player.src)
    }

    stepBack = (e, r) => {
        if (r <= 0) {
            this.player.currentTime = 0
        } else {
            this.player.currentTime = this.player.currentTime - r
            if (this.player.currentTime <= 0) {
                this.player.currentTime = 0
            }
        }

        this.calculate_progress()
    }

    back = (e, r) => {
        e.stopPropagation()

        clearInterval(this.state.interval)

        this._seeking = false
        let position = this.player.currentTime

        this.player.pause()
        this.player.playbackRate = 1.0

        this.player.onseeked = () => {
            this._seeking = false
        }

        const i = window.setInterval(() => {
            if (position <= r) {
                clearInterval(i)
                this.player.currentTime = 0
            } else {
                if (!this._seeking) {
                    position = position - r
                    this._seeking = true
                    this.player.currentTime = position
                }
            }

            this.calculate_progress()
        }, 85)

        this.setState({ ...this.state, 'interval': i })
    }

    mute = (e) => {
        e.stopPropagation()
        this.player.muted = !this.state.mute
        this.setState({ ...this.state, mute: !this.state.mute })
    }

    fit = (e) => {
        e.stopPropagation()
        let fit = !this.state.fit

        if (fit) {
            this.setState({ ...this.state, fit: fit, video_width: '100%', video_height: null })
        } else {
            this.setState({ ...this.state, fit: fit, video_width: this.state.video_file?.width, video_height: this.state.video_file?.height })
        }
    }

    forward = (e, r) => {
        e.stopPropagation()

        this.setState({ ...this.state, 'playing': true })
        this.player.playbackRate = r
        this.player.play()
        this.player.muted = true
        this.read_time(this.player.src)
    }

    canPlayThrough = (e) => {
        setTimeout(() => {
            this.setState({ ...this.state, loading: false, video_width: '100%', video_height: 'auto' })
        }, 0);
    }

    done(e) {
        e.stopPropagation()
        clearInterval(this.state.interval)

        this.setState({ ...this.state, 'playing': false })
        this.player.playbackRate = 1

        this.player.muted = this.state.mute

        if (this.state.play) {
            this.player.play()
        } else {
            this.player.pause()
        }
    }

    render() {
        const is = 20
        const ic = 'black'

        if (this.state.video_file && this.props.video_file && (this.state.video_file.id !== this.props.video_file.id)) {
            this.clear()
        }

        const play = <FaPlay size={is} color={ic} />
        const pause = <FaPause size={is} color={ic} />

        // const expand = <FaExpand size={is} color={ic} />
        // const compress = <FaCompress size={is} color={ic} />

        const progress = isNaN(this.state.progress?.used) ? 0.001 : this.state.progress.used

        return (
            <div className={styles.Container} ref={container => {
                this.container = container
            }} height={this.getContainerHeight()} width="100%" >
                <div className={styles.VideoInfo}>
                    <div className={'Title'}>{this.props.video.title}&nbsp;&nbsp;</div>
                    <div className={'Occurs'}>{this.props.video.occurred_on}&nbsp;&nbsp;</div>
                    <div className={'Type'}>{this.props.video.event_kind}&nbsp;&nbsp;</div>
                    <div className={'Play'}> Play: {1}&nbsp;&nbsp;</div>
                </div>
                <figure className={styles.Figure} ref={figure => { this.figure = figure }}>
                    <video onLoadStart={this.onLoadStart} onCanPlayThrough={this.canPlayThrough} controls={false} height={this.state.video_height} width={this.state.video_width} ref={player => {
                        this.player = player
                    }} src={this.state.video_url} />
                </figure>
                <div className={styles.ProgressContainer} onClick={(e) => {
                    this.seek(e)
                }} ref={progress => {
                    this.progress = progress
                }}>
                    <div className={styles.Progress}>
                        <div className={styles.ProgressBar} style={{ width: progress + '%' }} />
                    </div>
                    <div className={styles.Controls} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.ControlIcons}>
                            <div className={'Hot'} onClick={(e) => this.stepBack(e, 0)}> <FaFastBackward size={is} color={ic} /> </div>
                            <div className={['Hot', styles.IconTextContainer].join(' ')} onClick={(e) => this.stepBack(e, 3)}>
                                <FaUndo size={is} color={ic} />
                                <div className={styles.IconText}>3</div>
                            </div>
                            <div className={['Hot', styles.IconTextContainer].join(' ')} onClick={(e) => this.stepBack(e, 1)} style={{ position: 'relative' }}>
                                <FaUndo size={is} color={ic} />
                                <div className={styles.IconText}>1</div>
                            </div>
                            <div className={'Hot'} onClick={(e) => this.play(e)}> {this.state.play ? pause : play} </div>
                            <div className={'Hot'} onClick={this.prevent} onMouseDown={(e) => this.forward(e, 0.2)}
                                onMouseUp={(e) => this.done(e)}> <FaAngleRight
                                    size={is} color={ic} /> </div>
                            <div className={'Hot'} onClick={this.prevent} onMouseDown={(e) => this.forward(e, 0.5)}
                                onMouseUp={(e) => this.done(e)}> <FaAngleDoubleRight size={is} color={ic} /> </div>
                            <div className={'Hot'} onClick={this.prevent} onMouseDown={(e) => this.forward(e, 2)}
                                onMouseUp={(e) => this.done(e)}> <FaForward
                                    size={is} color={ic} /> </div>
                        </div>
                    </div>
                </div>
                <div className={styles.ProgressContainer2} onClick={(e) => {
                    this.seek(e)
                }} ref={progress => {
                    this.progress = progress
                }}>
                    <div className={styles.Controls} onClick={(e) => e.stopPropagation()}>
                        <div className={styles.VideoIndex}>
                            {/* {(this.state.fit) && <div className={'Hot'} onClick={this.fit}> <FaExpand size={is} color={ic} /> </div>} */}
                            {/* {(!this.state.fit) && <div className={'Hot'} onClick={this.fit}> <FaCompress size={is} color={ic} /> </div>} */}
                            {(this.state.video_file?.audio && this.state.mute) && <div className={'Hot'} onClick={this.mute}> <FaVolumeMute size={is} color={ic} /> </div>}
                            {(this.state.video_file?.audio && !this.state.mute) && <div className={'Hot'} onClick={this.mute}> <FaVolumeUp size={is} color={ic} /> </div>}
                            <div className={'Hot'} onClick={this.onPlaybackMenu}>
                                {this.state.playback === 'loop' && <FaUndo size={is} color={ic} />}
                                {this.state.playback === 'stop' && <FaStop size={is} color={ic} />}
                                {this.state.playback === 'next' && <FaArrowCircleRight size={is} color={ic} />}
                            </div>
                            <Menu
                                anchorEl={this.state.playbackEl}
                                keepMounted
                                open={Boolean(this.state.playbackEl)}
                                onClose={this.onPlaybackMenuClose}
                            >
                                <MenuItem selected={this.state.playback === 'loop'} onClick={() => this.onPlayback('loop')}><FaUndo size={is} color={ic} />&nbsp;&nbsp;Repeat</MenuItem>
                                <MenuItem selected={this.state.playback === 'stop'} onClick={() => this.onPlayback('stop')}><FaStop size={is} color={ic} />&nbsp;&nbsp;Stop</MenuItem>
                                <MenuItem selected={this.state.playback === 'next'} onClick={() => this.onPlayback('next')}><FaArrowCircleRight size={is} color={ic} />&nbsp;&nbsp;Next</MenuItem>
                            </Menu>
                            <div className={'Hot'} onClick={(e) => this.props.previous(e)}> <FaArrowLeft size={is} color={ic} /> </div>

                            <form onSubmit={this.selectedSubmit}>
                                <input className={styles.SelectedInput} type="text" value={this.state.selected} onChange={this.selectedChange} />
                            </form>

                            <span style={{ fontSize: '0.8rem' }}>&nbsp;of {this.props.total}</span>

                            <div className={'Hot'} onClick={(e) => this.props.next(e)}> <FaArrowRight size={is} color={ic} /> </div>
                        </div>

                        <div className={styles.ControlTime}>{(Math.round((this.state.current * 100)) / 100).toFixed(2)} / {(Math.round(((this.state.video_file?.duration / 1000) * 100)) / 100).toFixed(2)}</div>
                        {/* <div style={{}}>
                            <span
                                onClick={(e) => this.fullscreen(e)}> {this.state.fullscreen ? compress : expand} </span>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        video: state.videos.video,
        video_file: state.videos.video_file,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectVideoFile: (video_file) => dispatch(actions.selectVideoFile(video_file)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(view))
