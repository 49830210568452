import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import List from "../Season/List/List";
import Create from "../Season/Create/Create";
import Detail from "../Season/Detail/Detail";

class Season extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.path}/`} component={List}/>
                <Route path={`${this.props.match.path}/create`} component={Create}/>
                <Route path={`${this.props.match.path}/:season_id`} component={Detail}/>
            </Switch>
        );
    }
}

export default Season;
