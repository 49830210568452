import React, {Component} from 'react';

class Weight extends Component {
    state = {
        pounds: 0,
        ounces: 0,
    };

    componentDidMount() {
        if (this.props.value) {
            if (this.props.value && Number(this.props.value) > 0) {
                const f = Math.floor(Number(this.props.value) / 16);
                const i = Number(this.props.value) - (f * 16);
                this.setState({...this.state, pounds: f, ounces: i});
            }
        }
    }

    render() {
        let v = '';
        if (this.state.pounds) {
            v = v + this.state.pounds + ' lbs ';
        }

        if (this.state.ounces) {
            v = v + this.state.ounces + ' oz ';
        }

        return (
            <div>{v}</div>
        );
    }
}

export default Weight;
