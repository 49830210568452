import React from 'react';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class panel extends React.Component {
    render() {
        return (
            <FormControlLabel
                label={this.props.label}
                control={
                    <Checkbox
                        disabled={this.props.loading}
                        checked={this.props.checked}
                        onChange={this.props.change}
                        inputProps={{
                            'aria-label': 'primary checkbox',
                        }}
                        color='primary'
                    />
                }
            />
        );
    }
}

export default panel;
