import React, { Component } from 'react';
import { connect } from 'react-redux';
import Success from '../../components/UI/Alert/Success';
import Error from '../../components/UI/Alert/Error';
import Spinner from '../../components/UI/Spinner/Spinner';
import * as actions from '../../store/actions/index';
import { authReset } from "../../store/actions/auth";
import logo from "../../assets/images/logo.svg";
import Button from "@material-ui/core/Button";
import Card from "../../components/UI/Card/Card";
import Form from "../../components/UI/Form/Form";
import TextInput from '../../components/UI/TextInput/TextInput';
import { doGet } from "../../axios-main";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { authSuccess } from '../../store/actions/auth';
import { Redirect } from 'react-router-dom';

class ForgotPassword extends Component {
    state = {
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: { blank: false, min_length: 6, max_length: 100 }
        },
        error: '',
        remember_me: false,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        this.props.onReset();

        this.loadMember();
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    loadMember() {
        doGet(this.props.token, '/members/forgot-password?token=' + this.props.match.params.token, r => this.setState({
            ...this.state,
            member: r.data
        }), r => console.log('GET ERROR', r));
    }

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.password.valid) {
            this.setState({ ...this.state, code: { ...this.state.code, message: 'Password is not valid.' } });
        } else {
            this.setState({ ...this.state, code: { ...this.state.code, message: '' } });
            this.props.onForgotPassword(this.props.match.params.token, this.state.password.value, this.state.remember_me);
        }
    };

    goToSignIn = () => {
        this.props.onReset();
        this.props.history.push('/');
    }

    onRememberMe = name => event => {
        this.setState({...this.state, [name]: event.target.checked});
        this.props.onReset();
    };

    render() {
        let form = null;

        form = (
            <div>
                <h4>Please enter the new password.</h4>
                {this.state.member && <div style={{ display: 'flex' }}>
                    <div style={{ flex: '1 1 auto' }}>
                        <img alt={this.state.member.first_name + '-' + this.state.member.last_name} style={{ borderRadius: '50%' }} src={this.state.member.image_url} />
                    </div>
                    <div style={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ flex: '1 1 auto' }}>{this.state.member.first_name} {this.state.member.last_name}</div>
                        <div style={{ flex: '1 1 auto' }}>{this.state.member.user_name}</div>
                        <div style={{ flex: '1 1 auto' }}>{this.state.member.email_address}</div>
                    </div>
                </div>}
                <Form onSubmit={this.submitHandler}>
                    <TextInput info={this.state.password} change={(event, info) => this.onChange(event, info)} />

                    <FormGroup row>
                        <FormControlLabel
                            label="Remember Me"
                            control={
                                <Checkbox
                                    disabled={this.props.loading}
                                    checked={this.state.remember_me}
                                    onChange={this.onRememberMe('remember_me')}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color='primary'
                                />
                            }
                        />
                    </FormGroup>
                    
                    <Button variant="contained" color="primary" onClick={this.submitHandler}> SUBMIT </Button>
                </Form>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        let alert = null;

        if (this.props.success) {
            alert = (
                <Success>{this.props.success}</Success>
            );
            form = null;
        }

        if (this.props.error) {
            alert = (
                <Error>{this.props.error}</Error>
            );
        }

        if (this.props.password) {
            this.props.onUser(this.props.password);
            form = <Redirect to="/"/>
        }

        return (
            <Card>
                <div><img src={logo} alt="SportsHub.io" width={'50%'} /></div>
                {alert}
                {form}
                <Button color="secondary" onClick={this.goToSignIn}> SIGN IN </Button>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        password: state.auth.password,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onReset: () => dispatch(authReset()),
        onForgotPassword: (token, password, remember_me) => dispatch(actions.forgotPassword(token, password, remember_me)),
        onUser: (user) => dispatch(authSuccess(user)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
