import * as actionTypes from './actionTypes';

export const setParticipants = (participants) => {
    return {
        type: actionTypes.SET_PARTICIPANTS,
        participants: participants
    };
};

export const updateParticipant = (participant) => {
    return {
        type: actionTypes.UPDATE_PARTICIPANT,
        participant: participant
    };
};

