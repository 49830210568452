import React from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import Spinner from '../../components/UI/Spinner/Spinner';
import { authReset } from "../../store/actions/auth";
import Form from "../../components/UI/Form/Form";
import FormComponent from "../../components/UI/FormComponent/FormComponent";
import TextInput from "../../components/UI/TextInput/TextInput";
import { doPut } from '../../axios-main';
import { getToken } from '../../components/functions';

class ChangePassword extends FormComponent {
    state = {
        new_password: {
            id: 'new_password',
            label: 'New Password',
            type: 'password',
            value: '',
            valid: false,
            validate: { blank: false, min_length: 6, max_length: 100 }
        },
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            success: null,
            error: null,
            loading: true,
            new_password: { ...this.state.new_password, message: '' },
        });

        doPut(this.props.token, '/members/password', {
            password: this.state.new_password.value
        }, () => {
            this.setState({
                ...this.state,
                success: 'Password has been updated.',
                error: null,
                loading: false,
            });
        }, (r) => {
            let m = 'Unable to update your password.'
            const { response } = r;
            const { data } = response;
            const { message } = data;

            if (message) {
                m = message;
            }

            this.setState({
                ...this.state,
                success: null,
                error: m,
                loading: false,
            });
        });
    }

    render() {
        let form = (    
            <Form onSubmit={this.submitHandler}>
                {this.state.new_password.value}
                <TextInput info={this.state.new_password} change={(event, info) => this.onChange(event, info)} />

                <Button variant="contained" color="primary" onClick={this.submitHandler}> SUBMIT </Button>
            </Form>
        );

        if (this.state.loading) {
            form = <Spinner />
        }

        const alert = this.getAlert();

        return (
            <div>
                {alert}
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
