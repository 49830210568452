import React from 'react';
import {connect} from 'react-redux';

import * as actions from '../../store/actions/index';
import Form from "../../components/UI/Form/Form";
import TextInput from "../../components/UI/TextInput/TextInput";
import FormComponent from '../../components/UI/FormComponent/FormComponent';
import SubmitButton from '../../components/UI/SubmitButton/SubmitButton';

class SignUpForm extends FormComponent {
    state = {
        email: {
            id: 'email',
            label: 'Email Address',
            value: '',
            valid: false,
            focus: true,
            validate: {blank: false, max_length: 200, email: true}
        },
        username: {
            id: 'username',
            label: 'Username',
            value: '',
            valid: false,
            validate: {blank: false, max_length: 25, username: true}
        },
        first_name: {
            id: 'first_name',
            label: 'First Name',
            value: '',
            valid: false,
            validate: {max_length: 100}
        },
        last_name: {
            id: 'last_name',
            label: 'Last Name',
            value: '',
            valid: false,
            validate: {max_length: 100}
        },
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: {blank: false, min_length: 6, max_length: 100}
        },
        error: '',
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            username: {...this.state.username, message: ''},
            password: {...this.state.password, message: ''},
            email: {...this.state.email, message: ''},
            first_name: {...this.state.first_name, message: ''},
            last_name: {...this.state.last_name, message: ''},
        });

        if (!this.state.username.valid) {
            this.setState({...this.state, username: {...this.state.username, message: 'Required'}});
        } else if (!this.state.email.valid) {
            this.setState({...this.state, email: {...this.state.email, message: 'Required'}});
        } else if (!this.state.password.valid) {
            this.setState({...this.state, password: {...this.state.password, message: 'Required'}});
        } else if (!this.state.first_name.value.trim() && !this.state.last_name.value.trim()) {
            this.setState({
                ...this.state,
                first_name: {...this.state.first_name, message: 'First and / or last name is required.'}
            });
        } else if ((this.state.first_name.value.trim().length + this.state.last_name.value.trim().length) > 100) {
            this.setState({
                ...this.state,
                first_name: {...this.state.first_name, message: 'First and last name together is too long.'}
            });
        } else {
            this.props.onSignUp(this.state);
        }
    };

    render() {
        return (
            <div>
                <Form onSubmit={this.submitHandler}>
                    <TextInput disabled={this.props.loading} info={this.state.email}
                               change={(event, info) => this.onChange(event, info)} blur={() => this.onBlurEmail()} />
                    <TextInput disabled={this.props.loading} info={this.state.username}
                               change={(event, info) => this.onChange(event, info)}/>
                    <TextInput disabled={this.props.loading} info={this.state.password}
                               change={(event, info) => this.onChange(event, info)}/>
                    <TextInput disabled={this.props.loading} info={this.state.first_name}
                               change={(event, info) => this.onChange(event, info)}/>
                    <TextInput disabled={this.props.loading} info={this.state.last_name}
                               change={(event, info) => this.onChange(event, info)}/>

                    <SubmitButton disabled={this.props.loading} click={this.submitHandler} > SIGN UP </SubmitButton>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        authRedirectPath: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSignUp: (state) => dispatch(actions.signUp(state)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
