import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Form from "../../../components/UI/Form/Form";
import { doPost } from "../../../axios-main";
import TextInput from "../../../components/UI/TextInput/TextInput";
import DateInput from "../../../components/UI/DateInput/DateInput";
import { withRouter } from "react-router-dom";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import { getToken } from "../../../components/functions";

class create extends Component {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        occurred_on: new Date(),
        video_kind: 10,
        season_id: null,
    };

    componentDidMount() {
    }

    onChange = (event, info)=> {
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeDate = (event, field) => {
        this.setState({ ...this.state, [field]: event });
    };

    onChangeField = (event, field) => {
        this.setState({ ...this.state, [field]: event.target.value });
    };

    submitHandler = (event) => {
        event.preventDefault();

        const { season } = this.props;

        doPost(this.props.token, '/videos', {
            video_title: this.state.title.value,
            video_kind: this.state.video_kind ? Number(this.state.video_kind) : 10,
            season_index: season.season_index,
            occurred_on: this.state.occurred_on,
            angles: [], // todo add these some time?
            remove_audio: true, // todo give the option?
        }, (r) => {
            // pushPath(this.props, `videos`);
            this.props.close(null, true);
        }, (r) => {
            console.log('ERROR', r.response.data.message);
        });
    };

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                <TextInput info={this.state.title}
                    change={(event, info) => this.onChange(event, info)} />

                <SelectPanel label={'Kind'} value={this.state.video_kind} items={this.props.video_kinds}
                    change={(event) => this.onChangeField(event, 'video_kind')} />

                <DateInput id="occurred_on" label="Occurred" value={this.state.occurred_on}
                    onChange={(event) => this.onChangeDate(event, 'occurred_on')} />

                <Button variant="contained" color="primary" type="submit"> SUBMIT </Button>
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

export default withRouter(connect(mapStateToProps)(create));
