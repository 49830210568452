const config = {
    sid: process.env.REACT_APP_SESSION_KEY,
    api: process.env.REACT_APP_API_URL,
    enums: process.env.REACT_APP_ENUM_URL,
    ws: process.env.REACT_APP_WEBSOCKET_URL,
};

export const getApi = () => {
    return config.api;
};

export const getEnums = () => {
    return config.enums;
};

export const getSid = () => {
    return config.sid;
};

export const getWebsocket = (token) => {
    return config.ws + "?" + config.sid + "=" + token;
};
