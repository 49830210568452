import React, { Component } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import BlockUsage from '../../../../components/BlockUsage/BlockUsage';

class row extends Component {
    render() {
        const { model } = this.props;

        return (
            <TableRow key={`${model.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, model)}>
                <TableCell align="left">
                    {model.season_title}
                </TableCell>
                <TableCell align="left">
                    {`${model.start_date[1]}/${model.start_date[2]}/${model.start_date[0]}`}
                </TableCell>
                <TableCell align="left">
                    {Number(model.total_participants) > 0 && model.total_participants}
                </TableCell>
                <TableCell align="left">
                    <BlockUsage size={model.used_video_storage} />
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = () => {
    return {
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(row);
