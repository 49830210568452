import React from 'react';

import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { getToken, getLocalDate } from "../../../components/functions";
import { withRouter } from "react-router-dom";
import HeightInput from "../../../components/UI/HeightInput/HeightInput";
import WeightInput from "../../../components/UI/WeightInput/WeightInput";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import DateInput from "../../../components/UI/DateInput/DateInput";
import { doPut } from "../../../axios-main";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import FormComponent from '../../../components/UI/FormComponent/FormComponent';
import SubmitButton from '../../../components/UI/SubmitButton/SubmitButton';
import { updateParticipant } from '../../../store/actions/participants';

class UpdateParticipant extends FormComponent {
    state = {
        participant: {},
        first_name: {
            id: 'first_name',
            label: 'First Name',
            value: ' ',
            valid: false,
            focus: true,
            validate: { max_length: 100 }
        },
        last_name: {
            id: 'last_name',
            label: 'Last Name',
            value: ' ',
            valid: false,
            validate: { max_length: 100 }
        },
        nick_name: {
            id: 'nick_name',
            label: 'Nick Name',
            value: ' ',
            valid: false,
            validate: { max_length: 100 }
        },
        birth_date: this.props.participant.birth_date ? new Date(this.props.participant.birth_date) : null,
        grade_level: this.props.participant.grade_level ? this.props.participant.grade_level : '',
        height_in_inches: this.props.participant.height_in_inches,
        jersey_number: {
            id: 'jersey_number',
            label: 'Jersey Number',
            value: ' ',
            valid: true,
            validate: {}
        },
        weight_in_ounces: this.props.participant.weight_in_ounces,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        if (this.props.participant) {
            const {
                first_name,
                last_name,
                nick_name,
                birth_date,
                grade_level,
                height_in_inches,
                jersey_number,
                weight_in_ounces } = this.props.participant

            this.setState({
                ...this.state,
                first_name: { ...this.state.first_name, value: first_name ? first_name : '' },
                last_name: { ...this.state.last_name, value: last_name ? last_name : '' },
                nick_name: { ...this.state.nick_name, value: nick_name ? nick_name : '' },
                birth_date: birth_date ? new Date(birth_date) : null,
                grade_level: grade_level ? grade_level : '',
                height_in_inches: height_in_inches ? height_in_inches : '',
                jersey_number: { ...this.state.jersey_number, value: jersey_number ? jersey_number : '' },
                weight_in_ounces: weight_in_ounces ? weight_in_ounces : '',
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props) {
            if (prevProps.participant.participant_index !== this.props.participant.participant_index) {
                const {
                    first_name,
                    last_name,
                    nick_name,
                    birth_date,
                    grade_level,
                    height_in_inches,
                    jersey_number,
                    weight_in_ounces } = this.props.participant

                this.setState({
                    ...this.state,
                    first_name: { ...this.state.first_name, value: first_name ? first_name : '' },
                    last_name: { ...this.state.last_name, value: last_name ? last_name : '' },
                    nick_name: { ...this.state.nick_name, value: nick_name ? nick_name : '' },
                    birth_date: birth_date ? new Date(birth_date) : null,
                    grade_level: grade_level ? grade_level : '',
                    height_in_inches: height_in_inches ? height_in_inches : '',
                    jersey_number: { ...this.state.jersey_number, value: jersey_number ? jersey_number : '' },
                    weight_in_ounces: weight_in_ounces ? weight_in_ounces : '',
                });
            }
        }
    }

    submitHandler = (event) => {
        event.preventDefault();

        const state = this.state;

        const { participant } = this.props
        const { level_index, participant_index, season_index } = participant ? participant : {}

        this.setLoading(true)

        doPut(this.props.token, '/participants', {
            first_name: state.first_name.value,
            last_name: state.last_name.value,
            birth_date: getLocalDate(state.birth_date),
            grade_level: state.grade_level ? Number(state.grade_level) : null,
            height_in_inches: state.height_in_inches ? Number(state.height_in_inches) : 0,
            jersey_number: state.jersey_number.value,
            level_index: level_index,
            nickname: state.nick_name.value,
            participant_index: participant_index,
            season_index: season_index,
            weight_in_ounces: state.weight_in_ounces ? Number(state.weight_in_ounces) : 0,
        },
            (s) => {
                this.setLoading(false)
                this.setSuccess("Participant updated.")
                this.props.refresh(s.data)
                this.props.updateParticipant(s.data)
            },
            (e) => {
                this.setLoading(false)
                this.setError(e.response?.data?.message);
            })
    };

    render() {
        const grade_levels = this.props.grade_levels ? this.props.grade_levels : [];

        let form = (
            <Form onSubmit={this.submitHandler}>
                {this.getAlert()}

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 2, marginRight: 5 }}>
                        <TextInput disabled={this.state.loading} info={this.state.first_name}
                            change={(event, info) => this.onChange(event, info)} />
                    </div>
                    <div style={{ flex: 1, marginRight: 5 }}>
                        <TextInput disabled={this.state.loading} info={this.state.nick_name}
                            change={(event, info) => this.onChange(event, info)} />
                    </div>
                    <div style={{ flex: 2 }}>
                        <TextInput disabled={this.state.loading} info={this.state.last_name}
                            change={(event, info) => this.onChange(event, info)} />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 2, marginRight: 5 }}>
                        <TextInput disabled={this.state.loading} info={this.state.jersey_number}
                            change={(event, info) => this.onChange(event, info)} />
                    </div>

                    <div style={{ flex: 2, paddingTop: 16, marginRight: 5 }}>
                        <SelectPanel disabled={this.state.loading} label={'Grade Level'} value={this.state.grade_level} items={grade_levels}
                            change={(event) => this.onChangeField(event, 'grade_level')} />
                    </div>

                    <div style={{ flex: 2 }}>
                        <DateInput disabled={this.state.loading} id="birth_date" label="Birth Date (m/d/y)" value={this.state.birth_date}
                            onChange={(event) => this.onChangeDate(event, 'birth_date')} />
                    </div>

                </div>

                <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1, paddingTop: 16 }}>
                        <HeightInput disabled={this.state.loading} value={this.state.height_in_inches} change={(event, value) => {
                            this.onChangeFieldValue(event, 'height_in_inches', value)
                        }} />
                    </div>
                    <div style={{ flex: 1, marginLeft: 5 }}>
                        <WeightInput disabled={this.state.loading} value={this.state.weight_in_ounces} change={(event, value) => {
                            this.onChangeFieldValue(event, 'weight_in_ounces', value)
                        }} />
                    </div>
                </div>
                {(this.props.manage_team || this.props.team_member_relationship?.modify_participant) && <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>}
            </Form>
        );

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        grade_levels: state.enums.grade_levels,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        updateParticipant: (participant) => dispatch(updateParticipant(participant))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateParticipant));
