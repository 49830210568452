import * as actionTypes from "./actionTypes";

export const setVideoList = (videos) => {
    return {
        type: actionTypes.SET_VIDEO_LIST,
        videos: videos,
        loading: false,
    };
};

export const selectVideo = (video) => {
    return {
        type: actionTypes.SELECT_VIDEO,
        video: video,
        loading: false,
    };
};

export const selectVideoFile = (video_file) => {
    return {
        type: actionTypes.SELECT_VIDEO_FILE,
        video_file: video_file,
        loading: false,
    };
};

