import React, { Component } from 'react';
import * as actions from '../../../store/actions/index';
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Row from "./Row";
import Create from "./Create"
import { doGet } from '../../../axios-main';
import { getToken, fieldSorter, canManageBilling } from '../../../components/functions';

class list extends Component {
    state = {
        is_create: false,
        payments: [],
    };

    componentDidMount() {
        this.onLoad();
    }

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true })
    };

    onLoad = () => {
        this.setState({...this.state, loading: true});

        doGet(this.props.token, '/teams/purchases', (r) => {
            this.setState({
                ...this.state,
                payments: r.data,
                loading: false
            });
        }, (r) => {
            if (this.state.loads < 5) {
                setTimeout(this.onLoad, 100);
                this.setState({ ...this.state, loads: this.state.loads + 1 });
            } else {
                console.log('ERROR', r.response.data.message);
                this.setState({...this.state, loading: false});
            }
        });
    };

    onClose = (e, load, model) => {
        if (e) {
            e.stopPropagation()
        }

        if (load) {
            this.onLoad()
        }

        if (model) {
            const list = this.state.payments
            list.unshift(model)
            this.setState({ ...this.state, payments: list, payment: model })
        }

        this.setState({ ...this.state, is_create: false})
    };

    render() {

        return (
            <div>
                {this.props.manage_billing && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> Purchase Storage </Button>
                </div>}

                <Table aria-label="payment table">
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left">Product</TableCell>
                            <TableCell align="left">Quantity</TableCell>
                            <TableCell align="left">Term</TableCell>
                            <TableCell align="left">Expiration</TableCell>
                            <TableCell align="left">Amount</TableCell>
                            <TableCell align="left">Purchased by</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.payments.sort(fieldSorter(['-created_on', 'transaction_id'])).map(row => {
                            return (
                                <Row key={`${row.transaction_id}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>

                <Create open={this.state.is_create} close={(e, load, model) => this.onClose(e, load, model)} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        manage_billing: canManageBilling(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSeasons: (token) => dispatch(actions.getSeasons(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(list);
