import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doPut, doDelete } from "../../axios-main";
import { getToken } from "../../components/functions";
import * as actions from '../../store/actions/index';
import ImageComponent from "../../components/UI/ImageComponent/index";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Relationship from './Relationship'

class Invite extends Component {
    state = {
        removed: false,
        accepting: false,
    }

    relationship = (hide_contact_info, relationship, relationship_other) => {
        let url = '/members/invites/teams'

        if (this.props.invite.participant_first_name || this.props.invite.participant_last_name) {
            url = '/members/invites/participants'
        }

        doPut(this.props.token, url, {
            token: this.props.invite.invite_token,
            hide_contact_info: hide_contact_info,
            relationship: relationship,
            relationship_other: relationship_other,
        }, (r) => {
            this.setState({ ...this.state, removed: true, accepting: false })
            let teams = this.props.teams;
            let i = teams.findIndex(t => t.team_id === r.data.member.team_id)
            if (i < 0) {
                teams.push(r.data.member)
            }
            this.props.onSetTeams(teams)
        },
            (r) => {
                console.log('ERROR', r.response.data.message);
            });
    }

    accept = (event) => {
        event.stopPropagation();

        this.setState({ ...this.state, accepting: true });
    };

    decline = (event) => {
        event.stopPropagation();

        let url = '/members/invites/teams'

        if (this.props.invite.participant_first_name || this.props.invite.participant_last_name) {
            url = '/members/invites/participants'
        }

        doDelete(this.props.token, `${url}?token=${this.props.invite.invite_token}`,
            () => {
                this.setState({ ...this.state, removed: true })
            },
            (r) => {
                console.log('ERROR', r.response.data.message);
            });
    };

    close = () => {
        this.setState({ ...this.state, accepting: false })
    }

    render() {
        return (
            <>
                {!this.state.removed && <TableRow>
                    <TableCell align="left">
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ textAlign: 'left', marginRight: 5 }}>
                                <ImageComponent size={40} first={this.props.invite.team_title} image_url={this.props.invite.team_image_url} />
                            </div>
                            <div style={{ flex: 1, textAlign: 'left' }}>{this.props.invite.team_title}</div>
                        </div>
                    </TableCell>
                    <TableCell align="left">
                        {(this.props.invite.participant_first_name || this.props.invite.participant_last_name) && <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ textAlign: 'left', marginRight: 5 }}>
                                <ImageComponent size={40} first={this.props.invite.participant_first_name} last={this.props.invite.participant_last_name} image_url={this.props.invite.participant_image_url} />
                            </div>
                            <div style={{ flex: 1, textAlign: 'left' }}>{this.props.invite.participant_first_name} {this.props.invite.participant_last_name}</div>
                        </div>}
                    </TableCell>
                    <TableCell>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ paddingLeft: 5 }}>
                                <Button size="small" variant="contained" color="secondary" onClick={this.decline}> Decline </Button>
                            </div>
                            <div style={{ paddingLeft: 5 }}>
                                <Button size="small" variant="contained" color="primary" onClick={this.accept}> Accept </Button>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>}
                <Relationship show_relationship={this.props.invite.participant_first_name || this.props.invite.participant_last_name} open={this.state.accepting} close={this.close} relationship={(hide_contact_info, relationship, relationship_other) => this.relationship(hide_contact_info, relationship, relationship_other)} />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: getToken(state),
        teams: state.auth.user.teams,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetTeams: (teams) => dispatch(actions.setTeams(teams)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
