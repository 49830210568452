import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Row from "../Row/Row";
import EditDialog from "../Edit/Edit";
import {doPost} from "../../../axios-main";
import { getToken } from "../../../components/functions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Card from "../../../components/UI/Card/Card";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Create from "../Create/Create";
import DialogAppBar from '../../../components/UI/DialogAppBar/DialogAppBar';

class list extends Component {
    state = {
        loading: false,
        empty: false,
        items: [],
        create: false,
        open: false,
        email: {},
    };

    componentWillUnmount() {
        this.setState({...this.state, items: [], empty: false, loading: false, open: false, email: {}});
    }

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.onLoad();
                clearInterval(i);
            }
        }, 0);
    }

    setLoading = (loading) => {
        this.setState({...this.state, loading: loading});
    };

    onLoad = () => {
        this.setLoading(true);

        doPost(this.props.token, '/emails/list', {season_id: this.props.season.id}, (r) => {
            this.setState({
                ...this.state,
                items: r.data.items,
                empty: r.data.items.length <= 0,
                loading: false
            });
        }, (r) => {
            console.log('ERROR', r.response.data.message);
            this.setLoading(false);
        });
    };

    close = (e, load) => {
        if (load === true) {
            this.onLoad();
        }

        this.setState({
            ...this.state,
            loading: false,
            empty: false,
            participant: {},
            open: false,
            create: false
        });
    };
    
    createHandler = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, create: true });
    };

    handleOpen = (event, email) => {
        this.setState({...this.state, open: true, email: email});
    };

    handleClose = (email) => {
        this.onLoad();
        this.setState({...this.state, open: false, email: {}});
    };

    render() {
        let table = <Spinner/>;
        let button;

        if (this.state.empty) {
            table = <Card>
                <h1>Welcome to SportsHub.io</h1>
                <h2>There are no emails to show, yet.</h2>
                <h3>Would you like to create a email?</h3>

                <Button variant="contained" color="primary" onClick={this.createHandler}> New Email </Button>
            </Card>
        } else if (this.state.items && this.state.items.length > 0) {
            button = <div style={{padding: '10px 0 0 10px'}}>
                <Button variant="contained" color="primary" onClick={this.createHandler}> New Email </Button>
            </div>;


            table = <Paper style={{margin: '10px'}}>
                <Table aria-label="email table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Subject</TableCell>
                            <TableCell align="left">Body</TableCell>
                            <TableCell align="left">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.items.map(row => {
                                    if (row.id) {
                                        return (
                                            <Row onClick={(event, email) => this.handleOpen(event, email)}
                                                 key={`${row.id}`} model={row}/>
                                        )
                                    } else {
                                        console.log('EMPTY ROW', row);
                                        return '';
                                    }
                                }
                            )
                        }
                    </TableBody>
                </Table>

                <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.close} open={this.state.create} disableBackdropClick>
                    <DialogAppBar title={'New Email'} close={this.close} />

                    <DialogContent id="player-content">
                        <Create close={(e, load) => this.close(e, load)} />
                    </DialogContent>
                </Dialog>

                <EditDialog email={this.state.email} open={this.state.open}
                            onClose={(email) => this.handleClose(email)}/>
            </Paper>
        }

        return (
            <div>
                {button}
                {table}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
