import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Create from "./Create/Create";
import List from "./List/List";

class index extends Component {
    path = '';

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    render() {
        let path;
        if (this.props.match.params && this.props.match.params.team_id && this.props.match.params.season_id) {
            path = `/${this.props.match.params.team_id}/${this.props.match.params.season_id}/emails/`;
        }

        this.path = path;

        return (
            <div>
                <div style={{display: "flex"}}>
                    <div style={{flexGrow: 1}}>
                        <Switch>
                            <Route path={`${path}create`} component={Create}/>
                            <Route path={`${path}`} component={List}/>
                        </Switch>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.members.loading,
        error: state.members.error,
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
        list: state.members.items,
        user: state.auth.user,
        team: state.teams.team,
        season: state.seasons.season,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
