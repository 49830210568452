import React, {Component} from 'react';

class delay extends Component {
    _timeout = 0;

    state = {
        display: 'none',
    };

    componentDidMount() {
        this._timeout = setTimeout(() => {
            this.setState({...this.state, display: 'block'});
        }, this.props.delay);
    }

    componentWillUnmount() {
        clearTimeout(this._timeout);
    }

    render() {
        return (
            <div style={{display: this.state.display}}>
                {this.props.children}
            </div>
        )
    }
}

export default delay;
