import React, {Component} from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import EditIcon from '@material-ui/icons/Edit';

class row extends Component {
    render() {
        let row = this.props.model;

        return (
            <TableRow style={{cursor: "pointer"}} onClick={(event) => this.props.onClick(event, row)} key={`${row.id}`}>
                <TableCell align="left">
                    {row.subject}
                </TableCell>
                <TableCell align="left">{row.body} {row.submitted}</TableCell>
                <TableCell align="left">
                    <EditIcon/>
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(row);
