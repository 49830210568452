import React, {Component} from 'react';
import {doGet} from "../../../../axios-main";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Row from "./Row/Row";
import { getToken } from "../../../../components/functions";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Card from "../../../../components/UI/Card/Card";
import "./List.css";

class list extends Component {
    state = {
        loading: false,
        empty: false,
        items: []
    };

    componentWillUnmount() {
        this.setState({...this.state, items: [], empty: false, loading: false});
    }

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.onLoad();
                clearInterval(i);
            }
        }, 0);
    }

    setLoading = (loading) => {
        this.setState({...this.state, loading: loading});
    };

    onLoad = () => {
        this.setLoading(true);

        doGet(this.props.token, `/squad-members/${this.props.squad}/list`, (r) => {
            this.setState({
                ...this.state,
                items: r.data.items,
                empty: r.data.items.length <= 0,
                loading: false
            });
        }, (r) => {
            console.log('ERROR', r.response.data.message);
            this.setLoading(false);
        });
    };

    render() {
        let table = <Spinner/>;

        if (this.state.empty) {
            table = <Card>
                <h1>Welcome to SportsHub.io</h1>
                <h2>There are no squad members to show, yet.</h2>
                <h3>Would you like to add a squad member?</h3>
            </Card>
        } else if (this.state.items && this.state.items.length > 0) {
            table =
                <Paper style={{margin: '10px'}}>
                    <Table aria-label="squad members table" size={'small'} className={'Table'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">&nbsp;</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Add and remove members from squad</TableCell>
                                <TableCell align="left">Delete the squad</TableCell>
                                <TableCell align="left">Edit squad appearance</TableCell>
                                <TableCell align="left">Send chats to squad</TableCell>
                                <TableCell align="left">Send emails to squad</TableCell>
                                <TableCell align="left">Manage events on squad</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.items.map(row => {
                                        if (row.id) {
                                            return (
                                                <Row key={`${row.id}`} model={row}/>
                                            )
                                        } else {
                                            console.log('EMPTY', row);
                                            return '';
                                        }
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </Paper>
        }

        return (
            <div>
                {table}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
