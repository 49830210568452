import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ImageComponent from "../../../../components/UI/ImageComponent/index";
import BooleanView from "../../../../components/UI/BooleanView/index";
import {
    MANAGE_BILLING,
    MANAGE_TEAM,
    MANAGE_PHOTOS,
    MANAGE_VIDEOS,
    VIEW_MEMBERS,
    VIEW_VIDEOS,
} from "../../../../constants/permissions";
import { PARTICIPANT_RELATIONSHIP } from '../../../../constants/enums';

class row extends Component {
    render() {
        const {team_member_permissions, member_first_name, member_last_name, member_image_url, member_email_address, relationship, relationship_other} = this.props.model;
        const theme = this.props.team_member?.alternate_team_theme ? this.props.team_member.alternate_team_theme : this.props.team?.theme

        const r = PARTICIPANT_RELATIONSHIP.find(r => Number(r.id) === Number(relationship))

        let relationship_title = r.title

        if (Number(relationship) === 100) {
            relationship_title = relationship_other
        }

        return (
            <TableRow key={`${this.props.model.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, this.props.model)}>
                <TableCell align="left">
                    <ImageComponent theme={theme} size={50} first={member_first_name} last={member_last_name} image_url={member_image_url} />
                </TableCell>
                <TableCell align="left">{member_first_name} {member_last_name}</TableCell>
                <TableCell align="left">{member_email_address}</TableCell>
                <TableCell align="left">{relationship_title}</TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_BILLING) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_TEAM) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_PHOTOS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_VIDEOS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & VIEW_MEMBERS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(team_member_permissions & VIEW_VIDEOS) !== 0} /></TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        team: state.teams.team,
        team_member: state.team_members.team_member
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(row));
