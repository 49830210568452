import React from 'react';
import { connect } from 'react-redux';
import Form from '../../components/UI/Form/Form';
import TextInput from '../../components/UI/TextInput/TextInput';
import * as actions from '../../store/actions/index';
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { authReset, authStart, authSuccess, authFail } from "../../store/actions/auth";
import { doPost, doPut, doDelete } from '../../axios-main';
import FormComponent from '../../components/UI/FormComponent/FormComponent';
import CheckPanel from "../../components/UI/CheckPanel/CheckPanel";

class SignInForm extends FormComponent {
    state = {
        username: {
            id: 'username',
            label: 'Username or Email Address',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, min_length: 1, max_length: 100 }
        },
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: { blank: false, min_length: 6, max_length: 100 }
        },
        remember_me: false,
        hide_contact_info: false,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        this.props.onSwitch();

        if (this.props.invite && this.props.invite.email_address) {
            this.setState({...this.state, username: {...this.state.username, value: this.props.invite.email_address}})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(this.props)
    }

    onDecline = (event) => {
        doDelete('', '/members/invites/teams?token=' + decodeURIComponent(this.props.match.params.token), 
        () => {
            this.setSuccess('Invite declined.')
        },
        () => {
            this.setError('Unable to decline invite.')
        })
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            username: { ...this.state.username, message: '' },
            code: { ...this.state.code, message: '' },
        });

        if (!this.state.username.valid) {
            this.setState({ ...this.state, username: { ...this.state.username, message: 'Required' } });
        } else if (!this.state.password.valid) {
            this.setState({ ...this.state, password: { ...this.state.password, message: 'Required' } });
        } else {
            this.props.authStart();
            doPost('', '/sign-in/password', {
                identity: this.state.username.value,
                password: this.state.password.value,
                remember_me: this.state.remember_me
            }, (r) => {
                const { session } = r.data;
                const { token } = session ? session : null;

                if (token) {
                    setTimeout(() => {
                        doPut(token, `/members/invites/teams`, {
                            token: decodeURIComponent(this.props.match.params.token),
                            hide_contact_info: this.state.hide_contact_info,
                        }, (r2) => {
                            this.props.authSuccess(r.data);

                            if (this.state.remember_me && r.data.cookie) {
                                localStorage.setItem('sign_in_store', r.data.cookie)
                            }

                            this.props.history.push('/')
                        }, (r2) => {
                            this.props.authFail(r);
                            console.log('ERROR', r);
                        });
                    }, 0)
                }
            }, (r) => {
                this.props.authFail(r);
                console.log('ERROR 3', r);
            });
        }
    }

    onRememberMe = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    render() {
        return (
            <div>
                <Form onSubmit={this.submitHandler}>

                    {this.getAlert()}

                    <TextInput disabled={this.props.loading} info={this.state.username}
                        change={(event, info) => this.onChange(event, info)} />
                    <TextInput disabled={this.props.loading} info={this.state.password}
                        change={(event, info) => this.onChange(event, info)} />

                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('remember_me')} label='Remember Me' checked={this.state.remember_me} />
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('hide_contact_info')} label='Hide Contact Info' checked={this.state.hide_contact_info} />
                    </div>

                    <Button disabled={this.props.loading} variant="contained" color="secondary"
                        onClick={this.onDecline} type="button" style={{ marginRight: 2 }}> DECLINE </Button>
                    <Button disabled={this.props.loading} variant="contained" color="primary"
                        type="submit" style={{ marginLeft: 2 }}> ACCEPT </Button>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        authStart: () => dispatch(authStart()),
        authSuccess: (d) => dispatch(authSuccess(d)),
        authFail: (r) => dispatch(authFail(r)),
        onSignIn: (email, password, remember_me) => dispatch(actions.signIn(email, password, remember_me)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInForm));
