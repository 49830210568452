import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {authReset} from "../../../store/actions/auth";
import { getToken } from "../../../components/functions";
import Cropper from "../../../components/UI/Cropper/Cropper";
import { updateParticipant } from '../../../store/actions/participants';

class Image extends Component {
    state = {
        file: null,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    onComplete = result => {
        let participant = this.props.participant

        participant.image_url = result.small

        this.props.updateParticipant(participant)
    };

    render() {
        let cropper;

        let theme = this.props.team_member?.alternate_team_theme ? this.props.team_member?.alternate_team_theme : this.props.team?.theme

        if (this.props.team_member) {
            const {level_index, participant_index, season_index} = this.props.participant;

            cropper = <Cropper can_update={(this.props.manage_team || this.props.team_member_relationship?.modify_participant)} first_name={this.props.participant?.first_name} last_name={this.props.participant?.last_name} theme={theme} extras={{level_index: level_index, participant_index: participant_index, season_index: season_index}} url={`/participants/images`} onComplete={result => {
                this.onComplete(result)
            }}/>
        }

        return (
            <div>
                {cropper}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        isAuthenticated: state.auth.user !== null,
        isPasswordChanged: state.auth.isPasswordChanged,
        user: state.auth.user,
        authRedirectPath: state.auth.authRedirectPath,
        team: state.teams.team,
        team_member: state.team_members.team_member,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onChangeSummary: (user, first_name, last_name, nick_name, phone_number) => dispatch(actions.changeSummary(user, first_name, last_name, nick_name, phone_number)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        updateParticipant: (participant) => dispatch(updateParticipant(participant))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
