import React, { Component } from 'react';
import { connect } from 'react-redux';
import { authSuccess } from "../../store/actions/auth";
import Cropper from "../../components/UI/Cropper/Cropper";

class Image extends Component {
    onComplete = result => {
        this.props.onUpdate({...this.props.user, member: {...this.props.user.member, image_url: result.small}})
    };

    render() {
        return (
            <div>
                <Cropper can_update={true} first_name={this.props.user?.member?.first_name} last_name={this.props.user?.member?.last_name} url="/members/images" onComplete={result => {
                    this.onComplete(result)
                }} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (user) => dispatch(authSuccess(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
