import React from 'react';

import Alert from "./Alert";

const success = (props) => {
    return (
        <Alert alertType={'Green'}>
            {props.children}
        </Alert>
    );
};

export default success;
