import React, {Component} from 'react';
import OnIcon from "@material-ui/icons/CheckBox";
import OffIcon from "@material-ui/icons/CheckBoxOutlineBlank";

class check extends Component {

    onChange = (e) => {
        if (!this.props.disabled) {
            this.props.change(e, this.props.permission, (this.props.permissions & this.props.permission) !== 0)
        }
    }

    render() {
        let color
        if (this.props.disabled) {
            color = 'lightgrey'
        }


        return (
            <div style={{cursor: 'pointer', display: 'flex'}}
                 onClick={(e) => this.onChange(e)}>
                <div>
                    {(this.props.permissions & this.props.permission) !== 0 ? <OnIcon style={{color: color, fontSize: '20px'}}/> : <OffIcon style={{fontSize: '20px', color}}/>}
                </div>
                <div style={{lineHeight: '20px', fontSize: '0.8rem', paddingLeft: 3, color: color}}>{this.props.label}</div>
            </div>
        );
    }
}

export default check
