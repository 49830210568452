import React from 'react';

import * as actions from '../../../../store/actions/index';

import { doPost } from "../../../../axios-main";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import ChipInput from 'material-ui-chip-input';
import TeamMemberPermissions from "../../../../components/UI/Permission/TeamMemberPermissions";
import { withRouter } from "react-router-dom";
import { getColors, getToken, checkEmail } from "../../../../components/functions";
import "../../Member/List/List.css";
import FormComponent from "../../../../components/UI/FormComponent/FormComponent";
import SubmitButton from '../../../../components/UI/SubmitButton/SubmitButton';
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";

class create extends FormComponent {
    state = {
        loading: false,
        emails: [],
        email: {
            id: 'email_address',
            label: 'Email Address',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 100 }
        },
        permissions: 0,
    };

    onPress = (event) => {
        if (event.key.toLowerCase() === 'enter') {
            event.stopPropagation();
            event.preventDefault();

            this.addEmail();
        }
    };

    onChangePermissions = (permissions) => {
        this.setState({
            ...this.state, permissions: permissions
        });
    };

    submitHandler = (event) => {
        event.preventDefault();

        const { email, emails } = this.state;

        const list = [];

        if (email && email.value && email.value.trim()) {
            list.push(this.state.email.value.trim());
        }

        emails.forEach((e) => {
            if (e.trim()) {
                list.push(e.trim());
            }
        });

        this.setLoading(true);

        doPost(this.props.token, '/teams/invites', {
            email_addresses: list,
            team_member_permissions: this.state.permissions,
        }, () => {
            this.setLoading(false)
            this.props.loadTeamInvites(this.props.token)
            this.props.openTeam()
            this.onClose(null, true)
        }, (r) => {
            this.setLoading(false);
            if (r.response.data.code === 'email-address-already-invited') {
                this.setError('Email address ' + r.response.data.attributes.email_address + ' already invited.')
            } else if (r.response.data.code === 'email-address-already-member') {
                this.setError('Email address ' + r.response.data.attributes.email_address + ' already a member.')
            } else {
                this.setError(r.response.data.message)
            }
        });
    };

    reset = () => {
        this.setState({
            ...this.state,
            success: null,
            error: null,
        });
    }

    onClose = (event, load) => {
        this.reset();
        this.props.close(event, load);
    }

    render() {
        let form = (
            <Container style={{ paddingTop: 20, paddingBottom: 20 }}>
                {this.getAlert()}
                <Form onSubmit={this.submitHandler}>
                    <ChipInput
                        disabled={this.state.loading}
                        blurBehavior={'add'}
                        InputProps={{ autoFocus: true }}
                        allowDuplicates={false}
                        fullWidth={true}
                        label={'Email(s)'}
                        value={this.state.emails}
                        newChipKeyCodes={[13, 32]}
                        newChipKeys={['Enter', 'Space', 'SpaceBar']}
                        onBeforeAdd={(email) => { return (checkEmail(email.toLowerCase()) ? true : false) }}
                        onAdd={(email) => this.onAddEmail(email)}
                        onDelete={(email, index) => this.onRemoveEmail(email, index)}
                    />

                    <TeamMemberPermissions disabled={this.state.loading} permissions={this.state.permissions} change={(permissions) => {
                        this.onChangePermissions(permissions)
                    }} />

                    <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                </Form>
            </Container>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Invite a Member to the Team" close={this.onClose} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openTeam: () => dispatch(actions.openTeamAction('Invite', false)),
        loadTeamInvites: (token) => dispatch(actions.loadTeamInvitesAction(token)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
