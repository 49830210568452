import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import BooleanView from "../../../../../components/UI/BooleanView/index";
import {
    MANAGE_BILLING,
    MANAGE_TEAM,
    MANAGE_PHOTOS,
    MANAGE_VIDEOS,
    VIEW_MEMBERS,
    VIEW_VIDEOS,
} from "../../../../../constants/permissions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogAppBar from '../../../../../components/UI/DialogAppBar/DialogAppBar';

class row extends Component {
    state = {
        open: false,
    };

    open = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ open: true })
    }

    close = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ open: false })
    }

    confirm = (e) => {
        this.props.remove(e, this.props.model)
    }

    render() {
        const { team_member_permissions, email_address } = this.props.model;

        return (
            <>
                <TableRow key={`${this.props.model.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, this.props.model)}>
                    <TableCell align="left">{email_address}</TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_BILLING) !== 0} /></TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_TEAM) !== 0} /></TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_PHOTOS) !== 0} /></TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & MANAGE_VIDEOS) !== 0} /></TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & VIEW_MEMBERS) !== 0} /></TableCell>
                    <TableCell align="left"><BooleanView value={(team_member_permissions & VIEW_VIDEOS) !== 0} /></TableCell>
                    {this.props.manage_team &&
                        <TableCell align="right">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Tooltip title={'Resend Invite'} arrow>
                                    <div>
                                        <FontAwesomeIcon icon={faEnvelope} className={'TrashIconButton'} onClick={(e) => this.props.resend(e, this.props.model)} aria-label="resend" />
                                    </div>
                                </Tooltip>
                                <Tooltip title={'Remove Invite'} arrow>
                                    <div>
                                        <FontAwesomeIcon icon={faTrash} className={'TrashIconButton'} onClick={(e) => this.open(e)} aria-label="remove" />
                                    </div>
                                </Tooltip>
                            </div>
                        </TableCell>
                    }
                </TableRow>
                <Dialog fullWidth={true} maxWidth={'sm'} scroll={'paper'} onClose={this.close} open={this.state.open} disableBackdropClick>
                    <DialogAppBar title={'Remove Invite?'} close={this.close} />
                    <DialogContent>
                        <h3>Remove the invite for {email_address}</h3>

                        <Button size="small" variant="contained" color="primary" onClick={this.confirm}> YES </Button>
                    </DialogContent>
                </Dialog >
            </>
        )
    }
}

export default row;
