import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { AppBar } from '@material-ui/core';

class AuthTabs extends Component {
    render() {
        return (
            <AppBar position="static" style={{ background: 'lightgrey', marginBottom: 25 }}>
                <Tabs
                    value={this.props.value}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    onChange={this.props.change}
                    aria-label="tabs"
                >
                    <Tab label="Sign In" />
                    <Tab label="Sign Up" />
                </Tabs>
            </AppBar>
        );
    }
}

export default AuthTabs;