import React, { Component } from 'react'
import Button from "@material-ui/core/Button";
import { doGet } from "../../../axios-main"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Row from "../Row/Row"
import Spinner from "../../../components/UI/Spinner/Spinner"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import Card from "../../../components/UI/Card/Card"
import { getToken, pushPath, fieldSorter, canManageVideos, canManageTeam } from "../../../components/functions"
import * as actions from "../../../store/actions"

class list extends Component {
    state = {
        loading: false,
        empty: false,
    }

    componentWillUnmount() {
        this.setState({ ...this.state, empty: false, loading: false })
        this.props.setVideoList([])
    }

    componentDidMount() {
        this.setState({ ...this.state, empty: false, loading: false })
        this.props.setVideoList([])

        let i = setInterval(() => {
            if (this.props.team) {
                this.onLoad()
                clearInterval(i)
            }
        }, 0)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.season_id !== prevProps.match.params.season_id) {
            setTimeout(this.onLoad(), 0)
        }
    }

    createHandler = (event) => {
        event.stopPropagation()
        this.props.openTeam()
    }

    close = (e, load) => {
        if (load === true) {
            this.onLoad()
        }
    }

    onVideo = (event, video) => {
        if (video?.total_files > 0) {
            pushPath(this.props, `videos/${video.video_id}`)
        }
    }

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading })
    }

    onLoad = () => {
        this.setLoading(true)

        doGet(this.props.token, `/videos?season_index=${this.props.match.params.season_id}`, (r) => {
            this.props.setVideoList(r.data)

            this.setState({
                ...this.state,
                empty: r.data.length <= 0,
                loading: false
            })
        }, (r) => {
            console.log('ERROR', r.response.data.message)
            this.setLoading(false)
        })
    }


    render() {
        const can_upload = (this.props.manage_team || this.props.manage_video)

        let table = <Spinner />

        let button = <div style={{ padding: '10px 0 0 10px' }}>
            <Button variant="contained" color="primary" onClick={this.createHandler}> New Video </Button>
        </div>;

        if (this.state.empty) {
            table = <Card>
                <h1>Welcome to SportsHub.io</h1>
                <h2>There are no videos to show, yet.</h2>
                {can_upload && <h3>Would you like to create a video?</h3>}
                {can_upload && <Button variant="contained" color="primary" onClick={this.createHandler}> New Video </Button>}
            </Card>
        } else if (this.props.videos && this.props.videos.length > 0) {
            button = <div style={{ padding: '10px 0 0 10px' }}>
                <Button variant="contained" color="primary" onClick={this.createHandler}> New Video </Button>
            </div>;

            table =
                <Paper style={{ margin: '10px' }}>
                    <Table aria-label="videos table">
                        <TableHead className='PrimaryTable'>
                            <TableRow>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="center">Kind</TableCell>
                                <TableCell align="center">Occurred</TableCell>
                                <TableCell align="center">Files</TableCell>
                                <TableCell align="center">Size</TableCell>
                                <TableCell align="center">Quality</TableCell>
                                <TableCell align="center">Audio</TableCell>
                                {this.props.manage_video && <TableCell align="center" />}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.videos.sort(fieldSorter(['-occurred_on', 'title', 'created_on', 'video_id'])).map(row => {
                                    return (
                                        <Row onClick={(event, video) => this.onVideo(event, video)} key={row.video_id} model={row} manage_video={this.props.manage_video} />
                                    )
                                }
                                )
                            }
                        </TableBody>
                    </Table>
                </Paper>
        }

        return (
            <div>
                {can_upload && <div>{button}</div>}
                {table}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        manage_video: canManageVideos(state),
        manage_team: canManageTeam(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
        videos: state.videos.videos,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setVideoList: (videos) => dispatch(actions.setVideoList(videos)),
        openTeam: () => dispatch(actions.openTeamAction('Video', true)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list))
