import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import List from "./List/List";
import Create from "./Create/Create";
import Video from "./Video/Video";

class index extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path={`${this.props.match.path}/`} component={List}/>
                    <Route path={`${this.props.match.path}/create`} component={Create}/>
                    <Route path={`${this.props.match.path}/:video_id`} component={Video}/>
                </Switch>
            </div>
        );
    }
};

export default withRouter(index);
