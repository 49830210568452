import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Header from "../../../components/UI/Header/Header";

import { withRouter } from "react-router-dom";
import Success from "../../../components/UI/Alert/Success";
import Error from "../../../components/UI/Alert/Error";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import { doPost } from "../../../axios-main";
import Card from "../../../components/UI/Card/Card";
import { getColors, pushPath, getToken } from "../../../components/functions";
import classes from "../../../components/UI/Styles.module.css";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";

class create extends Component {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        background_color: '#606060',
        text_color: '#CC0000',
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            } else if (this.props.season.id && !this.state.season_id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            }
        }
    }

    onCancel = (event) => {
        event.stopPropagation();
        this.props.history.goBack();
    };

    onChangeColor = (value, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: value });
    };

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    set_success = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'success': squad });
    };

    set_error = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'error': squad });
    };

    reset = () => {
        this.setState({ ...this.state, 'error': null });
        this.setState({ ...this.state, 'success': null });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.reset();

        const state = this.state;

        doPost(this.props.token, '/squads', {
            title: state.title.value,
            background_color: state.background_color,
            text_color: state.text_color,
        }, (r) => {
            this.set_success("Squad created.");
            pushPath(this.props, `squads/${r.data.id}/members`);
            this.props.getSquads(this.props.token);
        }, (r) => {
            console.log('ERROR', r);
            this.set_error(r.response);
        });
    };

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let form = (
            <Card style={{ margin: 10, padding: 10 }}>
                <Header> SETUP A SQUAD </Header>

                {error}
                {success}

                <Form onSubmit={this.submitHandler}>
                    <TextInput info={this.state.title}
                        change={(event, info) => this.onChange(event, info)} />

                    <ColorPicker label="Primary Color" value={this.state.background_color} field="background_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />
                    <ColorPicker label="Secondary Color" value={this.state.text_color} field="text_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />

                    <div className={classes.Buttons}>
                        <Button variant="contained" color="secondary" type="button"
                            onClick={this.onCancel}> CANCEL </Button>
                        <Button variant="contained" color="primary" type="submit"> CREATE </Button>
                    </div>
                </Form>
            </Card>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        getSquads: (session) => dispatch(actions.getSquads(session)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
