import React, { Component } from 'react';
import * as actions from '../../../../store/actions/index';
import { doDelete, doPut } from "../../../../axios-main";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InviteRow from "./Row/Invite";
import { getToken, canManageTeam } from "../../../../components/functions";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import CloseIcon from '../../../../components/UI/CloseIcon/CloseIcon';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./List.css";
import Create from "./Create"
import Snackbar from '@material-ui/core/Snackbar';

class list extends Component {
    state = {
        is_create: false,
    };

    componentDidMount() {
        this.onLoad();
    }

    closeSnackbar = () => {
        this.setState({ ...this.state, snackbar: false, snackbar_message: '' })
    }

    resend = (e, model) => {
        if (e) {
            e.stopPropagation()
        }

        doPut(this.props.token, `/teams/invites/resend`, {
            email_address: model.email_address,
        },
            (s) => {
                this.setState({ ...this.state, snackbar: true, snackbar_message: 'The invite has been sent to ' + model.email_address + '.' })
            }, (r) => {
                console.log('ERROR', r);
                this.setState({ ...this.state, snackbar: true, snackbar_message: 'Unable to send the invite.' })
            });
    };

    remove = (e, model) => {
        e.stopPropagation()

        doDelete(this.props.token,
            `/teams/invites?email_address=${model.email_address}`,
            (s) => {
                this.onLoad()
            },
            (e) => {
                console.log('Unable to remove the invite at this time.', e);
            }
        )
    }

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true })
    };

    onDetailInvite = (e, model) => {
        e.stopPropagation()
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    onClose = (e, load, model) => {
        if (e) {
            e.stopPropagation()
        }

        if (load) {
            this.props.loadTeamInvites(this.props.token)
        }

        this.setState({ ...this.state, is_create: false })
    };

    onLoad = () => {
        this.props.loadTeamInvites(this.props.token)
    };

    render() {
        const list = this.props.team_invites ? this.props.team_invites : []

        let table

        if (this.props.loading) {
            table = <Spinner />;
        } else {
            table = <h2>Send out some invites to people you would like tho have access to this team.</h2>
        }

        if (list && list.length > 0) {
            table = <Paper>
                <Table aria-label="team members table" size={'small'} className={'Table'}>
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left"><div>Manage</div><div>Billing</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Team</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Photos</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Videos</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Members</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Videos</div></TableCell>
                            {this.props.manage_team && <TableCell align="right"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(row => {
                            return (
                                <InviteRow remove={(e, model) => this.remove(e, model)}
                                    resend={(e, model) => this.resend(e, model)}
                                    manage_team={this.props.manage_team}
                                    key={`${row.email_address}${row.created_on}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        }

        return (
            <div>
                {this.props.manage_team && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> Invite Member </Button>
                </div>}

                {table}

                <Create open={this.state.is_create} close={(e, load) => this.onClose(e, load)} />
                <Snackbar
                    open={this.state.snackbar}
                    autoHideDuration={5000}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbar_message}
                    action={<>
                        <div className={'Close'} variant="contained" color="primary" onClick={this.closeSnackbar}>
                            <CloseIcon style={{ marginRight: 0 }} aria-label="close" />
                        </div>
                    </>}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        manage_team: canManageTeam(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
        team_invites: state.teams.invites,
        loading: state.teams.loading_team_invites,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadTeamInvites: (token) => dispatch(actions.loadTeamInvitesAction(token)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
