import React from 'react';

class component extends React.Component {
    render() {
        let first
        let last

        let first_input = this.props.first
        let last_input = this.props.last

        if (this.props.model) {
            first_input = this.props.model.first_name
            last_input = this.props.model.last_name
        }

        if (first_input && first_input.length > 0) {
            first = first_input.charAt(0).toUpperCase();
        }

        if (last_input && last_input.length > 0) {
            last = last_input.charAt(0).toUpperCase();
        }

        if (!first && last_input && last_input.length > 1) {
            first = last_input.charAt(0).toUpperCase();

            const parts = last_input.split(' ');
            if (parts.length > 1) {
                last = parts[1].charAt(0).toUpperCase();
            } else {
                last = last_input.charAt(1).toUpperCase();
            }
        }

        if (!last && first_input && first_input.length > 1) {
            const parts = first_input.split(' ');
            if (parts.length > 1) {
                last = parts[1].charAt(0).toUpperCase();
            } else {
                last = first_input.charAt(1).toUpperCase();
            }
        }

        if (!first && !last) {
            first = 'S'
            last = 'H'
        }

        let primary = this.props.primary ? this.props.primary : '#CC0000'
        let secondary = this.props.secondary ? this.props.secondary : '#606060'

        if (this.props.theme) {
            primary = this.props.theme.primary_color ? this.props.theme.primary_color : primary;
            secondary = this.props.theme.secondary_color ? this.props.theme.secondary_color : secondary;
        }

        const size = this.props.size ? this.props.size : 32

        return (
            <div>
                {!this.props.image_url && <div style={{ color: primary, fontSize: (size / 2), fontWeight: 'bold', width: size, height: size, background: secondary, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div>{first}{last}</div>
                </div>}
                {this.props.image_url && <img alt={''} width={size} src={this.props.image_url} className="Circle" />}
            </div>
        );
    }
}

export default component;
