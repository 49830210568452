import React from 'react';

import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import DateTimeInput from "../../../../components/UI/DateTimeInput/DateTimeInput";
import { doPut } from "../../../../axios-main";
import { withRouter } from "react-router-dom";
import { empty, getToken } from "../../../../components/functions";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from '../../../../components/UI/FormComponent/FormComponent';
import SelectPanel from "../../../../components/UI/SelectPanel/SelectPanel";

class detail extends FormComponent {
    state = {
        loading: false,
        video_title: {
            id: 'video_title',
            label: 'Video Title',
            value: '',
            valid: true,
            focus: true,
            validate: { blank: false, max_length: 61 }
        },
        occurred_on: new Date(),
        video_kind: 10,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item && this.props.item && (prevProps.item.video_id !== this.props.item.video_id)) {
            this.reset();

            const { item } = this.props;

            this.setState({
                ...this.state,
                video_title: { ...this.state.video_title, value: empty(item.video_title) },
                occurred_on: new Date(item.occurred_on),
                video_kind: item.video_kind,
            });
        }
    }

    submitHandler = (event) => {
        event.preventDefault();

        const pattern = /^[a-zA-Z0-9._$!() -]{1,60}$/

        if (!this.state.video_title.valid || !pattern.test(this.state.video_title.value.trim())) {
            this.setState({ ...this.state, video_title: { ...this.state.video_title, message: 'Required' } });
        } else {
            this.setLoading(true);

            doPut(this.props.token, `/videos`, {
                video_id: this.props.item.video_id,
                video_title: this.state.video_title.value,
                occurred_on: this.state.occurred_on,
                video_kind: this.state.video_kind ? Number(this.state.video_kind) : 10,
            },
                () => {
                    this.reset();
                    this.setLoading(false);
                    this.props.close(null, true);
                },
                (r) => {
                    this.setLoading(false);
                    this.setError(r.response.data.message);
                });
        }
    };

    onClose = (event) => {
        this.reset();
        this.props.close(event);
    }

    render() {
        let form = (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Update a Video" close={this.onClose} />
                <Container style={{ background: 'white', padding: 20 }}>
                    <Form onSubmit={this.submitHandler}>
                        {this.getAlert()}

                        <TextInput disabled={this.state.loading} info={this.state.video_title}
                            change={(event, info) => this.onChange(event, info)} />

                        <SelectPanel disabled={this.state.loading} label={'Kind'} value={this.state.video_kind} items={this.props.video_kinds}
                            change={(event) => this.onChangeField(event, 'video_kind')} />

                        <DateTimeInput disabled={this.state.loading} id="occurred_on" label="Occurred" value={this.state.occurred_on}
                            onChange={(event) => this.onChangeDate(event, 'occurred_on')} />

                        <div className={'Buttons'}>
                            <Button disabled={this.state.loading} variant="contained" color="primary" type="submit"> SUBMIT </Button>
                        </div>
                    </Form>
                </Container>
            </Dialog>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        team: state.teams.team,
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(detail));
