export const state = {
    loading: false,
    title: {
        id: 'title',
        label: 'Title',
        value: '',
        valid: false,
        focus: true,
        validate: { blank: false, max_length: 100 }
    },
    city: {
        id: 'city',
        label: 'City',
        value: '',
        valid: false,
        validate: { max_length: 100 }
    },
    country: {
        id: 'country',
        label: 'Country',
        value: '',
        valid: false,
        validate: { max_length: 200 }
    },
    line1: {
        id: 'line1',
        label: 'Line 1',
        value: '',
        valid: false,
        validate: { max_length: 50 }
    },
    line2: {
        id: 'line2',
        label: 'Line 2',
        value: '',
        valid: false,
        validate: { blank: false, max_length: 50 }
    },
    postal_code: {
        id: 'postal_code',
        label: 'Postal Code',
        value: '',
        valid: false,
        validate: { blank: false, max_length: 50 }
    },
    region: {
        id: 'region',
        label: 'Region',
        value: '',
        valid: false,
        validate: { blank: false, max_length: 50 }
    },
    map_url: {
        id: 'map_url',
        label: 'Map URL',
        value: '',
        valid: false,
        validate: { blank: false, max_length: 50 }
    },
    notes: {
        id: 'notes',
        label: 'Notes',
        value: '',
        valid: false,
        validate: { max_length: (1024 * 128) }
    }
};