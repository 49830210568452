import React, { Component } from 'react';
import TextField from "@material-ui/core/TextField";

class TextInput extends Component {
    state = {
        focus: false,
        error: false,
        message: '',
    };

    onPress = (event) => {
        if (this.props.press) {
            this.props.press(event);
        }
    };

    onBlur = (event) => {
        if (this.props.blur) {
            this.props.blur(event);
        }
    };

    onChange = (event) => {
        const rules = this.props.info.validate;
        let v = event ? event.target.value : this.props.info.value;

        let valid = true;

        this.setState({ ...this.state, error: false, focus: false, message: '' });

        const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        const usernamePattern = /^[a-zA-Z0-9._-]{1,50}$/;

        const maxlength =  Number(this.props.maxlength) ? Number(this.props.maxlength) : 200

        if (v.length > maxlength) {
            v = v.substring(0, maxlength)
        }

        if (rules.email === true && !emailPattern.test(v.trim().toLowerCase())) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Please use a valid email address.'
            });
        }

        if (rules.username === true && !usernamePattern.test(v.trim())) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Only letters, numbers, periods, hyphens and underscores are allowed.'
            });
        }

        if (rules.blank === false && !v.trim()) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Cannot be blank.'
            });
        }

        if (rules.length > 0 && (v.length !== rules.length)) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Must be ' + rules.length + ' characters.'
            });
        }

        if (rules.min_length > 0 && (v.length < rules.min_length)) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Must be at least ' + rules.min_length + ' characters long.'
            });
        }

        if (rules.max_length > 0 && (v.length > rules.max_length)) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Must be less than ' + rules.max_length + ' characters long.'
            });
        }

        if (rules.min_value > 0 && (v < rules.min_value)) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Must be ' + rules.min_value + ' or more.'
            });
        }

        if (rules.max_value > 0 && (v > rules.max_value)) {
            valid = false;
            this.setState({
                ...this.state,
                error: true,
                focus: true,
                message: 'Must be ' + rules.max_value + ' or less.'
            });
        }

        this.props.change(event, { ...this.props.info, value: v, valid: valid, message: '' });
    };

    render() {
        let error = this.state.error;
        let focus = this.state.focus;
        let message = this.state.message;

        if (this.props.info) {
            if (this.props.info.focus) {
                focus = true;
            }

            if (this.props.info.message) {
                error = true;
                focus = true;
                message = this.props.info.message;
            }

            return (
                <TextField
                    id={this.props.info.id}
                    type={this.props.info.type}
                    value={this.props.info.value}
                    label={this.props.info.label}
                    disabled={this.props.disabled}
                    margin="normal"
                    style={{ width: '100%' }}
                    onChange={(event) => this.onChange(event)}
                    onKeyPress={e => {
                        this.onPress(e)
                    }}
                    onBlur={e => {
                        this.onBlur(e)
                    }}
                    autoFocus={focus}
                    error={error}
                    helperText={message}
                />
            );
        } else {
            return <div>NO INFO PROPS</div>;
        }
    }
};

export default TextInput;
