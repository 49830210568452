import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getToken } from '../../../components/functions';
import Spinner from "../../../components/UI/Spinner/Spinner";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import RelationshipRow from "./Row/Relationship";
import Button from "@material-ui/core/Button";
import Create from "./Create/Create"
import Detail from "./Detail/Detail"

class Family extends Component {
    state = {
        is_detail: false,
        is_create: false,
        participant: {},
        member: {},
    };

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true, is_detail: false })
    };

    onDetail = (e, model) => {
        e.stopPropagation()

        if (this.props.manage_team || this.props.team_member_relationship?.modify_participant_allowed) {
            this.setState({...this.state, is_create: false, is_detail: true, member: model })
        }
    };

    onClose = (e, created) => {
        if (e) {
            console.log('onClose e', e)
            e.stopPropagation()
        }

        if (created) {
            this.props.created()
        }

        this.setState({ ...this.state, is_create: false, is_detail: false, model: {} })
    };

    render() {
        let table = <Spinner />;

        if (!this.state.loading) {
            table = <h2>Send out some invites to your family members to share how you are participating on the team.</h2>
        }

        const { participant } = this.props
        const { relationships } = participant

        if (relationships && relationships.length > 0) {
            table = <Paper style={{ marginBottom: 15 }}>
                <Table aria-label="team members table" size={'small'} className={'Table'}>
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Relationship</TableCell>
                            <TableCell align="left"><div>Manage</div><div>Billing</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Team</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Photos</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Videos</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Members</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Videos</div></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relationships.map(row => {
                            return (
                                <RelationshipRow onClick={(e, model) => this.onDetail(e, model)}
                                    key={`${row.member_email_address}${row.created_on}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        }

        return (
            <div>
                {(this.props.manage_team || this.props.team_member_relationship?.invite_others) && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> Invite Family </Button>
                </div>}

                {table}

                <Create open={this.state.is_create} close={(e, created) => this.onClose(e, created)} participant={this.props.participant} />
                <Detail open={this.state.is_detail} close={this.onClose} participant={this.props.participant} member={this.state.member} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Family));
