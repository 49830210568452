import React from 'react';

import ChipInput from 'material-ui-chip-input';
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { withRouter } from "react-router-dom";
import { doPost } from "../../../../axios-main";
import TeamMemberPermissions from "../../../../components/UI/Permission/TeamMemberPermissions";
import "../../../Manage/Member/List/List.css";
import { getToken, checkEmail } from "../../../../components/functions";
import Radio from '@material-ui/core/Radio';
import {
    MANAGE_BILLING,
    MANAGE_TEAM,
    MANAGE_PHOTOS,
    MANAGE_VIDEOS,
    VIEW_MEMBERS,
    VIEW_VIDEOS
} from "../../../../constants/permissions";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from "../../../../components/UI/FormComponent/FormComponent";

class create extends FormComponent {
    state = {
        emails: [],
        invite_others_allowed: true,
        modify_participant_allowed: true,
        permissions: 48,
        defined_permission: 'spectator',
        step: 1,
    };

    onClose = (e, created) => {
        if (e) {
            e.stopPropagation()
        }

        this.reset()

        this.props.close(e, created)

        this.clear();
    }

    clear = () => {
        this.reset()
        this.setState({
            ...this.state,
            emails: [],
            invite_others_allowed: true,
            modify_participant_allowed: true,
            permissions: 48,
            defined_permission: 'spectator',
            step: 1,
        });
    }

    componentDidMount() {
        this.reset()
        this.clear();
    }

    componentWillUnmount() {
        this.reset()
        this.clear();
    }

    onChangePermissions = (permissions) => {
        this.reset();

        this.setState({
            ...this.state, permissions: permissions
        });
    };

    definedPermission = (e, defined_permission) => {
        this.reset();

        let permissions = 0;

        switch (defined_permission) {
            case 'spectator':
                permissions = permissions | VIEW_MEMBERS;
                permissions = permissions | VIEW_VIDEOS;
                console.log(permissions)
                break;
            case 'player':
                permissions = permissions | VIEW_VIDEOS;
                permissions = permissions | VIEW_MEMBERS;
                break;
            case 'coach':
                permissions = permissions | MANAGE_TEAM;
                permissions = permissions | MANAGE_PHOTOS;
                permissions = permissions | MANAGE_VIDEOS;
                permissions = permissions | VIEW_MEMBERS;
                permissions = permissions | VIEW_VIDEOS;
                break;
            case 'manager':
                permissions = permissions | MANAGE_BILLING;
                permissions = permissions | MANAGE_TEAM;
                permissions = permissions | MANAGE_PHOTOS;
                permissions = permissions | MANAGE_VIDEOS;
                permissions = permissions | VIEW_MEMBERS;
                permissions = permissions | VIEW_VIDEOS;
                break;
            case 'other':
                permissions = permissions | VIEW_MEMBERS;
                permissions = permissions | VIEW_VIDEOS;
                break;
            default:
                permissions = 0;
        }

        this.setState({
            ...this.state, defined_permission: defined_permission, permissions: permissions
        });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.reset();

        const { email, emails } = this.state;

        const list = [];

        if (email && email.value && email.value.trim()) {
            list.push(this.state.email.value.trim());
        }

        emails.forEach((e) => {
            if (e.trim()) {
                list.push(e.trim());
            }
        });

        doPost(this.props.token, `/participants/invites`, {
            level_index: this.props.participant.level_index,
            participant_index: this.props.participant.participant_index,
            season_index: this.props.participant.season_index,
            email_addresses: list,
            invite_others_allowed: this.state.invite_others_allowed,
            modify_participant_allowed: this.state.modify_participant_allowed,
            team_member_permissions: this.state.permissions,
        }, () => {
            this.onClose(null, true)
        }, (r) => {
            this.setError(r.response.data.message);
        });
    };

    render() {
        const { defined_permission } = this.state;

        let form = (
            <div style={{ padding: 10 }}>
                {this.getAlert()}

                {this.state.step === 1 && <div>

                    <div style={{ flexGrow: 1 }}>
                        <h1>Step 1 of 2: Email Addresses</h1>
                        <h3>Enter the email addresses of the people to add to this player.</h3>
                        <ChipInput
                            blurBehavior={'add'}
                            InputProps={{ autoFocus: true }}
                            allowDuplicates={false}
                            fullWidth={true}
                            label={'Email(s)'}
                            value={this.state.emails}
                            newChipKeyCodes={[13, 32]}
                            newChipKeys={['Enter', 'Space', 'SpaceBar']}
                            onBeforeAdd={(email) => { return (checkEmail(email.toLowerCase()) ? true : false) }}
                            onAdd={(email) => this.onAddEmail(email)}
                            onDelete={(email, index) => this.onRemoveEmail(email, index)}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <FormControlLabel
                            label="Update Player"
                            control={
                                <Checkbox
                                    disabled={this.props.loading}
                                    checked={this.state.modify_participant_allowed}
                                    onChange={this.onCheck('modify_participant_allowed')}
                                    inputProps={{
                                        'aria-label': 'modify participant allowed checkbox',
                                    }}
                                    color='primary'
                                />
                            }
                        />

                        <FormControlLabel
                            label="Invite Others"
                            control={
                                <Checkbox
                                    disabled={this.props.loading}
                                    checked={this.state.invite_others_allowed}
                                    onChange={this.onCheck('invite_others_allowed')}
                                    inputProps={{
                                        'aria-label': 'invite others allowed checkbox',
                                    }}
                                    color='primary'
                                />
                            }
                        />
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <Button variant="contained" color="primary" type="button" onClick={() => this.setState({ ...this.state, step: 2 })}> NEXT </Button>
                    </div>
                </div>}
                {this.state.step === 2 && <div>
                    <h1>Step 2 of 2: Permissions</h1>
                    <h3>What will this person be able to do in the system?</h3>
                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <FormControlLabel value="spectator" control={<Radio
                            checked={defined_permission === 'spectator'}
                            onChange={(e) => this.definedPermission(e, 'spectator')}
                            value="spectator"
                            name="spectator-option"
                            inputProps={{ 'aria-label': 'Spectator' }}
                        />} label="Spectator" />

                        <FormControlLabel value="player" control={<Radio
                            checked={defined_permission === 'player'}
                            onChange={(e) => this.definedPermission(e, 'player')}
                            value="player"
                            name="player-option"
                            inputProps={{ 'aria-label': 'Player' }}
                        />} label="Player" />

                        <FormControlLabel value="coach" control={<Radio
                            checked={defined_permission === 'coach'}
                            onChange={(e) => this.definedPermission(e, 'coach')}
                            value="coach"
                            name="coach-option"
                            inputProps={{ 'aria-label': 'Coach' }}
                        />} label="Coach" />

                        <FormControlLabel value="manager" control={<Radio
                            disabled={this.state.loading}
                            checked={defined_permission === 'manager'}
                            onChange={(e) => this.definedPermission(e, 'manager')}
                            value="manager"
                            name="manager-option"
                            inputProps={{ 'aria-label': 'Manager' }}
                        />} label="Manager" />

                        <FormControlLabel value="other" control={<Radio
                            checked={defined_permission === 'other'}
                            onChange={(e) => this.definedPermission(e, 'other')}
                            value="other"
                            name="other-option"
                            inputProps={{ 'aria-label': 'Other' }}
                        />} label="Other" />
                    </div>

                    {(this.state.defined_permission === 'other') &&
                        <TeamMemberPermissions permissions={this.state.permissions} change={(permissions) => {
                            this.onChangePermissions(permissions)
                        }} />}

                    <div style={{ paddingTop: 10 }}>
                        <Button style={{ marginRight: 5 }} variant="contained" color="secondary" type="button" onClick={() => this.setState({ ...this.state, step: 1 })}> BACK </Button>
                        <Button variant="contained" color="primary" type="button" onClick={this.submitHandler}> SUBMIT </Button>
                    </div>
                </div>}
            </div>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Add Family" close={this.onClose} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        team: state.teams.team,
        season: state.seasons.season,
        token: getToken(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
