import React, { Component } from 'react';
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import Update from "./Update";
import Chat from "./Chat";
import Members from "./Member/List";
import Member from "./Member/Add";
import Image from "./Member/Image";
import ChatIcon from '@material-ui/icons/Chat';
import MemberIcon from '@material-ui/icons/Person';
import UpdateIcon from '@material-ui/icons/Update';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ImageIcon from '@material-ui/icons/Image';
import Tooltip from '@material-ui/core/Tooltip';
import { pushPath, getPath } from "../../../components/functions";

class index extends Component {
    state = {
        squad: {},
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Number(this.props.match.params.squad_id) && (this.props.match.params.squad_id !== this.state.squad.id)) {
            
        }
    }

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.squads) {
                this.loadSquad(this.props.squads, this.props.match.params.squad_id);

                clearInterval(i);
            }
        }, 0);
    }

    loadSquad = (squads, squad) => {
        if (squads && squad) {
            const s = squads.find(s => s.id === Number(squad));
            if (s) {
                this.setState({
                    ...this.state,
                    squad: s,
                })
            }
        }
    };

    render() {
        let path;
        if (this.props.match && this.props.match.url) {
            path = this.props.match.url;
        }

        const { squad_id } = this.props.match.params;

        if (this.props.team && this.props.season) {
            path = getPath(this.props) + '/squads/' + squad_id + "/";

        }

        this.path = path;

        let title = "Chat";

        if (this.props.location.pathname.endsWith('/image')) {
            title = "Image";
        }

        if (this.props.location.pathname.endsWith('/update')) {
            title = "Details";
        }

        if (this.props.location.pathname.endsWith('/add')) {
            title = "Invite";
        }

        if (this.props.location.pathname.endsWith('/members')) {
            title = "Members";
        }

        return (
            <div>
                <div style={{ display: "flex", flexDirection: 'column' }}>
                    <div style={{ display: 'flex', background: 'black', padding: 3 }}>
                        <h3 style={{ flex: '1 1 auto', color: 'white', padding: '5px 5px 0 5px', margin: 0 }}>
                            {this.state.squad ? this.state.squad.title : ''} - {title}
                        </h3>
                        <div>
                            <Tooltip title="Chat">
                                <ChatIcon className="PageAction" onClick={() => pushPath(this.props, `squads/${squad_id}`)} />
                            </Tooltip>
                            <Tooltip title="Members">
                                <MemberIcon className="PageAction" onClick={() => pushPath(this.props, `squads/${squad_id}/members`)} />
                            </Tooltip>
                            <Tooltip title="Add Member">
                                <PersonAddIcon className="PageAction" onClick={() => pushPath(this.props, `squads/${squad_id}/add`)} />
                            </Tooltip>
                            <Tooltip title="Update">
                                <UpdateIcon className="PageAction" onClick={() => pushPath(this.props, `squads/${squad_id}/update`)} />
                            </Tooltip>
                            <Tooltip title="Image">
                                <ImageIcon className="PageAction" onClick={() => pushPath(this.props, `squads/${squad_id}/image`)} />
                            </Tooltip>
                        </div>
                    </div>
                    <div style={{ flexGrow: 1 }}>
                        <Switch>
                            <Route path={`${path}update`}
                                render={(props) => <Update {...props} squad={squad_id} />} />
                            <Route path={`${path}members`}
                                render={(props) => <Members {...props} squad={squad_id} />} />
                            <Route path={`${path}add`}
                                render={(props) => <Member {...props} squad={squad_id} />} />
                            <Route path={`${path}image`}
                                render={(props) => <Image {...props} squad={squad_id} />} />
                            <Route path={`${path}`} component={Chat} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        team: state.teams.team,
        season: state.seasons.season,
        squads: state.teams.squads,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
