import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button} from "@material-ui/core";
import * as actions from '../../store/actions/index';
import {authReset} from "../../store/actions/auth";
import Spinner from "../../components/UI/Spinner/Spinner";
import Form from "../../components/UI/Form/Form";
import TextInput from "../../components/UI/TextInput/TextInput";
import CardHeader from "../../components/UI/Card/CardHeader/CardHeader";

class SignInReset extends Component {
    state = {
        code: {
            id: 'code',
            label: 'Code',
            value: '',
            valid: false,
            focus: true,
            validate: {blank: false, length: 6}
        },
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: {blank: false, min_length: 6, max_length: 100}
        },
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        this.props.onSwitch();
    }

    onChange = (event, info) => {
        this.setState({...this.state, [info.id]: info});
    };

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            code: {...this.state.code, message: ''},
            password: {...this.state.password, message: ''},
        });

        if (!this.state.code.valid) {
            this.setState({...this.state, code: {...this.state.token, message: 'Required'}});
        } else if (!this.state.password.valid) {
            this.setState({...this.state, password: {...this.state.token, message: 'Required'}});
        } else {
            this.props.onSignIn(this.props.username, this.state.code.value, this.state.password.value);
        }
    }

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                <TextInput info={this.state.code} change={(event, info) => this.onChange(event, info)}/>
                <TextInput info={this.state.password} change={(event, info) => this.onChange(event, info)}/>

                <Button variant="contained" color="primary" onClick={this.submitHandler}> SUBMIT </Button>
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner/>
        }

        return (
            <div style={{padding: '15px 0'}}>
                <CardHeader>Please reset your password.</CardHeader>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
        username: state.auth.username
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onSignIn: (username, code, password) => dispatch(actions.forgotPasswordCode(username, code, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInReset);
