import {grey, red} from "@material-ui/core/colors"
import { MANAGE_TEAM, MANAGE_BILLING, MANAGE_VIDEOS, VIEW_VIDEOS, VIEW_MEMBERS } from "../constants/permissions"

export const checkColors = (text, background) => {
    const tL = relativeLuminanceFromHex(text.substring(1))
    const bL = relativeLuminanceFromHex(background.substring(1))

    if ((bL < 0.25) && (tL > bL)) {
        return {text_color: background, background_color: text}
    } else {
        return {text_color: text, background_color: background}
    }
}

export const getColors = (state) => {
    let text_color = red
    let background_color = grey

    return {text_color: background_color, background_color: text_color}

    // if (state.auth.user) {
    //     if (state.teams.team) {
    //         if (state.teams.team.text_color) {
    //             text_color = {main: state.teams.team.text_color}
    //         }
    //         if (state.teams.team.background_color) {
    //             background_color = {main: state.teams.team.background_color}
    //         }
    //     }
    //
    //     if (state.team_members.team_member) {
    //         if (state.team_members.team_member.alternate_text_color) {
    //             text_color = {main: state.team_members.team_member.alternate_text_color}
    //         }
    //         if (state.team_members.team_member.alternate_background_color) {
    //             background_color = {main: state.team_members.team_member.alternate_background_color}
    //         }
    //     }
    // }
    //
    // if (text_color.main && background_color.main) {
    //     const tL = relativeLuminanceFromHex(text_color.main.substring(1))
    //     const bL = relativeLuminanceFromHex(background_color.main.substring(1))
    //
    //     if (tL > bL) {
    //         return {text_color: background_color, background_color: text_color}
    //     } else {
    //         return {text_color: text_color, background_color: background_color}
    //     }
    // } else {
    //     return {text_color: text_color, background_color: background_color}
    // }
}

export const relativeLuminanceFromHex = (hex) => {
    const rgb = hexToRgb(hex)
    return relativeLuminance(rgb.r, rgb.g, rgb.b)
}

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null
}

export const relativeLuminance = (R8bit, G8bit, B8bit) => {

    const RsRGB = R8bit / 255
    const GsRGB = G8bit / 255
    const BsRGB = B8bit / 255

    const R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4)
    const G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4)
    const B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4)

    return 0.2126 * R + 0.7152 * G + 0.0722 * B
}

export const checkEmail = (email) => {
    const emailPattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    return emailPattern.test(email.trim())
}

export const getPath = (props) => {
    if (props && props.team && props.team.id && props.season && props.season.id) {
        const team_id = props.team.id
        const season_id = props.season.id

        return `/${team_id}/${season_id}`
    } else if (props && props.match && props.match.params && props.match.params.team_id && props.match.params.season_id) {
        const team_id = props.match.params.team_id
        const season_id = props.match.params.season_id

        return `/${team_id}/${season_id}`
    } else {
        window.alert('Team ID and Season ID are needed here.')
    }
}

export const pushPath = (props, path) => {
    if (props && props.history) {
        props.history.push(`${getPath(props)}/${path}`)
    } else {
        window.alert('Cannot seem to find the history on the props.')
    }
}

export const clearPath = (props, path) => {
    if (props && props.history) {
        props.history.push(`/${path}`)
    } else {
        window.alert('Cannot seem to find the history on the props.')
    }
}

export const newPath = (props, path) => {
    if (props && props.history) {
        props.history.push(`${path}`)
    } else {
        window.alert('Cannot seem to find the history on the props.')
    }
}

export const empty = (v) => {
    return v ? v : ''
}

export const hasTeamMemberPermission = (state, permission) => {
    if (state.team_members?.team_member?.permissions) {
        return Boolean(state.team_members.team_member.permissions & permission)
    }

    return false
}

export const getTeamMemberPermissions = (state) => {
    if (state && state.team_members && state.team_members.team_member && state.team_members.team_member.permissions) {
        return state.team_members.team_member.permissions
    }

    return 0
}

export const canViewVideos = (state) => {
    return hasTeamMemberPermission(state, VIEW_VIDEOS)
}

export const canViewMembers = (state) => {
    return hasTeamMemberPermission(state, VIEW_MEMBERS)
}

export const canManageVideos = (state) => {
    return hasTeamMemberPermission(state, MANAGE_VIDEOS)
}

export const canManageTeam = (state) => {
    return hasTeamMemberPermission(state, MANAGE_TEAM)
}

export const canManageBilling = (state) => {
    return hasTeamMemberPermission(state, MANAGE_BILLING)
}

export const getToken = (state) => {
    const {session} = state.auth
    return session ? session.token : null
}

export const teamPath = (user) => {
    return 'roster'
}

export const getLocalDate = (date) => {
    if (date) {
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    } else {
        return null
    }
}

export const fieldSorter = (fields) => (a, b) => fields.map(o => {
    let dir = 1;
    if (o[0] === '-') { dir = -1; o=o.substring(1); }
    return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
}).reduce((p, n) => p ? p : n, 0);
