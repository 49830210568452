import React, {Component} from 'react';

import {getColors} from '../../functions';
import Delay from './Delay';
import './Spinner.css';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

class create extends Component {
    render() {
        let c1 = this.props.primary.main;
        let c2 = this.props.secondary.main;

        if (!c1) {
            c1 = this.props.primary[500];
        }

        if (!c2) {
            c2 = this.props.secondary[500];
        }

        return (
            <Delay delay={this.props.delay ? this.props.delay : 1200}>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <div className={'Ripple'}>
                        <div style={{borderColor: c1}}/>
                        <div style={{borderColor: c2}}/>
                    </div>
                </div>
            </Delay>
        )
    }
}

const mapStateToProps = state => {
    const {text_color, background_color} = getColors(state);

    return {
        primary: text_color,
        secondary: background_color
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
