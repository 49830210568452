import React, {Component} from 'react';
import {
    ADD_AND_REMOVE_MEMBERS_FROM_SQUAD,
    DELETE_SQUAD,
    EDIT_SQUAD_APPEARANCE,
    MANAGE_EVENTS_ON_SQUAD,
    SEND_CHATS_TO_SQUAD,
    SEND_EMAILS_TO_SQUAD
} from "../../../constants/permissions";
import {getColors} from "../../functions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Check from "./Check";
import RemoveIcon from "@material-ui/icons/Delete";

class squad extends Component {
    state = {
        check: false,
        all: false,
    }

    onChangeCheck = (event, permission, value) => {
        this.props.change(this.props.model.id, Boolean(value) ? (this.props.model.permissions & (~permission)) : (this.props.model.permissions | permission));
    };

    onPermission = (model, permission) => {
        this.props.permission(model, permission);
    };

    render() {
        return (
            <TableRow>
                {this.props.show !== false && <TableCell align="left">
                    <img width="50" className="Circle" alt={this.props.model.title}
                         src={this.props.model.image_url}/>
                </TableCell>}
                {this.props.show !== false &&
                <TableCell align="left" style={{fontSize: '1rem'}}> {this.props.model.title} </TableCell>}
                <TableCell align="left">
                    <Check label="Add and Remove Members" permissions={this.props.model.permissions}
                           permission={ADD_AND_REMOVE_MEMBERS_FROM_SQUAD}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                <TableCell align="left">
                    <Check label="Edit Appearance" permissions={this.props.model.permissions}
                           permission={EDIT_SQUAD_APPEARANCE}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                <TableCell align="left">
                    <Check label="Send Chats" permissions={this.props.model.permissions}
                           permission={SEND_CHATS_TO_SQUAD}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                <TableCell align="left">
                    <Check label="Send Emails" permissions={this.props.model.permissions}
                           permission={SEND_EMAILS_TO_SQUAD}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                <TableCell align="left">
                    <Check label="Manage Events" permissions={this.props.model.permissions}
                           permission={MANAGE_EVENTS_ON_SQUAD}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                <TableCell align="left">
                    <Check label="Delete Squad" permissions={this.props.model.permissions} permission={DELETE_SQUAD}
                           change={(e, permission, value) => this.onChangeCheck(e, permission, value)}/>
                </TableCell>
                {this.props.show !== false && <TableCell align="left">
                    <RemoveIcon style={{cursor: 'pointer', fontSize: '1.4rem'}}
                                onClick={(e) => this.onPermission(this.props.model)}/>
                </TableCell>}
            </TableRow>
        );
    }
}

const mapStateToProps = state => {
    const {text_color, background_color} = getColors(state);

    return {
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(squad))
