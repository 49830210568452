import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import View from "./View/View";
import * as actions from "../../../store/actions";
import { getToken } from "../../../components/functions";
import { doGet } from '../../../axios-main';

class video extends Component {
    state = {
        loading: false,
    }

    updateState = (field, value) => {
        this.setState({...this.state, [field]: value});
    };

    componentDidMount() {
        this.props.selectVideo(null)

        const i = setInterval(() => {
            if (this.props && this.props.team && this.props.team.team_id) {
                clearInterval(i);
                doGet(this.props.token, `/videos/angles?season_index=${this.props.match.params.season_id}&angle_index=0&video_id=${this.props.match.params.video_id}`,
                 (video) => {
                    this.updateState("message", null)
                    this.props.selectVideo(video.data)
                 }, 
                 (e) => {
                        console.log('ERROR', e.response.data.message);
                 })
            }
        }, 0);
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route video={this.state.video} exact path={`${this.props.match.path}/`} component={View}/>
                    <Route video={this.state.video} path={`${this.props.match.path}/:video_file_id`} component={View}/>
                </Switch>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectVideo: (video) => dispatch(actions.selectVideo(video)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(video));
