import React from 'react';
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { getToken, canManageTeam } from "../../../components/functions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";
import Form from "../../../components/UI/Form/Form";
import FormComponent from "../../../components/UI/FormComponent/FormComponent";
import SubmitButton from '../../../components/UI/SubmitButton/SubmitButton';
import TextInput from "../../../components/UI/TextInput/TextInput";
import { doPut } from '../../../axios-main';
import { authTeamUpdateSuccess } from '../../../store/actions/auth';

class update extends FormComponent {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 50 }
        },
        primary_color: '#606060',
        secondary_color: '#CC0000',
        sport: 1
    };

    componentDidMount() {
        const i = setInterval(() => {
            if (this.props.team) {
                clearInterval(i);

                this.setState({
                    ...this.state,
                    primary_color: this.props.team.theme.primary_color,
                    secondary_color: this.props.team.theme.secondary_color,
                    sport: this.props.team.sport,
                    title: { ...this.state.title, value: this.props.team.team_title },
                });
            }
        }, 0);
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.reset();

        if (!this.state.title.value.trim()) {
            this.setState({
                ...this.state,
                title: { ...this.state.title, message: 'Required' },
            });
        } else {
            this.props.selectingTeam();
            this.setLoading(true);

            doPut(this.props.token, '/teams', {
                title: this.state.title.value,
                theme: {
                    primary_color: this.state.primary_color,
                    secondary_color: this.state.secondary_color
                },
                sport: this.state.sport
            },
                (s) => {
                    const { user } = this.props;

                    const member_team = user.teams.findIndex(t => t.team_id === s.data.team_id);

                    if (member_team >= 0) {
                        user.teams[member_team].team_title = s.data.team_title
                        user.teams[member_team].theme = s.data.theme
                        this.props.onUpdateUser(user);
                    }

                    this.props.selectTeam(s.data);
                    this.setLoading(false);
                    this.setSuccess('Team has been updated.');
                },
                (r) => {
                    this.props.selectTeam(this.props.team);
                    this.setLoading(false);
                    this.setError(r.response.data.message);
                });
        }
    };

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                {this.getAlert()}
                <TextInput disabled={this.state.loading} info={this.state.title}
                    change={(event, info) => this.onChange(event, info)} />

                <ColorPicker disabled={this.state.loading} label="Primary Color" value={this.state.primary_color} field="primary_color"
                    change={(value, field) => {
                        this.onChangeColor(value, field)
                    }} />
                <ColorPicker disabled={this.state.loading} label="Secondary Color" value={this.state.secondary_color} field="secondary_color"
                    change={(value, field) => {
                        this.onChangeColor(value, field)
                    }} />

                <div style={{ paddingTop: 10 }}>
                    {this.props.manage_team && <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>}
                </div>
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        user: state.auth.user,
        loading: state.teams.loading,
        error: state.auth.error,
        sports: state.enums.sports ? state.enums.sports : [],
        team: state.teams.team,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectingTeam: () => dispatch(actions.selectingTeam()),
        selectTeam: (team) => dispatch(actions.selectTeam(team)),
        onUpdateUser: (user) => dispatch(authTeamUpdateSuccess(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(update);
