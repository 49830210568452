import * as actionTypes from './actionTypes';
import * as actions from "./index";

import { doGet, doPost } from '../../axios-main';

export const updateSelectedTeam = (team) => {
    return {
        type: actionTypes.UPDATE_SELECTED_TEAM,
        team: team
    };
};

export const setTeams = (teams) => {
    return {
        type: actionTypes.SET_TEAMS,
        teams: teams
    };
};

export const updateTeamsImage = (team, image) => {
    return {
        type: actionTypes.UPDATE_TEAMS_IMAGE,
        team: team,
        image: image
    };
};

export const openProfileAction = () => {
    return {
        type: actionTypes.OPEN_PROFILE,
    };
};

export const closeProfileAction = () => {
    return {
        type: actionTypes.CLOSE_PROFILE,
    };
};

export const authReset = () => {
    return {
        type: actionTypes.AUTH_RESET,
        error: null,
        success: null,
    };
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START,
        error: null,
        success: null,
    };
};

export const authCookieStart = () => {
    return {
        type: actionTypes.AUTH_COOKIE_START
    };
};

export const authFormSuccess = (message) => {
    return {
        type: actionTypes.AUTH_FORM_SUCCESS,
        error: null,
        success: message,
    };
};

export const authCookieSuccess = (user) => {
    return {
        type: actionTypes.AUTH_COOKIE_SUCCESS,
        user: user
    };
};

export const authFormFail = (error) => {
    return {
        type: actionTypes.AUTH_FORM_FAIL,
        success: null,
        error: getErrorMessage(error),
    };
};

export const authCookieFail = (error) => {
    return {
        type: actionTypes.AUTH_COOKIE_FAIL,
        refreshing: false,
        error: getErrorMessage(error),
    };
};

export const authSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user: user
    };
};

export const authTeamUpdateSuccess = (user) => {
    return {
        type: actionTypes.AUTH_TEAM_UPDATE_SUCCESS,
        user: user
    };
};

export const authVerifySuccess = (verify) => {
    return {
        type: actionTypes.AUTH_VERIFY_SUCCESS,
        success: true,
        verify: verify
    };
};

export const authQuickSuccess = (quick) => {
    return {
        type: actionTypes.AUTH_QUICK_SUCCESS,
        success: true,
        quick: quick
    };
};

export const authChangeSuccess = (change) => {
    return {
        type: actionTypes.AUTH_CHANGE_SUCCESS,
        success: true,
        change: change
    };
};

export const authForgotPasswordSuccess = (password) => {
    return {
        type: actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS,
        success: true,
        password: password
    };
};

export const authTwoStepCancel = (user) => {
    return {
        type: actionTypes.AUTH_TWO_STEP_CANCEL,
        user: user
    };
};

export const authTouchSuccess = (session) => {
    return {
        type: actionTypes.AUTH_TOUCH_SUCCESS,
        session: session,
        touch: true,
        touch_message: '',
    };
};

export const authTouchError = () => {
    return {
        type: actionTypes.AUTH_TOUCH_ERROR,
        touch: false,
        touch_message: 'Unable to contact APIs.',
    };
};

export const authTwoStep = (user) => {
    return {
        type: actionTypes.AUTH_TWO_STEP,
        user: user
    };
};

export const authForgotPassword = (username) => {
    return {
        type: actionTypes.AUTH_FORGOT_PASSWORD,
        isCode: true,
        username: username
    };
};

export const authForgotPasswordSubmit = () => {
    return {
        type: actionTypes.AUTH_CHANGED_PASSWORD,
        success: 'Password has been set.',
        loading: false,
    };
};

export const getErrorMessage = e => {
    let message = 'Unable to complete your request.';

    if (!e) {
        message = '';
    } else {
        if (e.message) {
            message = e.message;
        }

        if (e.response) {
            message = e.response.data;
        }

        if (e.request && e.request.statusText) {
            message = e.request.statusText;
        }

        if (e.response && e.response.data && e.response.data.message) {
            message = e.response.data.message;
        }
    }

    return message;
};

export const authError = (message) => {
    return {
        type: actionTypes.AUTH_ERROR,
        error: message
    };
};

export const authSetupTwoStep = (code) => {
    return {
        type: actionTypes.AUTH_SETUP_TWO_STEP,
        code: code
    };
};

export const authTwoStepSetup = (success) => {
    return {
        type: actionTypes.AUTH_TWO_STEP_SETUP,
        success: success
    };
};

export const authChangedPassword = () => {
    return {
        type: actionTypes.AUTH_CHANGED_PASSWORD,
        success: 'Password has been set.',
        loading: false,
    };
};

export const authChangedSummary = (user) => {
    return {
        type: actionTypes.AUTH_CHANGED_SUMMARY,
        success: 'Summary has been updated.',
        user: user,
        loading: false,
    };
};

export const authPasswordReset = (username) => {
    return {
        type: actionTypes.AUTH_PASSWORD_RESET,
        username: username,
        loading: false,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        loading: false,
        error: getErrorMessage(error),
    };
};

export const logout = () => {
    localStorage.removeItem('sign_in_store');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const signIn = (username, password, remember_me) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/sign-in/password', {
            identity: username,
            password: password,
            remember_me: remember_me
        }, (r) => {
            if (remember_me && r.data.cookie) {
                localStorage.setItem('sign_in_store', r.data.cookie)
            }
            dispatch(authSuccess(r.data));
        }, (r) => {
            dispatch(authFail(r));
            console.log('ERROR 3', r);
        });
    };
};

export const verify = (token) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/members/verified', { token: decodeURIComponent(token) }, r => dispatch(authVerifySuccess(r.data)), r => dispatch(authFail(r)));
    };
};

export const quick = (token) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/sign-in/quick', { token: decodeURIComponent(token) }, r => dispatch(authQuickSuccess(r.data)), r => dispatch(authFail(r)));
    };
};

export const change = (token) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/sign-in/change', { token: decodeURIComponent(token) }, r => dispatch(authChangeSuccess(r.data)), r => dispatch(authFail(r)));
    };
};

export const sendForgotPassword = (identity) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/links/forgot-password', { identity: identity }, r => dispatch(authFormSuccess('An email has been sent.')), r => dispatch(authFormFail(r)));
    };
};

export const forgotPassword = (token, password, remember_me) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/members/forgot-password', { token: decodeURIComponent(token), password: password, remember_me: remember_me }, r => {
            if (remember_me && r.data.cookie) {
                localStorage.setItem('sign_in_store', r.data.cookie)
            }

            dispatch(authForgotPasswordSuccess(r.data))
        }, r => dispatch(authFormFail(r)));
    };
};

export const forgotPasswordCode = (username, code, password) => {
    return dispatch => {
        dispatch(authStart());
    };
};

export const cookie = (cookie) => {
    return dispatch => {
        dispatch(authCookieStart());
        if (cookie) {
            doPost('', '/sign-in/cookie', { cookie: cookie }, r => dispatch(authCookieSuccess(r.data)), r => dispatch(authCookieFail(r)));
        }
    };
};

export const quickSignIn = (identity, remember_me) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/links/quick-sign-in', { identity: identity, remember_me: remember_me }, r => {
            if (remember_me && r.data.cookie) {
                localStorage.setItem('sign_in_store', r.data.cookie)
            }

            dispatch(authFormSuccess('An email has been sent.'))
        }, r => dispatch(authFormFail(r)));
    };
};

export const signUp = (state) => {
    return dispatch => {
        dispatch(authStart());

        doPost('', '/members', {
            user_name: state.username.value,
            password: state.password.value,
            email_address: state.email.value,
            first_name: state.first_name.value,
            last_name: state.last_name.value
        }, () => {
            dispatch(authFormSuccess('An email has been sent. Please click the link to complete the sign up process.'));
        }, (r) => {
            dispatch(authFail(r));
            console.log('ERROR 3', r);
        });
    };
};


export const changePassword = (oldPassword, newPassword) => {
    return dispatch => {
        dispatch(authStart());
    };
};

export const changeSummary = (user, first_name, last_name, user_name, email_address) => {
    return dispatch => {
        dispatch(authStart());
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    return dispatch => {
        const cookie = localStorage.getItem('sign_in_store');
        if (!cookie) {
            dispatch(logout());
        } else {
            dispatch(actions.cookie(cookie));
        }
    };
};

export const onTouch = (session) => {
    return dispatch => {
        if (session) {
            doGet(session, '/sessions/touch?limit=25',
                r => dispatch(authTouchSuccess(r.data)),
                (e) => {
                    dispatch(authTouchError())
                }
            );
        }
    };
};

export const offMultiFactorAuthenitcation = (user) => {
    return () => {
    };
};

export const setupMultiFactorAuthenitcation = (user) => {
    return dispatch => {
    };
};

export const twoStep = (user, token) => {
    return () => {
    };
};

export const onMultiFactorAuthenitcationSetup = (user, code) => {
    return dispatch => {
    };
};

export const onMultiFactorAuthenitcationSubmit = (user, code) => {
    return dispatch => {
    };
};

export const onMultiFactorAuthenitcationCancel = () => {
    return dispatch => {
    };
};