import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    grade_levels: null,
};

const loadGradeLevelSuccess = (state, action) => {
    return updateObject(state, {
        grade_levels: action.grade_levels,
    });
};

const loadVideoKindSuccess = (state, action) => {
    return updateObject(state, {
        video_kinds: action.video_kinds,
    });
};

const loadGameKindSuccess = (state, action) => {
    return updateObject(state, {
        game_kinds: action.video_kinds,
    });
};

const loadEventKindSuccess = (state, action) => {
    return updateObject(state, {
        event_kinds: action.video_kinds,
    });
};

const loadSportSuccess = (state, action) => {
    return updateObject(state, {
        sports: action.sports,
    });
};

const loadRelationshipSuccess = (state, action) => {
    return updateObject(state, {
        relationships: action.relationships,
    });
};

const loadTeamMemberSecurityRightsSuccess = (state, action) => {
    return updateObject(state, {
        team_member_security_rights: action.team_member_security_rights,
    });
};

const loadSquadSecurityRightsSuccess = (state, action) => {
    return updateObject(state, {
        squad_security_rights: action.squad_security_rights,
    });
};

const loadVideoFileStatus = (state, action) => {
    return updateObject(state, {
        video_file_status: action.video_file_status,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOAD_VIDEO_FILE_STATUS:
            return loadVideoFileStatus(state, action);
        case actionTypes.LOAD_GRADE_LEVEL_SUCCESS:
            return loadGradeLevelSuccess(state, action);
        case actionTypes.LOAD_EVENT_KIND_SUCCESS:
            return loadEventKindSuccess(state, action);
        case actionTypes.LOAD_GAME_KIND_SUCCESS:
            return loadGameKindSuccess(state, action);
        case actionTypes.LOAD_VIDEO_KIND_SUCCESS:
            return loadVideoKindSuccess(state, action);
        case actionTypes.LOAD_SPORT_SUCCESS:
            return loadSportSuccess(state, action);
        case actionTypes.LOAD_RELATIONSHIP_SUCCESS:
            return loadRelationshipSuccess(state, action);
        case actionTypes.LOAD_TEAM_MEMBER_SECURITY_RIGHTS_SUCCESS:
            return loadTeamMemberSecurityRightsSuccess(state, action);
        case actionTypes.LOAD_SQUAD_SECURITY_RIGHTS_SUCCESS:
            return loadSquadSecurityRightsSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
