import React, { Component } from 'react';
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getToken, canManageTeam } from "../../../../components/functions";
import Row from "../Row/Row";
import Create from "../Create/Create"
import Detail from "../Detail/Detail"

class list extends Component {
    state = {
        is_detail: false,
        is_create: false,
    };

    componentDidMount() {
        setTimeout(() => this.props.getSeasons(this.props.token), 0);
    }

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true })
    };

    onDetail = (e, season) => {
        e.stopPropagation()
        if (this.props.manage_team) {
            this.setState({ ...this.state, is_detail: true, season: season })
        }
    };

    onClose = (e, load) => {
        if (e) {
            e.stopPropagation()
        }

        if (load) {
            this.props.getSeasons(this.props.token);
        }

        this.setState({ ...this.state, is_create: false, is_detail: false, season: null })
    };

    render() {
        const seasons = this.props.seasons ? this.props.seasons : [];

        return (
            <div>
                {this.props.manage_team && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> New Season </Button>
                </div>}

                <Table aria-label="season table">
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">Start</TableCell>
                            <TableCell align="left">Players</TableCell>
                            <TableCell align="left">Storage Usage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {seasons.map(row => {
                            return (
                                <Row onClick={(e, season) => this.onDetail(e, season)}
                                    key={`${row.season_index}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>

                <Create open={this.state.is_create} close={this.onClose} />
                <Detail open={this.state.is_detail} close={this.onClose} season={this.state.season} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        team: state.teams.team,
        seasons: state.seasons.seasons,
        loading: state.seasons.loading,
        success: state.seasons.success,
        error: state.seasons.error,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSeasons: (token) => dispatch(actions.getSeasons(token)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(list);
