import React, { Component } from 'react';
import {
    MANAGE_BILLING,
    MANAGE_TEAM,
    MANAGE_PHOTOS,
    MANAGE_VIDEOS,
    VIEW_MEMBERS,
    VIEW_VIDEOS
} from "../../../constants/permissions";
import Check from "./Check";
import styles from "./TeamMemberPermissions.module.css";

class permission extends Component {
    state = { all: false };

    onChangeCheck = (event, permission, value) => {
        const { permissions } = this.props;

        this.props.change(Boolean(value) ? (permissions & (~permission)) : (permissions | permission));
    };

    render() {
        const { permissions } = this.props;

        return (
            <div className={styles.Container}>
                <div className={styles.Row}>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="Manage Billing" permissions={permissions} permission={MANAGE_BILLING}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="Manage Team" permissions={permissions} permission={MANAGE_TEAM}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                </div>
                <div className={styles.Row}>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="Manage Photos" permissions={permissions} permission={MANAGE_PHOTOS}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="Manage Videos" permissions={permissions} permission={MANAGE_VIDEOS}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                </div>
                <div className={styles.Row}>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="View Members" permissions={permissions} permission={VIEW_MEMBERS}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                    <div className={styles.Cell}>
                        <Check disabled={this.props.disabled} label="View Videos" permissions={permissions} permission={VIEW_VIDEOS}
                            change={(e, permission, value) => this.onChangeCheck(e, permission, value)} />
                    </div>
                </div>
            </div>
        );
    }
}

export default permission;
