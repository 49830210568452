import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Button from "@material-ui/core/Button";
import Card from "../../components/UI/Card/Card";
import logo from "../../assets/images/logo.svg";
import { clearPath, getToken } from "../../components/functions";

class Home extends Component {
    componentDidMount() {
        const { props } = this;
        const { auth } = props ? props : {};
        const { user } = auth ? auth : {};
        const { teams } = user ? user : {};

        const participant_invite_token = localStorage.getItem('participant_invite_token');

        if (participant_invite_token) {
            clearPath(this.props, `participant_invite/${participant_invite_token}`)
        } else {
            this.props.resetTeam();

            if (teams && teams.length > 0) {
                if (teams[0].last_season_id) {
                    console.log('last season');
                } else {
                    const team = teams[0] ? teams[0] : {};
                    clearPath(this.props, `${team.team_id}/${team.last_entered_season_index}/roster`)
                }
            }
        }
    }

    render() {
        return (
            <Card>
                <div><img src={logo} alt="SportsHub" width={'50%'} /></div>
                <h1>Welcome to SportsHub.io</h1>
                <h2>You are not a part of a team.</h2>
                <h3>Would you like to create a team?</h3>

                <Button variant="contained" color="primary" onClick={this.props.openTeamCreate}> Create Team </Button>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        auth: state.auth,
        member: state.auth.member,
        environment: state.auth.environment,
        session: state.auth.session,
        loading: state.home.loading
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onFetchHome: (sessionId) => dispatch(actions.fetchHome(sessionId)),
        openTeamCreate: () => dispatch(actions.openTeamCreateAction()),
        resetTeam: () => dispatch(actions.resetTeamAction())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
