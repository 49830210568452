import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getToken, clearPath } from "../../components/functions";
import Success from '../../components/UI/Alert/Success';
import Error from '../../components/UI/Alert/Error';
import Spinner from '../../components/UI/Spinner/Spinner';
import Card from "../../components/UI/Card/Card";
import { doGet, doPut } from "../../axios-main";
import SignInInviteForm from "../Auth/ParticipantSignInInviteForm";
import SignUpInviteForm from "../Auth/ParticipantSignUpInviteForm";
import AuthTabs from '../Auth/AuthTabs';
import InviteFormInfo from '../Auth/InviteFormInfo';

class invite extends Component {
    _isMounted = false;

    state = {
        tab: 0,
        error: '',
    };

    componentDidMount() {
        this._isMounted = true;

        setTimeout(() => {
            this.loadInvite()
        }, 250);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadInvite = () => {
        localStorage.setItem('participant_invite_token', this.props.match.params.token)

        doGet('', '/members/invites/participants?token=' + this.props.match.params.token, r => {
            if (this._isMounted) {
                this.setState({ invite: r.data })
                if (r.data.email_address_exists === true) {
                    this.setState({ ...this.state, tab: 0 })
                } else {
                    this.setState({ ...this.state, tab: 1 })
                }
            }
        }, r => {
            localStorage.removeItem('participant_invite_token')
            this.setLoading(false)
            console.log('GET ERROR', r)
            if (!this.props.token) {
                this.setState({ ...this.state, tab: 0 })
            }
            clearPath(this.props, '')
        })
    }

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info })
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.setLoading(true);

        doPut(this.props.token, `/members/invites/participants`, {
            token: decodeURIComponent(this.props.match.params.token),
            relationship: this.state.relationship,
            relationship_other: this.state.relationship_other.value,
            hide_contact_info: true,
        }, (r) => {
            this.setLoading(false);
            localStorage.removeItem('participant_invite_token')
            clearPath(this.props, `${r.data.team_id}/${r.data.season_index}`);
        }, (r) => {
            this.setLoading(false);
            console.log('ERROR', r);
        });
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    onChangeField = (event, field) => {
        this.setState({ ...this.state, [field]: event.target.value });
    };

    handleChange = (event, value) => {
        event.stopPropagation()
        this.setState({ ...this.state, tab: value })
    };

    render() {

        let form;
        if (this.state.tab === 0) {
            form = <div>
                <h2>Please sign in to accept this invitation.</h2>
                <SignInInviteForm invite={this.state.invite} token={this.props.match.params.token} />
            </div>
        } else if (this.state.tab === 1) {
            form = <div>
                <SignUpInviteForm invite={this.state.invite} token={this.props.match.params.token} />
            </div>
        }

        let main = (
            <div>
                <h1>By Invitation Only</h1>

                <InviteFormInfo info={this.state} />

                <AuthTabs value={this.state.tab} change={this.handleChange} />

                {form}
            </div>
        );

        if (this.props.loading) {
            main = <Spinner />
        }

        let alert = null;

        if (this.props.success) {
            alert = (
                <Success>{this.props.success}</Success>
            );
            form = null;
        }

        if (this.props.error) {
            alert = (
                <Error>{this.props.error}</Error>
            );
        }

        return (
            <Card>
                {alert}
                {main}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    let user;
    if (state && state.auth && state.auth.user) {
        user = state.auth.user;
    }
    return {
        token: getToken(state),
        loading: false,
        error: state.auth.error,
        success: state.auth.success,
        isAuthenticated: state.auth.user !== null,
        user: user,
        isCode: state.auth.isCode,
        authRedirectPath: state.auth.authRedirectPath,
        relationships: state.enums.relationships,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(invite);
