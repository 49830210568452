import React, { Component } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

class input extends Component {
    render() {
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    format="MM/dd/yyyy"
                    margin="normal"
                    id={this.props.id}
                    style={{ width: '100%' }}
                    label={this.props.label}
                    value={this.props.value}
                    onChange={(event) => this.props.onChange(event)}
                    disabled={this.props.disabled} 
                />
            </MuiPickersUtilsProvider>
        );
    }
};

export default input;
