import React from 'react';

import './DialogSideNavItem.css';

const item = (props) => {
    let cs = ['Container'];
    if (props.active) {
        cs.push('active');
    }

    return (
        <div className={cs.join(' ')} onClick={props.onClick}>
            <div>{props.children}</div>
        </div>
    );
};

export default item;
