import React, {Component} from 'react';

import Dialog from "@material-ui/core/Dialog";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Update from "../Update/Update";

class edit extends Component {
    state = {
        display: 'summary',
        event: null
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({...this.state, 'season_id': this.props.season.id});
            }
        }
    }

    onChange = (event, info) => {
        this.setState({...this.state, [info.id]: info});
    };

    onUpdate = (event, value) => {
        this.setState({...this.state, event: value});
        this.props.onClose(event);
    };

    onDisplay = (event, display) => {
        this.setState({...this.state, display: display});
    };

    handleClose = (event) => {
        this.setState({...this.state, display: 'summary'});
        if (this.state.event) {
            this.props.onClose(event, this.state.event);
        } else {
            this.props.onClose(event, this.props.event);
        }
    };

    render() {
        let content = <Update value={this.props.value} change={(event, value) => this.onUpdate(event, value)} onClose={(event) => this.handleClose(event)}/>;

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={this.props.open} onClose={(event) => this.handleClose(event)} disableBackdropClick>
                <div style={{paddingTop: 64}}>
                    <div style={{display: 'flex'}}>
                        <div style={{flexGrow: '1', padding: '0 10px'}}>
                            {content}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(edit));
