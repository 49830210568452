import React from 'react';

import classes from './Alert.module.css';

const alert = (props) => {
    return (
        <div
            className={[classes.Alert, classes[props.alertType]].join(' ')}>
            {props.children}
        </div>
    );
};

export default alert;
