import React, {Component} from 'react';

import './WeightInput.css';
import TextInput from "../TextInput/TextInput";

class WeightInput extends Component {
    state = {
        pounds: {
            id: 'pounds',
            label: 'Weight (lbs)',
            value: 0,
            valid: true,
            validate: {}
        },
        ounces: {
            id: 'ounces',
            label: 'Weight (oz)',
            value: 0,
            valid: true,
            validate: {}
        },
    };

    componentDidMount() {
        if (this.props.value) {
            this.setValue(this.props.value);
        }
    }

    setValue(value) {
        if (value && Number(value) > 0) {
            this.setState({
                ...this.state,
                pounds: {...this.state.pounds, value: Math.floor(Number(value) / 16)},
                ounces: {...this.state.ounces, value: Number(value) - (Math.floor(Number(value) / 16) * 16)}
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    }

    onChange = (event, info) => {
        this.setState({...this.state, [info.id]: info});
        setTimeout(() => {
            if (this.state.pounds.value || this.state.ounces.value) {
                let p = 0;
                if (this.state.pounds.value) {
                    p = Number(this.state.pounds.value) * 16;
                }

                let o = 0;
                if (this.state.ounces.value) {
                    o = Number(this.state.ounces.value);
                }

                this.props.change(event, Number(p + o));
            } else {
                this.props.change(event, 0);
            }
        }, 0);
    };

    render() {
        return (
            <div className={'WeightInput'}>
                <div style={{marginRight: 5}}>
                    <TextInput disabled={this.props.disabled} info={this.state.pounds} change={(event, info) => this.onChange(event, info)}/>
                </div>
                <div style={{}}>
                    <TextInput disabled={this.props.disabled} info={this.state.ounces} change={(event, info) => this.onChange(event, info)}/>
                </div>
            </div>
        );
    }
}

export default WeightInput;
