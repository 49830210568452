import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import * as actions from "../../../../store/actions";
import { connect } from "react-redux";
import { getToken } from "../../../../components/functions";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Header from "../../../../components/UI/Header/Header";

import { withRouter } from "react-router-dom";
import Success from "../../../../components/UI/Alert/Success";
import Error from "../../../../components/UI/Alert/Error";
import Form from "../../../../components/UI/Form/Form";
import { doGet, doPost, doPut } from "../../../../axios-main";
import classes from "../../../../components/UI/Styles.module.css";
import Squad from "../../../../components/UI/Permission/Squad";
import SelectPanel from "../../../../components/UI/SelectPanel/SelectPanel";

class create extends Component {
    state = {
        model: {},
        member: '',
        members: [],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Number(this.props.squad) && (this.props.squad !== prevProps.squad)) {
            const squad = this.props.squads.find(s => s.id === Number(this.props.squad));
            if (squad) {
                this.setState({ ...this.state, background_color: '#ffffff', text_color: '#000000' })
            }

            this.loadMembers(this.props.squads, this.props.squad);
        }
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        let i = setInterval(() => {
            if (this.props.squads) {
                this.loadSquad(this.props.squads, this.props.squad);

                doPost(this.props.token, '/team-members/list', {}, (r) => {
                    this.setState({ ...this.state, members: r.data.items ? r.data.items : [] });
                }, (r) => {
                    console.log('ERROR', r);
                    console.log('ERROR', r.response);
                });

                clearInterval(i);
            }
        }, 0);
    }

    loadSquad = (squads, squad) => {
        if (squads && squad) {
            const s = squads.find(s => s.id === Number(squad));
            if (s) {
                this.setState({
                    ...this.state,
                    model: s,
                    title: { ...this.state.title, value: s.title },
                    background_color: s.background_color,
                    text_color: s.text_color
                })
            }
        }
    };

    loadMembers = () => {
        doGet(this.props.token, `/squad-members/${this.props.squad.id}/list`, (r) => {
            console.log(r);
        }, (r) => {
            console.log('ERROR', r);
            this.set_error(r.response.message);
        });
    };

    onChangeColor = (value, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: value });
    };

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    set_success = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'success': squad });
    };

    set_error = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'error': squad });
    };

    reset = () => {
        this.setState({ ...this.state, 'error': null });
        this.setState({ ...this.state, 'success': null });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.reset();

        const state = this.state;

        doPut(this.props.token, `/squads/${this.props.squad}/update-appearance`, {
            title: state.title.value,
            background_color: state.background_color,
            text_color: state.text_color,
        }, () => {
            this.set_success("Squad updated.");
            this.props.loadSquads(this.props.token);
        }, (r) => {
            console.log('ERROR', r);
            this.set_error(r.response.data.squad);
        });
    };

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let form = (
            <Paper style={{ margin: 10, padding: 10 }}>
                <Header> ADD TO A SQUAD </Header>

                {error}
                {success}

                <Form onSubmit={this.submitHandler}>
                    {this.state.members.length > 0 &&
                        <SelectPanel label={'Member'} value={this.state.member}
                            items={this.state.members}
                            change={(event) => this.onChangeField(event, 'member')} />}

                    {this.state.members.length <= 0 && <div>All members are in this squad.</div>}

                    <Table>
                        <TableBody>
                            <Squad show={false} model={this.state.model} />
                        </TableBody>
                    </Table>

                    <div className={classes.Buttons}>
                        <Button variant="contained" color="primary" type="submit"> Add </Button>
                    </div>
                </Form>
            </Paper>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        squads: state.teams.squads,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        loadSquads: (session) => dispatch(actions.getSquads(session)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
