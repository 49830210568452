import * as actionTypes from './actionTypes';
import { doGet } from "../../axios-main";

export const loadingSeasons = () => {
    return {
        type: actionTypes.LOADING_SEASONS,
    };
};

export const loadSeasons = (seasons) => {
    let list
    if (seasons && seasons.length > 0) {
        list = seasons.sort((a, b) => {
            if (getTime(a.start_date) === getTime(b.start_date)) {
                return 0
            }
            return (getTime(a.start_date) > getTime(b.start_date)) ? -1 : 1
        })
    }   
    return {
        type: actionTypes.LOAD_SEASONS,
        seasons: list
    };
};

export const loadSeasonsError = () => {
    return {
        type: actionTypes.LOAD_SEASONS_ERROR,
    };
};

export const selectSeason = (season) => {
    return {
        type: actionTypes.SELECT_SEASON,
        season: season,
    };
};

export const getTime = (date) => {
    return new Date(date[0], date[1], date[2]).getTime()
}

export const getSeasons = (token) => {
    return dispatch => {
        dispatch(loadingSeasons());

        doGet(token, '/seasons',
            (r) => {
                dispatch(loadSeasons(r.data));
            },
            () => {
                dispatch(loadSeasonsError());
            });
    };
};

