import React from 'react';

import styles from './DialogNavigationItem.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faUnlock } from '@fortawesome/free-solid-svg-icons/faUnlock'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen'
import { faSun } from '@fortawesome/free-solid-svg-icons/faSun'
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'

const item = (props) => {
    let icon

    switch (props.icon) {
        case 'image':
            icon = faImage
            break
        case 'password':
            icon = faUnlock
            break
        case 'invite':
            icon = faEnvelope
            break
        case 'invites':
            icon = faEnvelopeOpen
            break
        case 'family':
            icon = faUsers
            break
        case 'season':
            icon = faSun
            break
        case 'video':
            icon = faVideo
            break
        case 'payment':
            icon = faDollarSign
            break
        case 'signout':
            icon = faSignOutAlt
            break
        default:
            icon = faAddressCard
    }

    let classes = [styles.Container]

    const { active, title } = props;


    if (active && title && (active.toLowerCase() === title.toLowerCase())) {
        classes.push(styles.ContainerActive)
    }

    classes = classes.join(' ')

    return (
        <div className={classes} onClick={props.click}>
            <div style={{textAlign: 'center'}}>
                <FontAwesomeIcon icon={icon} className={styles.Action} />
            </div>
            <div className={styles.Title}>
                {props.title}
            </div>
        </div>
    );
};

export default item;
