import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'

class SubmitButton extends Component {
    onClick = (e) => {
        if (this.props.click) {
            this.props.click(e)
        }
    }

    render() {
        return (
            <Button disabled={this.props.disabled} type={this.props.type ? this.props.type : 'button'} variant={this.props.variant ? this.props.variant : 'contained'} color={this.props.color ? this.props.color : 'primary'} onClick={this.onClick}>
                {this.props.disabled && <FontAwesomeIcon icon={faCog} spin={true} style={{ marginRight: 10 }} />} {this.props.children}
            </Button>
        )
    }
}

export default SubmitButton;
