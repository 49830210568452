import React from 'react';
import { connect } from 'react-redux';
import DropIn from 'braintree-web-drop-in-react';
import { doPost, doPut } from '../../../axios-main';
import { getToken } from '../../../components/functions';
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../components/UI/DialogAppBar/DialogAppBar";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import { PRODUCT_KIND } from "../../../constants/enums";
import FormComponent from '../../../components/UI/FormComponent/FormComponent';
import TextInput from "../../../components/UI/TextInput/TextInput";
import SubmitButton from '../../../components/UI/SubmitButton/SubmitButton';

class Payment extends FormComponent {
    state = {
        product: 1,
        products: PRODUCT_KIND,
        quantity: {
            id: 'quantity',
            label: 'Blocks to purchase (minimum of 20)',
            type: 'number',
            value: 20,
            valid: true,
            focus: true,
            validate: { blank: false, min_value: 20 },
        },
        term: 1,
        starting: false,
        buying: false,
        clientToken: null,
    };

    onClose = () => {
        this.props.close()
        this.setState({
            ...this.state,
            product: 1,
            products: PRODUCT_KIND,
            quantity: {...this.state.quantity, value: 20},
            starting: false,
            buying: false,
            clientToken: null,
            success: null,
            error: null,
        })
    }

    start = () => {
        this.setState({ ...this.state, starting: true, success: null, error: null })

        if (!this.state.quantity.valid || !Number(this.state.quantity.value)) {
            this.setState({ ...this.state, quantity: { ...this.state.quantity, message: 'Quantity is not valid.' }, error: 'Please correct the quantity.' });
        } else {
            // Get a client token for authorization from your server
            doPost(this.props.token, '/teams/purchases', {
                product_kind: this.state.product,
                quantity: this.state.quantity.value,
                years: this.state.term,
            }, (r) => {
                this.setState({ ...this.state, clientToken: r.data.client_token, purchaseItem: r.data.purchase_item, purchaseToken: r.data.purchase_token, starting: false })
            }, (r) => {
                this.setState({ ...this.state, starting: false })
                this.setError(r.response.data.message)
                console.log(r)
            });
        }
    }

    async buy() {
        // Send the nonce to your server
        const { nonce } = await this.instance.requestPaymentMethod();

        this.setState({ ...this.state, buying: true, success: null, error: null })

        doPut(this.props.token, '/teams/purchases', {
            nonce: nonce,
            purchase_id: this.state.purchaseItem.purchase_id,
            purchase_token: this.state.purchaseToken,
        }, (r) => {
            this.setState({ ...this.state, buying: false })
            this.props.close(null, false, r.data)
        }, (r) => {
            this.setState({ ...this.state, buying: false })
            console.log(r);
        });
    }

    render() {
        let form =
            <>
                {this.state.products.length > 1 && <div>
                    <SelectPanel disabled={this.state.starting} label={'Product'} value={this.state.product}
                        items={this.state.products}
                        change={(event) => this.onChangeField(event, 'relationship')} />
                </div>}
                <div>
                    <TextInput disabled={this.state.starting} info={this.state.quantity}
                        change={(event, info) => this.onChange(event, info)} />
                </div>
                <div>
                    <SelectPanel disabled={this.state.starting} label={'Years to keep storage active'} value={this.state.term}
                        items={[{ id: 1, title: 1 }, { id: 2, title: 2 }, { id: 3, title: 3 }]}
                        change={(event) => this.onChangeField(event, 'term')} />
                </div>
                <div style={{ paddingTop: 10 }}>
                    <SubmitButton disabled={this.state.starting} click={this.start}> Continue </SubmitButton>
                </div>
            </>

        if (this.state.clientToken) {
            const cost = (Number(PRODUCT_KIND.find(k => k.id === this.state.product)?.cost) * Number(this.state.quantity.value) * Number(this.state.term)).toFixed(2)
            form =
                <>
                    <div style={{ paddingTop: 15, display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem', fontWeight: 'bold' }}>
                        {this.state.quantity.value} Storage Blocks for {this.state.term} Years will cost ${cost}
                    </div>
                    <div style={{ minHeight: 391 }}>
                        <DropIn
                            options={{
                                authorization: this.state.clientToken,
                                dataCollectorOptions: { kount: true },
                                card: {
                                    cardholderName: true,
                                },
                            }}
                            onInstance={(instance) => (this.instance = instance)}
                        />
                    </div>
                    <div style={{ paddingTop: 10 }}>
                        <SubmitButton disabled={this.state.buying} click={this.buy.bind(this)}> Purchase </SubmitButton>
                    </div>
                </>
        }



        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Purchase Blocks of Storage" close={this.onClose} />
                <div style={{ padding: 10 }}>
                    {this.getAlert()}
                    {form}
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
    };
};

export default connect(mapStateToProps)(Payment);
