import React, { Component } from 'react';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Update from "../Update/Update";
import DialogAppBar from '../../../components/UI/DialogAppBar/DialogAppBar';

class edit extends Component {
    state = {
        display: 'summary',
        email: null
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            }
        }
    }

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onUpdate = (event, email) => {
        this.setState({ ...this.state, email: email });
        this.props.onClose(email);
    };

    onDisplay = (event, display) => {
        this.setState({ ...this.state, display: display });
    };

    handleClose = (event) => {
        this.setState({ ...this.state, display: 'summary' });
        if (this.state.email) {
            this.props.onClose(event, this.state.email);
        } else {
            this.props.onClose(event, this.props.email);
        }
    };

    render() {
        let content = <Update email={this.props.email} change={(event, email) => this.onUpdate(event, email)} onClose={this.handleClose} />;

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.handleClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={'Update an Email'} close={this.handleClose} />
                <DialogContent id="player-content">
                    {content}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(edit));
