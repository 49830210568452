import React, {Component} from 'react';

import './HeightInput.css';
import {connect} from "react-redux";
import SelectPanel from "../SelectPanel/SelectPanel";

class HeightInput extends Component {
    state = {
        feet: 0,
        inches: 0,
        foot_values: [
            {id: 0, title: 0},
            {id: 1, title: 1},
            {id: 2, title: 2},
            {id: 3, title: 3},
            {id: 4, title: 4},
            {id: 5, title: 5},
            {id: 6, title: 6},
            {id: 7, title: 7},
            {id: 8, title: 8},
            {id: 9, title: 9},
        ],
        inch_values: [
            {id: 0, title: 0},
            {id: 1, title: 1},
            {id: 2, title: 2},
            {id: 3, title: 3},
            {id: 4, title: 4},
            {id: 5, title: 5},
            {id: 6, title: 6},
            {id: 7, title: 7},
            {id: 8, title: 8},
            {id: 9, title: 9},
            {id: 10, title: 10},
            {id: 11, title: 11},
        ]
    };

    componentDidMount() {
        if (this.props.value) {
            this.setValue(this.props.value);
        }
    }

    setValue = (value) => {
        if (value && Number(value) > 0) {
            const f = Math.floor(Number(value) / 12);
            const i = Number(value) - (f * 12);
            this.setState({...this.state, feet: f, inches: i});
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            this.setValue(this.props.value);
        }
    }

    onChange = (event, field) => {
        this.setState({...this.state, [field]: event.target.value});
        setTimeout(() => {
            if (this.state.feet || this.state.inches) {
                let f = 0;
                if (this.state.feet) {
                    f = Number(this.state.feet) * 12;
                }

                let i = 0;
                if (this.state.inches) {
                    i = Number(this.state.inches);
                }

                this.props.change(event, Number(f + i));
            } else {
                this.props.change(event, 0);
            }
        }, 0);
    };

    render() {
        return (
            <div className={'HeightInput'}>
                <div style={{width: '100%', marginRight: 5}}>
                    <SelectPanel disabled={this.props.disabled} label={'Height (ft)'} value={this.state.feet} items={this.state.foot_values}
                                 change={(event) => this.onChange(event, 'feet')}/>
                </div>

                <div style={{width: '100%'}}>
                    <SelectPanel disabled={this.props.disabled} label={'Height (in)'} value={this.state.inches} items={this.state.inch_values}
                                 change={(event) => this.onChange(event, 'inches')}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeightInput);
