import React, {Component} from 'react';

import AppBar from "@material-ui/core/AppBar";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from '@material-ui/icons/Close';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DialogSideNavItem from "../../../components/UI/DialogSideNavItem/DialogSideNavItem";
import Update from "../Update/Update";
import Image from "../../Squad/Image/Image";

class edit extends Component {
    state = {
        display: 'summary',
        squad: null
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({...this.state, 'season_id': this.props.season.id});
            }
        }
    }

    onChange = (event, info) => {
        this.setState({...this.state, [info.id]: info});
    };

    onUpdate = (event, squad) => {
        this.setState({...this.state, squad: squad});
        this.props.onClose(squad);
    };

    onDisplay = (event, display) => {
        this.setState({...this.state, display: display});
    };

    handleClose = (event) => {
        this.setState({...this.state, display: 'summary'});
        if (this.state.squad) {
            this.props.onClose(event, this.state.squad);
        } else {
            this.props.onClose(event, this.props.squad);
        }
    };

    render() {
        let content = <Update squad={this.props.squad} change={(event, squad) => this.onUpdate(event, squad)}/>;
        if (this.state.display === 'image') {
            content = <Image squad={this.props.squad}/>;
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} open={this.props.open} onClose={(event) => this.handleClose(event)} disableBackdropClick>
                <AppBar>
                    <Toolbar>
                        <CloseIcon close={(event) => this.handleClose(event)} aria-label="close" />
                        <Typography variant="h6">
                            {this.props.squad.first_name} {this.props.squad.last_name}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div style={{paddingTop: 64}}>
                    <div style={{display: 'flex'}}>
                        <div style={{flexBasis: '200px', height: 'calc(100vh - 56px)', padding: '0', background: '#8e8e8e'}}>
                            <DialogSideNavItem active={this.state.display === 'summary'} onClick={(event) => {
                                this.onDisplay(event, 'summary')
                            }}>Summary</DialogSideNavItem>
                            <DialogSideNavItem active={this.state.display === 'image'} onClick={(event) => {
                                this.onDisplay(event, 'image')
                            }}>Image</DialogSideNavItem>
                        </div>
                        <div style={{flexGrow: '1', padding: '0 10px'}}>
                            {content}
                        </div>
                    </div>
                </div>
            </Dialog>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(edit));
