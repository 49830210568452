import React from 'react';

const BlockUsage = ( props ) => {
    const size = props.size ? Number(props.size) : 0;
    const factor = props.factor ? Number(props.factor) : 100;

    let value = 0;

    value = (Math.round(size / (1000 * 1000 * 1000) * factor)) / factor;

    return (
        <>
            {size > 0 && <>{value} Blocks</>}
        </>
    );
};

export default BlockUsage;
