import React, { Component } from 'react';
import ImageComponent from "../../components/UI/ImageComponent/index";

class InviteFormInfo extends Component {
    render() {
        const { invite } = this.props.info
        const { 
            email_address, 
            team_title, 
            season_title, 
            team_theme, 
            team_image_url, 
            participant_first_name, 
            participant_last_name, 
            participant_image_url, 
        } = invite ? invite : {}

        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 25 }}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}></div>
                        <div >
                            <ImageComponent size={100} first={participant_first_name} last={participant_last_name} image_url={participant_image_url} theme={team_theme} />
                        </div>
                    </div>
                    <div style={{ flex: 1, textAlign: "left", padding: 10 }}>
                        {team_title && <>
                            <div style={{ color: 'grey', fontStyle: 'italic', fontSize: '0.8rem' }}>From:</div>
                            <div style={{ padding: 5, fontWeight: 'bold', fontSize: '1.2rem' }}>{participant_first_name} {participant_last_name}</div>
                        </>}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 25 }}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}></div>
                        <div >
                            <ImageComponent size={100} first={team_title} image_url={team_image_url} theme={team_theme} />
                        </div>
                    </div>
                    <div style={{ flex: 1, textAlign: "left", padding: 10 }}>
                        {team_title && <>
                            <div style={{ color: 'grey', fontStyle: 'italic', fontSize: '0.8rem' }}>Team:</div>
                            <div style={{ padding: 5, fontWeight: 'bold', fontSize: '1.2rem' }}>{team_title}</div>
                        </>}
                        {season_title && <>
                            <div style={{ color: 'grey', fontStyle: 'italic', fontSize: '0.8rem' }}>Season:</div>
                            <div style={{ padding: 5, fontWeight: 'bold', fontSize: '1.2rem' }}>{season_title}</div>
                        </>}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 25 }}>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}></div>
                    </div>
                    {email_address && <div style={{ flex: 1, textAlign: "left", padding: 10 }}>
                        <div style={{ color: 'grey', fontStyle: 'italic', fontSize: '0.8rem' }}>To:</div>
                        <div style={{ padding: 5, fontWeight: 'bold', fontSize: '1.2rem' }}>{email_address}</div>
                    </div>}
                </div>
            </div>
        );
    }
}

export default InviteFormInfo;
