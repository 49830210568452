import * as actionTypes from './actionTypes';

import {
    SPORT,
    GRADE_LEVEL,
    PARTICIPANT_RELATIONSHIP,
    VIDEO_KIND,
} from "../../constants/enums";


export const loadVideoFileStatus = (enums) => {
    return {
        type: actionTypes.LOAD_VIDEO_FILE_STATUS,
        video_file_status: enums
    };
};

export const loadGradeLevelSuccess = (enums) => {
    return {
        type: actionTypes.LOAD_GRADE_LEVEL_SUCCESS,
        grade_levels: enums
    };
};

export const loadVideoKindSuccess = (enums) => {
    return {
        type: actionTypes.LOAD_VIDEO_KIND_SUCCESS,
        video_kinds: enums
    };
};

export const loadGameKindSuccess = (enums) => {
    return {
        type: actionTypes.LOAD_GAME_KIND_SUCCESS,
        video_kinds: enums
    };
};

export const loadEventKindSuccess = (enums) => {
    return {
        type: actionTypes.LOAD_EVENT_KIND_SUCCESS,
        video_kinds: enums
    };
};

export const loadSportSuccess = (enums) => {
    return {
        type: actionTypes.LOAD_SPORT_SUCCESS,
        sports: enums
    };
};

export const loadRelationships = (enums) => {
    return {
        type: actionTypes.LOAD_RELATIONSHIP_SUCCESS,
        relationships: enums
    };
};

export const loadTeamMemberSecurityRights = (enums) => {
    return {
        type: actionTypes.LOAD_TEAM_MEMBER_SECURITY_RIGHTS_SUCCESS,
        team_member_security_rights: enums
    };
};

export const loadSquadSecurityRights = (enums) => {
    return {
        type: actionTypes.LOAD_SQUAD_SECURITY_RIGHTS_SUCCESS,
        squad_security_rights: enums
    };
};

export const loadEnums = () => {
    return dispatch => {
        dispatch(loadSportSuccess(SPORT));
        dispatch(loadGradeLevelSuccess(GRADE_LEVEL));
        dispatch(loadRelationships(PARTICIPANT_RELATIONSHIP));
        dispatch(loadVideoKindSuccess(VIDEO_KIND));

        // axios.get('/enums/EventKind')
        //     .then(res => {
        //         dispatch(loadEventKindSuccess(res.data.items));
        //     })
        //     .catch(err => {
        //         console.log('ERROR', err);
        //     });

        // axios.get('/enums/GameKind')
        //     .then(res => {
        //         dispatch(loadGameKindSuccess(res.data.items));
        //     })
        //     .catch(err => {
        //         console.log('ERROR', err);
        //     });

        // axios.get('/enums/VideoFileStatus')
        //     .then(res => {
        //         dispatch(loadVideoFileStatus(res.data.items));
        //     })
        //     .catch(err => {
        //         console.log('ERROR', err);
        //     });

        // axios.get('/enums/TeamMemberSecurityRight')
        //     .then(res => {
        //         dispatch(loadTeamMemberSecurityRights(res.data.items));
        //     })
        //     .catch(err => {
        //         console.log('ERROR', err);
        //     });

        // axios.get('/enums/SquadSecurityRight')
        //     .then(res => {
        //         dispatch(loadSquadSecurityRights(res.data.items));
        //     })
        //     .catch(err => {
        //         console.log('ERROR', err);
        //     });
    };
};
