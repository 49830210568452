import React from 'react';
import {connect} from 'react-redux';
import Form from '../../components/UI/Form/Form';
import TextInput from '../../components/UI/TextInput/TextInput';
import * as actions from '../../store/actions/index';
import {withRouter} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {authReset} from "../../store/actions/auth";
import FormComponent from '../../components/UI/FormComponent/FormComponent';
import SubmitButton from '../../components/UI/SubmitButton/SubmitButton';

class SignInForm extends FormComponent {
    state = {
        username: {
            id: 'username',
            label: 'Username or Email Address',
            value: '',
            valid: false,
            focus: true,
            validate: {blank: false, min_length: 1, max_length: 100}
        },
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: {blank: false, min_length: 6, max_length: 100}
        },
        remember_me: false,
        state: 0,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        this.props.onReset();
    }

    submitHandler = (event) => {
        event.preventDefault()

        this.setState({
            ...this.state,
            username: {...this.state.username, message: ''},
            code: {...this.state.code, message: ''}
        })

        if (!this.state.username.valid) {
            this.setState({...this.state, username: {...this.state.username, message: 'Required'}});
        } else if ((this.state.state === 0) && !this.state.password.valid) {
            this.setState({...this.state, password: {...this.state.password, message: 'Required'}});
        } else {
            if (this.state.state === 0) {
                this.props.onSignIn(this.state.username.value, this.state.password.value, this.state.remember_me);
            } else if (this.state.state === 1) {
                this.props.onForgotPassword(this.state.username.value);
            } else if (this.state.state === 2) {
                this.props.onQuickSignIn(this.state.username.value, this.state.remember_me);
            } else {
                console.log('That state is not handled', this.state.state);
            }
        }
    }

    signIn = () => {
        this.setState({...this.state, state: 0});
        this.props.onReset();
    }

    forgotPassword = () => {
        this.setState({...this.state, state: 1});
        this.props.onReset();
    }

    quickSignIn = () => {
        this.setState({...this.state, state: 2});
        this.props.onReset();
    }

    onRememberMe = name => event => {
        this.setState({...this.state, [name]: event.target.checked});
    };

    render() {
        return (
            <div>
                {(this.state.state === 1) && <h4>If you provide your email address or your username we will look you up and send an email so you can reset your password.</h4>}
                {(this.state.state === 2) && <h4>If you provide your email address or your username we will look you up and send an email with a link that can be used to sign in without a password.</h4>}
                <Form onSubmit={this.submitHandler}>
                    <TextInput disabled={this.props.loading} info={this.state.username}
                               change={(event, info) => this.onChange(event, info)}/>
                    {(this.state.state === 0) && <TextInput disabled={this.props.loading} info={this.state.password}
                               change={(event, info) => this.onChange(event, info)}/>}
                    {((this.state.state === 0) || this.state.state === 2) && <FormGroup row>
                        <FormControlLabel
                            label="Remember Me"
                            control={
                                <Checkbox
                                    disabled={this.props.loading}
                                    checked={this.state.remember_me}
                                    onChange={this.onRememberMe('remember_me')}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    color='primary'
                                />
                            }
                        />
                    </FormGroup>}

                    <SubmitButton disabled={this.props.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                </Form>

                <div style={{paddingTop: 25}}>
                    {(this.state.state !== 0) && <Button onClick={this.signIn}> Sign In </Button>}
                    {(this.state.state === 0) && <Button onClick={this.forgotPassword}> Forgot Password? </Button>}
                    {(this.state.state === 0) && <Button onClick={this.quickSignIn}> Quick Sign In </Button>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
        error: state.auth.error,
        success: state.auth.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onReset: () => dispatch(authReset()),
        onSignIn: (email, password, remember_me) => dispatch(actions.signIn(email, password, remember_me)),
        onQuickSignIn: (email, remember_me) => dispatch(actions.quickSignIn(email, remember_me)),
        onForgotPassword: (email) => dispatch(actions.sendForgotPassword(email)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInForm));
