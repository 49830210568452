import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    season: null,
    loading: false
};

const selectSeasonReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        season: action.season,
    });
};

const loadingSeasonsReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading: true,
        success: null,
        error: null,
    });
};

const loadSeasonsReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        seasons: action.seasons,
        loading: false,
        success: null,
        error: null,
    });
};

const loadSeasonsErrorReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading: false,
        success: null,
        error: 'Unable to load seasons.',
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_SEASON:
            return selectSeasonReducer(state, action);
        case actionTypes.LOADING_SEASONS:
            return loadingSeasonsReducer(state, action);
        case actionTypes.LOAD_SEASONS:
            return loadSeasonsReducer(state, action);
        case actionTypes.LOAD_SEASONS_ERROR:
            return loadSeasonsErrorReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
