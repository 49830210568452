import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import { doPut } from "../../../../axios-main";
import { withRouter } from "react-router-dom";
import { empty, pushPath, getToken } from "../../../../components/functions";
import { state } from "../constants";
import "./Detail.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogAppBar from '../../../../components/UI/DialogAppBar/DialogAppBar';

class detail extends Component {
    state = state;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item !== this.props.item) {
            this.setLoading(false);

            const { item } = this.props;

            this.setState({
                ...this.state,
                title: { ...this.state.title, value: empty(item.title) },
                city: { ...this.state.city, value: empty(item.city) },
                country: { ...this.state.country, value: empty(item.country) },
                line1: { ...this.state.line1, value: empty(item.line1) },
                line2: { ...this.state.line2, value: empty(item.line2) },
                map_url: { ...this.state.map_url, value: empty(item.map_url) },
                notes: { ...this.state.notes, value: empty(item.notes) },
                postal_code: { ...this.state.postal_code, value: empty(item.postal_code) },
                region: { ...this.state.region, value: empty(item.region) },
            });
        }
    }

    onChange = (e, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeColor = (value, field) => {
        this.setState({ ...this.state, [field]: value });
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    submitHandler = (event) => {
        event.preventDefault();

        const put = {
            title: this.state.title.value,
            city: this.state.city.value,
            country: this.state.country.value,
            line1: this.state.line1.value,
            line2: this.state.line2.value,
            map_url: this.state.map_url.value,
            notes: this.state.notes.value,
            postal_code: this.state.postal_code.value,
            region: this.state.region.value,
        };

        this.setLoading(true);

        doPut(this.props.token, `/locations/${this.props.match.params.location_id}`, put, () => {
            this.setLoading(false);
            pushPath(this.props, `manage/locations`);
        }, (r) => {
            this.setLoading(false);
            console.log('ERROR', r.response.data.message);
        });
    };

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                <TextInput info={this.state.title}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.city}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.country}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.line1}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.line2}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.postal_code}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.region}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.map_url}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.notes}
                    change={(event, info) => this.onChange(event, info)} />
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} scroll={'paper'} open={this.props.open} onClose={this.props.close} disableBackdropClick>
                <DialogAppBar title={'Update Location'} close={this.props.close} />
                <DialogContent id="player-content">
                    {form}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} variant="contained" color="secondary" type="button"> CLOSE </Button>
                    <Button onClick={this.submitHandler} variant="contained" color="primary" type="button"> SUBMIT </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        season: state.seasons.season,
        team: state.teams.team,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(detail));
