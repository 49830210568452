import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";

import { withRouter } from "react-router-dom";
import Success from "../../../components/UI/Alert/Success";
import Error from "../../../components/UI/Alert/Error";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import { doPost } from "../../../axios-main";
import { getColors, getToken } from "../../../components/functions";
import CheckPanel from "../../../components/UI/CheckPanel/CheckPanel";

class create extends Component {
    // todo squads are new. Add them on the email creation.
    state = {
        subject: {
            id: 'subject',
            label: 'Subject',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        body: {
            id: 'body',
            label: 'Body',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        squads: [],
        roster: [],
        members: [],
        squad_values: [],
        roster_values: [],
        member_values: [],
        step: 1,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.getRoster();
                this.getMembers();
                this.getSquads();
                clearInterval(i);
            }
        }, 0);

        this.setState({ ...this.state, step: 1 });
    }

    componentWillUnmount() {
        this.setState({ ...this.state, step: 1 });
    }

    getSquads = () => {
        // TODO GET SQUADS
        // doPost(this.props.token, '/squads/list', {}, res => this.setState({
        //     ...this.state,
        //     squads: res.data.items
        // }), r => console.log('POST ERROR', r));
    };

    getRoster = () => {
        // TODO Get ROSTER
        // doPost(this.props.token, '/participants', {}, res => this.setState({
        //     ...this.state,
        //     roster: res.data.items
        // }), r => console.log('POST ERROR', r));
    };

    getMembers = () => {
        // TODO get members
        // doGet(this.props.token, '/teams/members', {}, r => this.setState({
        //     ...this.state,
        //     members: r.data.items
        // }), r => console.log('POST ERROR', r));
    };

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id, step: 1 });
            } else if (this.props.season.id && !this.state.season_id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id, step: 1 });
            }
        }
    }

    onClose = (event) => {
        event.stopPropagation();
        this.props.close(false);
    };

    onNext = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, step: this.state.step + 1 });
    };

    onBack = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, step: this.state.step - 1 });
    };

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    selectAll = (items, checked) => {
        const values = [];

        if (checked) {
            items.forEach((r) => {
                values.push(r.id);
            });
        }

        return values;
    }

    onAllSquads = (event) => {
        this.setState({ ...this.state, squad_values: this.selectAll(this.state.squads, event.target.checked) });
    };

    onAllRoster = (event) => {
        this.setState({ ...this.state, roster_values: this.selectAll(this.state.roster, event.target.checked) });
    };

    onAllMember = (event) => {
        this.setState({ ...this.state, member_values: this.selectAll(this.state.members, event.target.checked) });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    set_success = (email) => {
        this.reset();
        this.setState({ ...this.state, 'success': email });
    };

    set_error = (email) => {
        this.reset();
        this.setState({ ...this.state, 'error': email });
    };

    reset = () => {
        this.setState({ ...this.state, 'error': null });
        this.setState({ ...this.state, 'success': null });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.store(false);
    };

    onSend = (event) => {
        event.preventDefault();

        this.store(true);
    };

    store = (submit) => {
        this.reset();

        const state = this.state;

        doPost(this.props.token, '/emails', {
            subject: state.subject.value,
            body: state.body.value,
            body_format: 1,
            season_id: this.props.season.id,
            submit: submit,
            squad_ids: state.squad_values,
            participant_ids: state.roster_values,
            team_member_ids: state.member_values,
        }, () => {
            this.set_success("Email created.");
            this.props.close(true);
        }, (r) => {
            console.log(r);
            this.set_error(r.response.data.email);
        });
    };

    getValues = (values, id, checked) => {
        const exists = values.findIndex((v) => v === id);

        if (checked) {
            if (exists < 0) {
                values.push(id);
            }
        } else {
            if (exists >= 0) {
                values.splice(exists, 1);
            }
        }

        return values;
    };

    onCheckRoster = (id, event) => {
        this.setState({
            ...this.state,
            roster_values: this.getValues(this.state.roster_values, id, event.target.checked)
        });
    };

    onCheckMember = (id, event) => {
        this.setState({
            ...this.state,
            member_values: this.getValues(this.state.member_values, id, event.target.checked)
        });
    };

    onCheckSquads = (id, event) => {
        this.setState({
            ...this.state,
            squad_values: this.getValues(this.state.squad_values, id, event.target.checked)
        });
    };

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let roster;

        if (this.state.roster && this.state.roster.length > 0) {
            roster = this.state.roster.map(row => {
                if (row.id) {
                    return (
                        <div key={row.id} style={{ padding: '0 30px' }}>
                            <CheckPanel change={(event) => this.onCheckRoster(row.id, event)}
                                label={row.first_name + " " + row.last_name}
                                checked={(this.state.roster_values.find((v) => v === row.id) > 0)} />
                        </div>
                    )
                } else {
                    console.log('EMPTY ROW', row);
                    return '';
                }
            }
            )
        }

        let squads;

        if (this.state.squads && this.state.squads.length > 0) {
            squads = this.state.squads.map(row => {
                if (row.id) {
                    return (
                        <div key={row.id} style={{ padding: '0 30px' }}>
                            <CheckPanel change={(event) => this.onCheckSquads(row.id, event)}
                                label={row.title}
                                checked={(this.state.squad_values.find((v) => v === row.id) > 0)} />
                        </div>
                    )
                } else {
                    console.log('EMPTY ROW', row);
                    return '';
                }
            }
            )
        }

        let members;
        if (this.state.members && this.state.members.length > 0) {
            members = this.state.members.map(row => {
                if (row.id) {
                    return (
                        <div key={row.id} style={{ padding: '0 30px' }}>
                            <CheckPanel change={(event) => this.onCheckMember(row.id, event)} label={row.full_name}
                                checked={(this.state.member_values.find((v) => v === row.id) > 0)} />
                        </div>
                    )
                } else {
                    console.log('EMPTY ROW', row);
                    return '';
                }
            }
            )
        }

        let form = (
            <>
                {error}
                {success}

                <Form onSubmit={this.submitHandler}>
                    {(this.state.step) === 1 && <div>
                        <h3>Step 1 of 4: Email</h3>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 2 }}>
                                <TextInput info={this.state.subject}
                                    change={(event, info) => this.onChange(event, info)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 2 }}>
                                <TextInput info={this.state.body}
                                    change={(event, info) => this.onChange(event, info)} />
                            </div>
                        </div>
                    </div>}
                    {(this.state.step) === 2 && <div>
                        <h3>Step 2 of 4: Squads</h3>
                        {squads}
                    </div>}
                    {(this.state.step) === 3 && <div>
                        <h3>Step 3 of 4: Roster</h3>
                        {roster}
                    </div>}
                    {(this.state.step) === 4 && <div>
                        <h3>Step 4 of 4: Members</h3>
                        {members}
                    </div>}

                    <Button style={{ margin: '0 2px' }} variant="contained" color="default" type="button"
                        onClick={this.onClose}> CLOSE </Button>
                    {(this.state.step) > 1 && <Button style={{ margin: '0 2px' }} variant="contained" color="secondary" type="button"
                        onClick={this.onBack}> BACK </Button>}
                    {(this.state.step) < 4 && <Button style={{ margin: '0 2px' }} variant="contained" color="secondary" type="button"
                        onClick={this.onNext}> NEXT </Button>}
                    {(this.state.step) === 4 && <Button style={{ margin: '0 2px' }} variant="contained" color="primary" type="button"
                        onClick={this.onSend}> SEND NOW </Button>}
                    {(this.state.step) === 4 && <Button style={{ margin: '0 2px' }} variant="contained" color="primary" type="submit"> SAVE FOR LATER </Button>}
                </Form>
            </>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
