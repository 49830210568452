import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Header from "../../../components/UI/Header/Header";
import { withRouter } from "react-router-dom";
import Card from '../../../components/UI/Card/Card';
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import axios from "../../../axios-main";
import { getColors, getToken } from "../../../components/functions";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";

class update extends Component {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: ' ',
            valid: false,
            focus: true,
            validate: {}
        },
        background_color: '#606060',
        text_color: '#CC0000',
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        const i = setInterval(() => {
            if (this.props.squad) {
                clearInterval(i);

                this.setState({
                    ...this.state,
                    background_color: this.props.squad.background_color,
                    text_color: this.props.squad.text_color,
                    title: { ...this.state.title, value: this.props.squad.title },
                });
            }
        }, 0);
    }

    onChangeColor = (value, field) => {
        this.setState({ ...this.state, [field]: value });
    };

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({
                    ...this.state,
                    season_id: this.props.season.id,
                });
            }
        }
    }

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeField = (event, field) => {
        this.setState({ ...this.state, [field]: event.target.value });
    };

    submitHandler = (event) => {
        event.preventDefault();

        const headers = {
            'Content-Type': 'application/json',
            'x-shsid': this.props.token
        };

        const state = this.state;

        const put = {
            title: state.title.value,
            text_color: state.text_color,
            background_color: state.background_color,
        };

        axios.put(`/squads/${this.props.squad.id}/update-appearance`, put, { headers: headers })
            .then((res) => {
                this.props.change(res.data);
            })
            .catch(err => {
                console.log('ERROR', err.response.data.message);
            });
    };

    render() {
        let form = (
            <Card>
                <Header> UPDATE A SQUAD </Header>

                <Form onSubmit={this.submitHandler}>
                    <TextInput info={this.state.title}
                        change={(event, info) => this.onChange(event, info)} />

                    <ColorPicker label="Primary Color" value={this.state.background_color} field="background_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />
                    <ColorPicker label="Secondary Color" value={this.state.text_color} field="text_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />

                    <Button variant="contained" color="primary" type="submit"> SUBMIT </Button>
                </Form>
            </Card>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(update));
