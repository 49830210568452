import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {authReset} from "../../../store/actions/auth";
import { getToken, canManageTeam } from "../../../components/functions";
import Cropper from "../../../components/UI/Cropper/Cropper";

class Image extends Component {
    state = {
        file: null,
    };

    onComplete = res => {
        this.props.onTeamImageUpdate(res);
        this.props.onTeamsImageUpdate(this.props.team, res);
    };

    render() {
        let cropper;

        let title = this.props.team_member?.alternate_team_title ? this.props.team_member?.alternate_team_title : this.props.team?.team_title
        let theme = this.props.team_member?.alternate_team_theme ? this.props.team_member?.alternate_team_theme : this.props.team?.theme

        if (this.props.team_member) {
            cropper = <Cropper can_update={this.props.manage_team} first_name={title} theme={theme} url="/teams/images" onComplete={result => {
                this.onComplete(result)
            }}/>
        }

        return (
            <div>
                {cropper}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        manage_team: canManageTeam(state),
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        isAuthenticated: state.auth.user !== null,
        isPasswordChanged: state.auth.isPasswordChanged,
        user: state.auth.user,
        team: state.teams.team,
        team_member: state.team_members.team_member,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onChangeSummary: (user, first_name, last_name, nick_name, phone_number) => dispatch(actions.changeSummary(user, first_name, last_name, nick_name, phone_number)),
        onTeamImageUpdate: (res) => dispatch(actions.updateTeamImage(res)),
        onTeamsImageUpdate: (team, res) => dispatch(actions.updateTeamsImage(team, res)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
