import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Height from "../../../components/UI/Height/Height";
import Weight from "../../../components/UI/Weight/Weight";
import ImageComponent from "../../../components/UI/ImageComponent/index";
import { PARTICIPANT_RELATIONSHIP, GRADE_LEVEL } from '../../../constants/enums';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogAppBar from '../../../components/UI/DialogAppBar/DialogAppBar';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";

class row extends Component {
    state = {
        open: false,
        delete_members: false,
    };

    open = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ open: true })
    }

    close = (e) => {
        if (e) {
            e.stopPropagation();
        }
        this.setState({ open: false })
    }

    confirm = (e) => {
        this.props.remove(e, this.props.model, this.state.delete_members)
    }

    getGradeLevel(level) {
        let g = GRADE_LEVEL.find(g => Number(g.id) === Number(level));
        if (!g) {
            return '';
        } else {
            return g.title;
        }
    }

    onDeleteMembers = name => event => {
        this.setState({...this.state, [name]: event.target.checked});
    };

    getRelationship(relationship) {
        let g = PARTICIPANT_RELATIONSHIP.find(r => Number(r.id) === Number(relationship));
        if (!g) {
            return '';
        } else {
            return g.title;
        }
    }

    render() {
        const theme = this.props.team_member?.alternate_team_theme ? this.props.team_member.alternate_team_theme : this.props.team?.theme

        const row = this.props.model;

        return (
            <>
                <TableRow key={`${row.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, row)}>
                    <TableCell align="left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ position: 'relative', paddingRight: 15 }}>
                                {<Badge badgeContent={row.total_invites &&
                                    <Tooltip title={`${row.total_invites} ${row.total_invites > 1 ? 'invites' : 'invite'} pending`}>
                                        <span>{row.total_invites}</span>
                                    </Tooltip>
                                } color="primary">
                                    <ImageComponent theme={theme} model={row} size={50} image_url={row.image_url} />
                                </Badge>}
                            </div>
                            <div>
                                {row.first_name} {row.nick_name && <span style={{ fontStyle: "italic" }}>"{row.nick_name}"</span>} {row.last_name}
                            </div>
                        </div>
                    </TableCell>
                    <TableCell align="left">{this.getGradeLevel(row.grade_level)}</TableCell>
                    <TableCell align="left"><Height value={row.height_in_inches} /></TableCell>
                    <TableCell align="left"><Weight value={row.weight_in_ounces} /></TableCell>
                    <TableCell align="left">{row.jersey_number}</TableCell>
                    <TableCell align="left">
                        {
                            row?.relationship_summaries?.map(s => {
                                return (
                                    <div key={s.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5 }}>
                                        <div style={{ marginRight: 5 }}>
                                            <ImageComponent theme={theme} model={s} size={30} image_url={s.image_url} />
                                        </div>
                                        <div style={{ flex: 1, fontSize: '0.7rem' }}>
                                            {s.first_name} {s.last_name}
                                            <div style={{ fontStyle: 'italic', fontSize: '0.6rem' }}>{s.relationship_other ? s.relationship_other : this.getRelationship(s.relationship)}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </TableCell>
                    {this.props.manage_team &&
                        <TableCell align="right">
                            <FontAwesomeIcon icon={faTrash} className={'TrashIconButton'} onClick={(e) => this.open(e)} aria-label="remove" />
                        </TableCell>
                    }
                </TableRow>
                <Dialog fullWidth={true} maxWidth={'sm'} scroll={'paper'} onClose={this.close} open={this.state.open} disableBackdropClick>
                    <DialogAppBar title={'Remove Player?'} close={this.close} />
                    <DialogContent>
                        <h3>Remove {row.first_name} {row.nick_name && <span style={{ fontStyle: "italic" }}>"{row.nick_name}"</span>} {row.last_name} from the roster?</h3>

                        <FormGroup row>
                            <FormControlLabel
                                label="Also remove members"
                                control={
                                    <Checkbox
                                        disabled={this.props.loading}
                                        checked={this.state.delete_members}
                                        onChange={this.onDeleteMembers('delete_members')}
                                        inputProps={{
                                            'aria-label': 'primary checkbox',
                                        }}
                                        color='primary'
                                    />
                                }
                            />
                        </FormGroup>

                        <Button size="small" variant="contained" color="primary" onClick={this.confirm}> YES </Button>
                    </DialogContent>
                </Dialog >
            </>
        )
    }
}

export default row;
