import * as actionTypes from './actionTypes';
import {doGet} from "../../axios-main";

export const fetchHomeSuccess = (teams) => {
    return {
        type: actionTypes.FETCH_HOME_SUCCESS,
        teams: teams
    };
};

export const fetchHomeFail = (error) => {
    return {
        type: actionTypes.FETCH_HOME_FAIL,
        error: error
    };
};

export const fetchHomeStart = () => {
    return {
        type: actionTypes.FETCH_HOME_START
    };
};

export const fetchHome = (sessionId) => {
    return dispatch => {
        dispatch(fetchHomeStart());

        doGet(sessionId, '/members/teams', (r) => {
            dispatch(fetchHomeSuccess(r.data));
        }, (r) => {
            dispatch(fetchHomeFail(r));
        });
    };
};

