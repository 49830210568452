import React from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import Spinner from '../../components/UI/Spinner/Spinner';
import { authSuccess } from "../../store/actions/auth";
import Form from "../../components/UI/Form/Form";
import FormContainer from "./FormContainer";
import TextInput from "../../components/UI/TextInput/TextInput";
import { getToken } from '../../components/functions';
import { doPut } from '../../axios-main';

class Summary extends FormContainer {
    state = {
        first_name: {
            id: 'first_name',
            label: 'First Name',
            value: this.props.user.member.first_name ? this.props.user.member.first_name : '',
            valid: true,
            validate: { max_length: 100 }
        },
        last_name: {
            id: 'last_name',
            label: 'Last Name',
            value: this.props.user.member.last_name ? this.props.user.member.last_name : '',
            valid: true,
            validate: { max_length: 100 }
        },
        user_name: {
            id: 'user_name',
            label: 'User Name',
            value: this.props.user.member.user_name ? this.props.user.member.user_name : '',
            valid: true,
            focus: true,
            validate: { max_length: 100 }
        },
        email_address: {
            id: 'email_address',
            label: 'Email Address',
            value: this.props.user.member.email_address ? this.props.user.member.email_address : '',
            valid: true,
            validate: { max_length: 100 }
        },
    }

    submitHandler = (event) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            success: null,
            error: null,
            loading: true,
        });

        if (!this.state.first_name.valid) {
            this.setState({ ...this.state, first_name: { ...this.state.first_name, message: 'Required' } });
        } else if (!this.state.last_name.valid) {
            this.setState({ ...this.state, last_name: { ...this.state.last_name, message: 'Required' } });
        } else if (!this.state.user_name.valid) {
            this.setState({ ...this.state, user_name: { ...this.state.user_name, message: 'Required' } });
        } else if (!this.state.email_address.valid) {
            this.setState({ ...this.state, email_address: { ...this.state.email_address, message: 'Required' } });
        } else {
            doPut(this.props.token, '/members', {
                user_name: this.state.user_name.value,
                email_address: this.state.email_address.value,
                first_name: this.state.first_name.value,
                last_name: this.state.last_name.value
            }, (r) => {
                this.setState({
                    ...this.state,
                    success: 'Information has been updated.',
                    error: null,
                    loading: false,
                });
                this.props.onUpdate({...this.props.user, member: {...this.props.user.member, ...r.data}});
            }, (r) => {
                let m = 'Unable to update your information.'

                const {response} = r;
                const {data} = response ? response : {};
                const {message} = data ? data : {};

                if (message) {
                    m = message;
                }

                this.setState({
                    ...this.state,
                    success: null,
                    error: m,
                    loading: false,
                });
            });
        }
    }

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                <TextInput info={this.state.user_name} change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.first_name} change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.last_name} change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.email_address} change={(event, info) => this.onChange(event, info)} />

                <Button variant="contained" color="primary" onClick={this.submitHandler}> SUBMIT </Button>
            </Form>
        );

        if (this.state.loading) {
            form = <Spinner />
        }

        let alert = this.getAlert();

        return (
            <div>
                {alert}
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        user: state.auth.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdate: (user) => dispatch(authSuccess(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
