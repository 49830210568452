import React from 'react';

import styles from './SideNavItem.module.css';
import {NavLink} from "react-router-dom";

const item = (props) => (
    <div className={styles.Container}>
        <NavLink
            to={`${props.url}`}
            exact={props.exact}
            activeClassName={styles.active}
            onClick={props.onClick}>
            <div>{props.children}</div>
        </NavLink>
    </div>
)

export default item;
