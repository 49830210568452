import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Header from "../../../components/UI/Header/Header";
import { withRouter } from "react-router-dom";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import axios from "../../../axios-main";
import { getColors, getToken } from "../../../components/functions";

class update extends Component {
    // todo squads are new  Handle the squads
    state = {
        subject: {
            id: 'subject',
            label: 'Subject',
            value: this.props.event.subject,
            valid: false,
            focus: true,
            validate: {}
        },
        body: {
            id: 'body',
            label: 'Body',
            value: this.props.event.body,
            valid: false,
            validate: {}
        },
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            }
        }
    }

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeField = (event, field) => {
        this.setState({ ...this.state, [field]: event.target.value });
    };

    onSend = (event) => {
        event.preventDefault();

        this.store(true);
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.store(false);
    };

    store = (submit) => {
        const headers = {
            'Content-Type': 'application/json',
            'x-shsid': this.props.token
        };

        const state = this.state;

        const put = {
            subject: state.subject.value,
            body: state.body.value,
            body_format: 1,
            submit: submit,
            participant_ids: state.roster_values,
            team_member_ids: state.member_values,
        };

        axios.put(`/events/${this.props.event.id}`, put, { headers: headers })
            .then((res) => {
                this.props.change(res.data);
            })
            .catch(err => {
                console.log('ERROR', err.response.data.message);
            });
    };

    render() {
        let form = (
            <>
                <Header> UPDATE AN EVENT </Header>
                <Form onSubmit={this.submitHandler}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 2 }}>
                                <TextInput info={this.state.subject}
                                    change={(event, info) => this.onChange(event, info)} />
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>
                                <TextInput info={this.state.body}
                                    change={(event, info) => this.onChange(event, info)} />
                            </div>
                        </div>
                    </div>

                    <Button style={{ margin: '0 2px' }} variant="contained" color="default" type="button"
                        onClick={this.props.onClose}> CLOSE </Button>
                    <Button style={{ margin: '0 2px' }} variant="contained" color="primary" type="submit"> UPDATE </Button>
                </Form>
            </>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(update));
