import React from 'react';

import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { getToken, teamPath, getLocalDate } from "../../../components/functions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import DateInput from "../../../components/UI/DateInput/DateInput";
import { doPost } from "../../../axios-main";
import { withRouter } from "react-router-dom";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import { fetchHomeSuccess } from "../../../store/actions/home";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from '../../../components/UI/FormComponent/FormComponent';
import SubmitButton from '../../../components/UI/SubmitButton/SubmitButton';

class create extends FormComponent {
    state = {
        loading: false,
        team_title: {
            id: 'team_title',
            label: 'Team Title',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 50 }
        },
        season_title: {
            id: 'season_title',
            label: 'Season Title',
            value: '',
            valid: false,
            focus: false,
            validate: { blank: false, max_length: 25 }
        },
        mobile_number: {
            id: 'mobile_number',
            label: 'Mobile Number',
            value: '',
            valid: false,
            focus: false,
            validate: { max_length: 25 }
        },
        primary_color: '#606060',
        secondary_color: '#CC0000',
        hide_contact_info: false,
        season_start_date: new Date(),
        sport: 1,
    };

    close = () => {
        this.setState({
            ...this.state,
            loading: false,
            primary_color: '#606060',
            secondary_color: '#CC0000',
            hide_contact_info: false,
            season_start_date: new Date(),
            sport: 1,
            team_title: { ...this.state.team_title, value: '' },
            season_title: { ...this.state.season_title, value: '' },
            mobile_number: { ...this.state.mobile_number, value: '' },
        });

        this.props.closeTeamCreate()
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.team_title.value.trim()) {
            this.setState({
                ...this.state,
                team_title: { ...this.state.team_title, message: 'Required' },
            });
        } else if (!this.state.season_title.value.trim()) {
            this.setState({
                ...this.state,
                season_title: { ...this.state.season_title, message: 'Required' },
            });
        } else {
            this.setLoading(true);

            doPost(this.props.token, '/teams', {
                hide_contact_info: this.state.hide_contact_info,
                season_start_date: getLocalDate(this.state.season_start_date),
                season_title: this.state.season_title.value,
                sport: this.state.sport,
                team_title: this.state.team_title.value,
                mobile_number: this.state.mobile_number.value,
                theme: {
                    primary_color: this.state.primary_color,
                    secondary_color: this.state.secondary_color,
                },
            }, (c) => {
                const { data } = c;
                const { team, member_team } = data ? data : {};
                const { teams } = this.props ? this.props : {};

                teams.unshift(member_team);

                this.props.onSetTeams(teams);

                this.props.history.push(`/${team.team_id}/0/${teamPath(this.props.user)}`);

                this.setLoading(false);

                this.close();
            }, (r) => {
                this.setLoading(false);
                console.log('ERROR', r);
            });
        }
    };

    render() {
        let form = (
            <div style={{ padding: 10, textAlign: 'center' }}>
                <Form onSubmit={this.submitHandler}>
                    <TextInput disabled={this.state.loading} info={this.state.team_title}
                        change={(event, info) => this.onChange(event, info)} />
                    <TextInput disabled={this.state.loading} info={this.state.season_title}
                        change={(event, info) => this.onChange(event, info)} />

                    <SelectPanel disabled={this.state.loading} label={'Sport'} value={this.state.sport} items={this.props.sports}
                        change={(event) => this.onChangeField(event, 'sport')} />

                    <DateInput disabled={this.state.loading} id="season_start_date" label="Season Start Date" value={this.state.season_start_date}
                        onChange={(event) => this.onChangeDate(event, 'season_start_date')} />

                    <ColorPicker disabled={this.state.loading} label="Primary Color" value={this.state.primary_color} field="primary_color"
                        position={'bottom'} change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />
                    <ColorPicker disabled={this.state.loading} label="Secondary Color" value={this.state.secondary_color} field="secondary_color"
                        position={'bottom'} change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />

                    <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                </Form>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.close} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Set Up a Team" close={this.close} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    const { auth } = state;
    const { user } = auth ? auth : {};
    const { teams } = user ? user : {};

    return {
        user: user,
        teams: teams,
        token: getToken(state),
        isAuthenticated: state.auth.user ? state.auth.user.token !== null : null,
        authRedirectPath: state.auth.authRedirectPath,
        sports: state.enums.sports ? state.enums.sports : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetTeams: (teams) => dispatch(actions.setTeams(teams)),
        onFetchHome: (items) => dispatch(fetchHomeSuccess(items)),
        closeTeamCreate: () => dispatch(actions.closeTeamCreateAction()),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
