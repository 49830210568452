import axios from 'axios';
import {getApi, getSid} from "./config/config";

const sid = getSid();

const instance = axios.create({baseURL: getApi()});

export default instance;

const getHeaders = (token) => {
    return {headers: {'Content-Type': 'application/json', [sid]: token}}
};

export const doPost = (token, path, data, success, error) => {
    instance.post(path, data, getHeaders(token))
        .then(r => {
            success(r);
        })
        .catch(r => error(r));
};

export const doPut = (token, path, data, success, error) => {
    instance.put(path, data, getHeaders(token))
        .then(r => success(r))
        .catch(r => error(r));
};

export const doGet = (token, path, success, error) => {
    instance.get(path, getHeaders(token))
        .then(r => success(r))
        .catch(r => error(r));
};

export const doDelete = (token, path, success, error) => {
    instance.delete(path, getHeaders(token))
        .then(r => success(r))
        .catch(r => error(r));
};



