import * as actionTypes from './actionTypes';
import { doGet } from "../../axios-main";
import { selectTeamMember } from "./team_members";
import { selectSeason, loadingSeasons, loadSeasons, loadSeasonsError } from "./seasons";
import { authTouchSuccess } from "./auth";

export const loadingTeamMembers = () => {
    return {
        type: actionTypes.LOADING_TEAM_MEMBERS,
    };
};

export const loadTeamMembers = (members) => {
    return {
        type: actionTypes.LOAD_TEAM_MEMBERS,
        members: members
    };
};

export const loadTeamMembersError = (error) => {
    return {
        type: actionTypes.LOAD_TEAM_MEMBERS_ERROR,
        error: error
    };
};

export const loadingTeamInvites = () => {
    return {
        type: actionTypes.LOADING_TEAM_INVITES,
    };
};

export const loadTeamInvites = (invites) => {
    return {
        type: actionTypes.LOAD_TEAM_INVITES,
        invites: invites
    };
};

export const loadTeamInvitesError = (error) => {
    return {
        type: actionTypes.LOAD_TEAM_INVITES_ERROR,
        error: error
    };
};

export const selectVideoManageAction = (video) => {
    return {
        type: actionTypes.SELECT_VIDEO_MANAGE,
        video: video,
    };
};

export const openVideoManageAction = (menu, video) => {
    return {
        type: actionTypes.OPEN_VIDEO_MANAGE,
        menu: menu,
        is_upload: true,
        video: video,
    };
};

export const closeVideoManageAction = () => {
    return {
        type: actionTypes.CLOSE_VIDEO_MANAGE,
        is_upload: false,
        video: null,
    };
};

export const openTeamAction = (menu, new_video) => {
    return {
        type: actionTypes.OPEN_TEAM,
        menu: menu,
        new_video: new_video,
    };
};

export const closeTeamAction = () => {
    return {
        type: actionTypes.CLOSE_TEAM,
    };
};

export const openTeamCreateAction = () => {
    return {
        type: actionTypes.OPEN_TEAM_CREATE,
    };
};

export const closeTeamCreateAction = () => {
    return {
        type: actionTypes.CLOSE_TEAM_CREATE,
    };
};

export const selectingTeam = () => {
    return {
        type: actionTypes.SELECTING_TEAM,
        team: null,
        loading: true,
    };
};


export const selectTeam = (team) => {
    return {
        type: actionTypes.SELECT_TEAM,
        team: team,
        loading: false,
    };
};

export const gotSquads = (squads) => {
    return {
        type: actionTypes.LOAD_SQUADS,
        squads: squads,
    };
};

export const updateTeamImage = (res) => {
    return {
        type: actionTypes.UPDATE_TEAM_IMAGE,
        team_image: res,
    };
};

export const selectTeamAction = (session, team_id, season_id, member_team_index) => {
    return dispatch => {
        dispatch(selectingTeam());

        doGet(session, '/teams/select?team_id=' + team_id + '&season_index=' + season_id + '&member_team_index=' + member_team_index, (r) => {
            dispatch(selectTeamMember(r.data.member));
            dispatch(selectTeam(r.data.team));
            dispatch(selectSeason(r.data.season));
            dispatch(authTouchSuccess(r.data.session));

            dispatch(loadingSeasons());

            doGet(r.data.session.token, '/seasons',
                (r) => {
                    dispatch(loadSeasons(r.data));
                },
                () => {
                    dispatch(loadSeasonsError());
                });
        }, (r) => {
            dispatch(selectTeam(null));
            console.log('ERROR', r);
        });
    };
};

export const getSquads = (session) => {
    return dispatch => {
        // doGet(session, '/squads/list', {}, (r) => {
        //     dispatch(gotSquads(r.data.items));
        // }, (r) => {
        //     console.log('ERROR', r);
        // });
    };
};

export const loadTeamMembersAction = (session) => {
    return dispatch => {
        dispatch(loadingTeamMembers());
        doGet(session, '/teams/members', (r) => {
            dispatch(loadTeamMembers(r.data));
        }, (r) => {
            console.log('ERROR loading team members', r.response.data.message);
            dispatch(loadTeamMembersError(r.response.data.message));
        });
    };
};

export const loadTeamInvitesAction = (session) => {
    return dispatch => {
        dispatch(loadingTeamInvites());
        doGet(session, '/teams/invites', (r) => {
            dispatch(loadTeamInvites(r.data));
        }, (r) => {
            console.log('ERROR loading team invites', r.response.data.message);
            dispatch(loadTeamInvitesError(r.response.data.message));
        });
    };
};

export const resetTeam = () => {
    return {
        type: actionTypes.RESET_TEAM,
        team: null
    };
};

export const resetTeamAction = () => {
    return dispatch => {
        dispatch(resetTeam());
    };
};

export const updateTeam = (team) => {
    return dispatch => {
        dispatch(updateTeam(team));
    };
};

