import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    team: null,
    loading: false
};

const selectTeamReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        team: action.team,
        loading: false,
    });
};

const loadSquadsReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        squads: action.squads,
    });
};

const selectingTeamReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        team: action.team,
        loading: true,
    });
};

const openTeamReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        open_team: true,
        open_team_menu: action.menu,
        new_video: action.new_video,
    });
};

const closeTeamReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        open_team: false,
    });
};

const loadingTeamMembersReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_members: true,
    });
};

const loadTeamMembersReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_members: false,
        success: null,
        error: null,
        members: action.members,
    });
};

const loadTeamMembersErrorReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_members: false,
        success: null,
        error: action.error,
        members: null,
    });
};

const loadingTeamInvitesReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_invites: true,
    });
};

const loadTeamInvitesReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_invites: false,
        success: null,
        error: null,
        invites: action.invites,
    });
};

const loadTeamInvitesErrorReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        loading_team_invites: false,
        success: null,
        error: action.error,
        invites: null,
    });
};

const selectVideoManageReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        video: action.video,
    });
};

const openVideoManageReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        open_team: true,
        open_team_menu: action.menu,
        is_upload: true,
        video: action.video,
    });
};

const closeVideoManageReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        is_upload: false,
    });
};

const openTeamCreateReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        new_team: true,
    });
};

const closeTeamCreateReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        new_team: false,
    });
};

const resetTeamReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        team: null,
        loading: false,
    });
};

const updateTeamImageReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        team: {...state.team, image_url: action.team_image.small},
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOADING_TEAM_MEMBERS:
            return loadingTeamMembersReducer(state, action);
        case actionTypes.LOAD_TEAM_MEMBERS:
            return loadTeamMembersReducer(state, action);
        case actionTypes.LOAD_TEAM_MEMBERS_ERROR:
            return loadTeamMembersErrorReducer(state, action);
        case actionTypes.LOADING_TEAM_INVITES:
            return loadingTeamInvitesReducer(state, action);
        case actionTypes.LOAD_TEAM_INVITES:
            return loadTeamInvitesReducer(state, action);
        case actionTypes.LOAD_TEAM_INVITES_ERROR:
            return loadTeamInvitesErrorReducer(state, action);
        case actionTypes.SELECT_VIDEO_MANAGE:
            return selectVideoManageReducer(state, action);
        case actionTypes.CLOSE_VIDEO_MANAGE:
            return closeVideoManageReducer(state, action);
        case actionTypes.OPEN_VIDEO_MANAGE:
            return openVideoManageReducer(state, action);
        case actionTypes.CLOSE_TEAM:
            return closeTeamReducer(state, action);
        case actionTypes.OPEN_TEAM:
            return openTeamReducer(state, action);
        case actionTypes.CLOSE_TEAM_CREATE:
            return closeTeamCreateReducer(state, action);
        case actionTypes.OPEN_TEAM_CREATE:
            return openTeamCreateReducer(state, action);
        case actionTypes.SELECTING_TEAM:
            return selectingTeamReducer(state, action);
        case actionTypes.SELECT_TEAM:
            return selectTeamReducer(state, action);
        case actionTypes.LOAD_SQUADS:
            return loadSquadsReducer(state, action);
        case actionTypes.RESET_TEAM:
            return resetTeamReducer(state, action);
        case actionTypes.UPDATE_TEAM_IMAGE:
            return updateTeamImageReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
