import React from 'react';

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { withRouter } from "react-router-dom";
import { doPut } from "../../../../axios-main";
import "../../../Manage/Member/List/List.css";
import { getToken } from "../../../../components/functions";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from "../../../../components/UI/FormComponent/FormComponent";
import SubmitButton from '../../../../components/UI/SubmitButton/SubmitButton';
import SelectPanel from "../../../../components/UI/SelectPanel/SelectPanel";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import { updateParticipant } from '../../../../store/actions/participants';

class create extends FormComponent {
    state = {
        invite_others_allowed: true,
        modify_participant_allowed: true,
        relationship: '',
        relationship_other: {
            id: 'relationship_other',
            label: 'Other',
            value: '',
            valid: true,
            validate: {}
        },
    };

    onClose = e => {
        if (e) {
            e.stopPropagation()
        }

        this.props.close()

        this.clear();
    }

    submitHandler = (event) => {
        event.preventDefault();

        this.setLoading(true)

        doPut(this.props.token, '/participants', {
            ...this.props.participant,
            relationship: {
                ...this.props.member,
                relationship: this.state.relationship,
                relationship_other: this.state.relationship_other.value,
                permissions: {
                    invite_others: this.state.invite_others_allowed,
                    modify_participant: this.state.modify_participant_allowed,
                }
            }
        },
            (s) => {
                this.setLoading(false)
                this.props.updateParticipant(s.data)
                this.props.close()
            },
            (e) => {
                this.setLoading(false)
                this.setError(e.response?.data?.message ? e.response?.data?.message : 'Something happened!');
                console.log(e);
            })
    };

    clear = () => {
        this.setState({
            ...this.state,
            success: null,
            error: null,
            invite_others_allowed: true,
            modify_participant_allowed: true,
            relationship: '',
            relationship_other: {
                id: 'relationship_other',
                label: 'Other',
                value: '',
                valid: true,
                validate: {}
            },
        });
    }

    componentDidMount() {
        this.clear();
    }

    componentWillUnmount() {
        this.clear();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.member?.member_id !== this.props.member?.member_id) {
            this.setState({
                ...this.state,
                invite_others_allowed: this.props.member?.invite_others,
                modify_participant_allowed: this.props.member?.modify_participant,
                relationship: this.props.member?.relationship,
                relationship_other: { ...this.state.relationship_other, value: this.props.member?.relationship_other },
            })
        }
    }

    render() {
        const relationships = this.props.relationships ? this.props.relationships : []

        let form = (
            <div style={{ padding: 10 }}>
                {this.getAlert()}

                <SelectPanel label={'Relationship'} value={this.state.relationship}
                    disabled={this.state.loading} items={relationships}
                    change={(event) => this.onChangeField(event, 'relationship')} />
                {100 === this.state.relationship && <TextInput disabled={this.state.loading} info={this.state.relationship_other} change={(event, info) => this.onChange(event, info)} />}

                <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                    <FormControlLabel
                        label="Update Player"
                        control={
                            <Checkbox
                                disabled={this.state.loading}
                                checked={this.state.modify_participant_allowed}
                                onChange={this.onCheck('modify_participant_allowed')}
                                inputProps={{
                                    'aria-label': 'modify participant allowed checkbox',
                                }}
                                color='primary'
                            />
                        }
                    />

                    <FormControlLabel
                        label="Invite Others"
                        control={
                            <Checkbox
                                disabled={this.state.loading}
                                checked={this.state.invite_others_allowed}
                                onChange={this.onCheck('invite_others_allowed')}
                                inputProps={{
                                    'aria-label': 'invite others allowed checkbox',
                                }}
                                color='primary'
                            />
                        }
                    />
                </div>
                <div style={{ paddingTop: 10 }}>
                    <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                </div>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        const name = (this.props.member?.member_first_name ? this.props.member.member_first_name : '') + ' ' + (this.props.member?.member_last_name ? this.props.member.member_last_name : '')
        const theme = this.props.team_member?.alternate_team_theme ? this.props.team_member?.alternate_team_theme : this.props.team?.theme

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Update Family" close={this.onClose} name={name} image={this.props.member?.member_image_url} theme={theme} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        relationships: state.enums.relationships,
        team: state.teams.team,
        team_member: state.team_members.team_member,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateParticipant: (participant) => dispatch(updateParticipant(participant))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
