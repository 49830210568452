import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    participants: [],
    loading: false
};

const setParticipants = (state, action) => {
    return updateObject(state, {
        type: action.type,
        participants: action.participants
    });
};

const updateParticipant = (state, action) => {
    const {participants} = state

    let index
    if (participants) {
        index = participants.findIndex(p => action.participant.participant_index === p.participant_index)
    }

    if (index >= 0) {
        participants[index] = action.participant
    }

    return updateObject(state, {
        type: action.type,
        participants: participants
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PARTICIPANTS:
            return setParticipants(state, action);
        case actionTypes.UPDATE_PARTICIPANT:
            return updateParticipant(state, action);
        default:
            return state;
    }
};

export default reducer;
