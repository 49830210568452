import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '../../components/UI/Card/Card';
import Spinner from '../../components/UI/Spinner/Spinner';
import Alert from '../../components/UI/Alert/Alert';
import logo from "../../assets/images/logo.svg";
import { authReset } from "../../store/actions/auth";
import SignUpForm from "./SignUpForm";
import AuthTabs from './AuthTabs';

class SignUp extends Component {
    state = {
        value: 1
    }

    componentDidMount() {
        this.props.onReset();
    }

    componentWillUnmount() {
        this.props.onReset();
    }

    goToSignIn = () => {
        this.props.onReset();
        this.props.history.push('/');
    };

    handleChange = (event, value) => {
        event.stopPropagation()
        if (value === 0) {
            this.goToSignIn();
        }
    };

    render() {
        let form = <SignUpForm />;

        if (this.props.success) {
            form = <h4>An email has been sent. Please click the link to complete the sign up process.</h4>
        }

        let spinner;
        if (this.props.loading) {
            spinner = <Spinner delay={250} />
        }

        let alert = null;

        if (this.props.error) {
            alert = (
                <div style={{ marginTop: 10 }}><Alert alertType={'Red'}>{this.props.error}</Alert></div>
            );
        }

        return (
            <Card>
                <div><img src={logo} alt="SportsHub" width={'50%'} /></div>
                <AuthTabs value={this.state.value} change={this.handleChange} />
                <div>
                    {alert}
                    {spinner}
                    {form}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onReset: () => dispatch(authReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);