import React, { Component } from 'react';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Row from "../Row/Row";
import { doGet, doDelete } from "../../../axios-main";
import { getToken, canManageTeam } from "../../../components/functions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Card from "../../../components/UI/Card/Card";
import Create from "../Create/Create";
import Edit from "../Edit/Edit";
import { setParticipants } from '../../../store/actions/participants';

class SeasonList extends Component {
    state = {
        loading: false,
        empty: false,
        items: [],
        participant: {},
        open: false,
        edit: false,
    };

    componentWillUnmount() {
        this.setState({
            ...this.state,
            loading: false,
            empty: false,
            items: [],
            participant: {},
            open: false,
            edit: false
        });
    }

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.team) {
                this.props.setParticipants([])
                this.onLoad();
                clearInterval(i);
            }
        }, 0);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.path !== prevProps.match.path) {
            this.props.setParticipants([])
            setTimeout(this.onLoad(), 0)
        }
    }

    close = (e, load) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({
            ...this.state,
            participant: {},
            open: false,
            edit: false
        });

        if (load === true) {
            this.onLoad();
        }
    };

    remove = (e, model, delete_members) => {
        e.stopPropagation()

        doDelete(this.props.token,
            `/participants/${model.season_index}/${model.level_index}/${model.participant_index}?delete_members=${delete_members}`,
            (s) => {
                let participants = this.props.participants

                let i = participants.findIndex(x => x.participant_index === model.participant_index)

                if (i >= 0) {
                    participants.splice(i, 1)
                }

                this.setState({
                    ...this.state,
                    empty: participants.length <= 0,
                    loading: false
                });
                this.props.setParticipants(participants)
            },
            (e) => {
                console.log('Unable to remove the participant at this time.');
            }
        )
    }

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };


    onLoad = () => {
        this.setLoading(true);

        //TODO Not 0 level index
        doGet(this.props.token, `/participants?level_index=0&season_index=${this.props.season.season_index}`, (r) => {
            this.setState({
                ...this.state,
                empty: r.data.length <= 0,
                loading: false
            });
            this.props.setParticipants(r.data)
        }, (r) => {
            console.log(r.response.data.message);
            this.setLoading(false);
        });
    };

    createHandler = (event) => {
        event.stopPropagation();
        this.setState({ ...this.state, open: true });
    };

    onParticipant = (event, participant) => {
        this.setState({ ...this.state, edit: true, participant: participant });
    };

    render() {
        let table = <Spinner />;

        let button = <div style={{ padding: '10px 0 0 10px' }}>
            <Button variant="contained" color="primary" onClick={this.createHandler}> New Player </Button>
        </div>;

        if (this.state.empty) {
            table = <Card>
                <h1>Welcome to SportsHub.io</h1>
                <h2>There are no players to show, yet.</h2>
                {this.props.manage_team && <h3>Would you like to create a player?</h3>}

                {this.props.manage_team && <Button variant="contained" color="primary" onClick={this.createHandler}> New Player </Button>}
            </Card>
        } else if (this.props.participants && this.props.participants.length > 0) {
            button = <div style={{ padding: '10px 0 0 10px' }}>
                <Button variant="contained" color="primary" onClick={this.createHandler}> New Player </Button>
            </div>;

            table = <Paper style={{ margin: '10px' }}>
                <Table aria-label="participant table">
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left" style={{ paddingLeft: 80 }}>Name</TableCell>
                            <TableCell align="left">Grade</TableCell>
                            <TableCell align="left">Height</TableCell>
                            <TableCell align="left">Weight</TableCell>
                            <TableCell align="left">Jersey</TableCell>
                            <TableCell align="left">Relationships</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.participants.map(row => {
                                return (
                                    <Row onClick={(event, participant) => this.onParticipant(event, participant)}
                                        remove={(e, model, delete_members) => this.remove(e, model, delete_members)}
                                        manage_team={this.props.manage_team}
                                        team={this.props.team}
                                        team_member={this.props.team_member}
                                        key={`${row.participant_index}`} model={row} />
                                )
                            }
                            )
                        }
                    </TableBody>
                </Table>
            </Paper>
        }

        return (
            <div>
                {this.props.manage_team && <div>{button}</div>}
                {table}
                <Create close={(e, load) => this.close(e, load)} open={this.state.open} />
                <Edit open={this.state.edit} participant={this.state.participant}
                    close={(e, load, model) => this.close(e, load, model)} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
        team_member: state.team_members.team_member,
        participants: state.participants.participants,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setParticipants: (participants) => dispatch(setParticipants(participants))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SeasonList));
