import React, {Component} from 'react';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Row from "../Row/Row";
import EditDialog from "../Edit/Edit";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Card from "../../../components/UI/Card/Card";
import {pushPath,getToken} from "../../../components/functions";

class list extends Component {
    state = {
        loading: false,
        empty: false,
        items: [],
        open: false,
        squad: {},
    };

    componentWillUnmount() {
        this.setState({...this.state, items: [], empty: false, loading: false, open: false, squad: {}});
    }

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.onLoad();
                clearInterval(i);
            }
        }, 0);
    }

    setLoading = (loading) => {
        this.setState({...this.state, loading: loading});
    };

    onLoad = () => {
        this.setLoading(true);

        // TODO get squads
        // doPost(this.props.token, '/squads/list', {season_id: this.props.season.id}, (r) => {
        //     this.setState({
        //         ...this.state,
        //         items: r.data.items,
        //         empty: r.data.items.length <= 0,
        //         loading: false
        //     });
        // }, (r) => {
        //     console.log('ERROR', r.response.data.message);
        //     this.setLoading(false);
        // });
    };

    createHandler = (event) => {
        event.stopPropagation();
        pushPath(this.props, `squads/create`);
    };

    handleOpen = (event, squad) => {
        this.setState({...this.state, open: true, squad: squad});
    };

    handleClose = (squad) => {
        this.onLoad();
        this.setState({...this.state, open: false, squad: {}});
    };

    render() {
        let table = <Spinner/>;
        let button;

        if (this.state.empty) {
            table = <Card>
                <h1>Welcome to SportsHub.io</h1>
                <h2>There are no squads to show, yet.</h2>
                <h3>Would you like to create a squad?</h3>

                <Button variant="contained" color="primary" onClick={this.createHandler}> New Squad </Button>
            </Card>
        } else if (this.state.items && this.state.items.length > 0) {
            button = <div style={{padding: '10px 0 0 10px'}}>
                <Button variant="contained" color="primary" onClick={this.createHandler}> New Squad </Button>
            </div>;


            table = <Paper style={{margin: '10px'}}>
                <Table aria-label="squad table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Title</TableCell>
                            <TableCell align="left">&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.state.items.map(row => {
                                    if (row.id) {
                                        return (
                                            <Row onClick={(event, squad) => this.handleOpen(event, squad)}
                                                 key={`${row.id}`} model={row}/>
                                        )
                                    } else {
                                        console.log('EMPTY', row);
                                        return '';
                                    }
                                }
                            )
                        }
                    </TableBody>
                </Table>
                <EditDialog squad={this.state.squad} open={this.state.open}
                            onClose={(squad) => this.handleClose(squad)}/>
            </Paper>
        }

        return (
            <div>
                {button}
                {table}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
