import React, { Component } from 'react';

import styles from './CloseIcon.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'

class CloseIcon extends Component {
    render() {
        return (
            <FontAwesomeIcon icon={faTimes} className={styles.Close} onClick={this.props.close} aria-label="close" />
        )
    }
}
export default CloseIcon;
