import React from 'react';

import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../components/UI/Spinner/Spinner";
import Form from "../../components/UI/Form/Form";
import TextInput from "../../components/UI/TextInput/TextInput";
import { withRouter } from "react-router-dom";
import SelectPanel from "../../components/UI/SelectPanel/SelectPanel";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from '../../components/UI/FormComponent/FormComponent';
import CheckPanel from "../../components/UI/CheckPanel/CheckPanel";

class create extends FormComponent {
    state = {
        relationship: '',
        relationship_other: {
            id: 'relationship_other',
            label: 'Other',
            value: '',
            valid: true,
            validate: {}
        },
        hide_contact_info: false,
    };

    componentDidMount() {
        this.reset();
    }

    reset = () => {
        this.setState({
            ...this.state,
            hide_contact_info: false,
            relationship: '',
            relationship_other: { ...this.state.relationship_other, value: '' },
        });
    }

    close = () => {
        this.reset()
        this.props.close()
    };

    submitHandler = (event) => {
        event.preventDefault()

        this.reset();

        this.props.relationship(this.state.hide_contact_info, this.state.relationship, this.state.relationship_other.value)
    };

    render() {
        const relationships = this.props.relationships ? this.props.relationships : []

        let form = (
            <div style={{ padding: 10, textAlign: 'center' }}>
                <Form onSubmit={this.submitHandler}>
                    {this.props.show_relationship && <SelectPanel label={'Relationship'} value={this.state.relationship}
                        items={relationships}
                        change={(event) => this.onChangeField(event, 'relationship')} />}

                    {(this.props.show_relationship && 100 === this.state.relationship) && <TextInput info={this.state.relationship_other} change={(event, info) => this.onChange(event, info)} />}

                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('hide_contact_info')} label='Hide Contact Info' checked={this.state.hide_contact_info} />
                    </div>

                    <Button style={{ marginLeft: 2 }} disabled={this.props.loading} variant="contained" color="primary"
                        onClick={this.submitHandler}> ACCEPT </Button>
                </Form>
            </div>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        let title = 'Just one more thing...'

        if (this.props.show_relationship) {
            title = 'How do you know this person?'
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.close} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={title} close={this.close} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        relationships: state.enums.relationships,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
