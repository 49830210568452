import React, { Component } from 'react';
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { NavLink, Route, Switch, withRouter } from "react-router-dom";
import Roster from "../Roster/index";
import Video from "../Video/index";
import Email from "../Email/index";
import Squad from "../Squad/index";
import Manage from "../Manage/index";
import Event from "../Event/index";
import SideNavItem from "./SideNavItem";
import { clearPath, getTeamMemberPermissions, pushPath, getToken } from "../../components/functions";
import "../../App.css";
import classes from "./Season.module.css";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RosterIcon from "@material-ui/icons/Accessibility";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import VideoIcon from "@material-ui/icons/Videocam";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import TeamDialog from "./TeamDialog";
import AppsIcon from '@material-ui/icons/Apps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { VIEW_VIDEOS, MANAGE_VIDEOS } from '../../constants/permissions';

class season extends Component {
    state = {
        season: false,
        show_squads: false,
    };

    onTeam = () => {
        this.props.openTeam()
    };

    onSeason = () => {
        if (this.props.seasons?.length > 1) {
            this.setState({ ...this.state, season: true })
        } else {
            this.onTeam();
        }
    };

    onClose = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.props.closeTeam()
    };

    offMenu = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, season: false })
    };

    link = (link) => {
        this.offMenu();
        clearPath(this.props, `profile/${link}`)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            if ((this.props.match.params.team_id !== prevProps.match.params.team_id) || (Number(this.props.match.params.season_id) !== Number(prevProps.match.params.season_id))) {
                this.select(this.props.match.params.team_id, this.props.match.params.season_id);
            }
        }, 0)
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        const { match, user, team, history } = this.props;
        const { params } = match ? match : {};
        const { team_id, season_id } = params ? params : {};

        if (params && team_id && season_id) {
            this.select(team_id, season_id);
        } else if (!team) {
            if (!user) {
                history.push('/');
            } else {
                if (!user.teams || user.teams.length <= 0) {
                    history.push('/');
                } else {
                    if (user.teams) {
                        const team = user.teams.find(t => Number(t.team.id) === Number(team_id));

                        const season = team.team.seasons.find(s => Number(s.id) === Number(season_id));

                        this.select(team.id, season.id);
                    }
                }
            }
        }
    }

    select = (team_id, season_id) => {
        this.props.selectTeamHandler(this.props.token, team_id, season_id, 0);
        setTimeout(() => {
            // todo load squads?
            // this.loadSquads();
        }, 0);
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    handleCreate = (e) => {
        e.stopPropagation();
        e.preventDefault();

        pushPath(this.props, 'squads/create')
    };

    navigate = (l) => {
        this.props.history.push(`${this.props.match.url}/` + l);
    };

    render() {
        const { team, season, team_member } = this.props;

        const path = `/${this.props.match.params.team_id}/${this.props.match.params.season_id}/`

        const seasons = this.props.seasons ? this.props.seasons : [];

        let season_menu;
        if (this.state.season) {
            season_menu = <div className={[classes.SeasonMenu, classes.SeasonMenuActive].join(' ')}>
                {seasons.map(season => (
                    <NavLink
                        key={season.season_index}
                        className={classes.NavLink}
                        activeClassName={classes.Active}
                        to={`/${this.props.team.team_id}/${season.season_index}/roster`}
                        onClick={this.offMenu}>
                        <div>{season.season_title}</div>
                    </NavLink>
                ))}
            </div>
        }

        return (
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                {this.state.season && <div className={classes.SeasonOverlay} onClick={this.offMenu} />}
                <div className={'Outer'} style={{
                    minWidth: 200,
                    maxWidth: 200,
                    background: '#9E9E9E',
                    overflowY: 'auto',
                    overflowX: 'hidden'
                }}>
                    <div>
                        <div className={classes.TeamContainer}>
                            <div className={classes.Team}
                                onClick={this.onTeam} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <div style={{ paddingRight: 5, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{ team_member?.alternate_team_title ? team_member.alternate_team_title : team?.team_title }</div>
                                <FontAwesomeIcon icon={faCog} size="xs" />
                            </div>

                            <div className={classes.Season}
                                onClick={() => this.onSeason(seasons)} style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ paddingRight: 5, flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{(season && season.season_title) ? season.season_title : ''}</div>
                                {this.props.seasons?.length > 1 && <ExpandMore style={{ padding: 0, margin: 0, fontSize: '1rem' }} />}
                            </div>

                            <div style={{ position: 'relative' }}>
                                {season_menu}
                            </div>
                        </div>
                    </div>
                    <div className={'Inner'}>
                        <SideNavItem exact={true} url={`${path}roster`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                    <RosterIcon style={{ fontSize: 15 }} />
                                </div>
                                <div style={{ lineHeight: '1.2rem', fontSize: '0.8rem' }}>Roster</div>
                            </div>
                        </SideNavItem>
                        {/* <SideNavItem exact={true} url={`${path}emails`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                    <EmailIcon style={{ fontSize: 15 }} />
                                </div>
                                <div style={{ lineHeight: '1.2rem', fontSize: '0.8rem' }}>Emails</div>
                            </div>
                        </SideNavItem> */}
                        {/* <SideNavItem exact={true} url={`${path}events`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                    <EventIcon style={{ fontSize: 15 }} />
                                </div>
                                <div style={{ lineHeight: '1.2rem', fontSize: '0.8rem' }}>Events</div>
                            </div>
                        </SideNavItem> */}

                        {(((team_member?.permissions & VIEW_VIDEOS) !== 0) || ((team_member?.permissions & MANAGE_VIDEOS) !== 0)) && <SideNavItem exact={true} url={`${path}videos`}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                    <VideoIcon style={{ fontSize: 15 }} />
                                </div>
                                <div style={{ lineHeight: '1.2rem', fontSize: '0.8rem' }}>Videos</div>
                            </div>
                        </SideNavItem>}

                        {(this.props.squads && this.props.squads.length > 0) &&
                            <SideNavItem style={{ cursor: this.state.show_squads ? 'n-resize' : 's-resize' }} exact={false} url={`${path}squads/${this.props.squads}`} onClick={() => this.setState({ ...this.state, show_squads: !this.state.show_squads })}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                        <AppsIcon style={{ fontSize: 15 }} />
                                    </div>
                                    <div style={{ flex: 1, lineHeight: '1.2rem', fontSize: '0.8rem' }}>Squads</div>
                                    <div onClick={this.handleCreate}>
                                        <AddIcon style={{ cursor: 'pointer', fontSize: 15, paddingRight: 7, paddingTop: 2 }} />
                                    </div>
                                </div>
                            </SideNavItem>}

                        {this.state.show_squads && this.props.squads.map(s =>
                            <SideNavItem key={s.id} url={`${path}squads/${s.id}`}>
                                <div style={{ paddingLeft: 10, display: 'flex', alignItems: 'center' }}>
                                    {s.image_url &&
                                        <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                            <img alt={`${s.title} squad`} width="25px" src={s.image_url} className="Circle" />
                                        </div>}
                                    {!s.image_url &&
                                        <div style={{ paddingTop: 2, paddingRight: 2 }}>
                                            <ViewHeadlineIcon style={{ fontSize: 15, color: s.background_color }} />
                                        </div>}
                                    <div style={{ lineHeight: '1.2rem', fontSize: '0.8rem' }}>{s.title}</div>
                                </div>
                            </SideNavItem>)}
                    </div>
                    <div style={{ background: 'black', height: 43, cursor: "pointer" }}
                        onClick={this.props.openProfile}>
                        <div className={classes.Container}>
                            <div style={{ width: 195, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className={classes.Name} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                    {this.props.user.member.first_name} {this.props.user.member.last_name}
                                </div>
                                {this.props.user.member.user_name &&
                                    <div className={classes.Username} style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                        @{this.props.user.member.user_name}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                    <Switch>
                        <Route path={`${this.props.match.path}/manage`} component={Manage} />
                        <Route path={`${this.props.match.path}/roster`} render={(props) => (<Roster key={`${props.match.params.season_id}`} {...props} />)} />
                        <Route path={`${this.props.match.path}/videos`} component={Video} />
                        <Route path={`${this.props.match.path}/squads`} component={Squad} />
                        <Route path={`${this.props.match.path}/emails`} component={Email} />
                        <Route path={`${this.props.match.path}/events`} component={Event} />
                    </Switch>
                </div>
                <TeamDialog view={this.props.team_menu} open={this.props.team_open} close={this.onClose} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        user: state.auth.user,
        team: state.teams.team,
        team_open: state.teams.open_team ? true : false,
        team_menu: state.teams.open_team_menu,
        team_member: state.team_members.team_member,
        season: state.seasons.season,
        squads: state.teams.squads ? state.teams.squads : [],
        seasons: state.seasons.seasons,
        permissions: getTeamMemberPermissions(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectTeamHandler: (session, team_id, season_id, member_team_index) => dispatch(actions.selectTeamAction(session, team_id, season_id, member_team_index)),
        getSquads: (squads) => dispatch(actions.getSquads(squads)),
        openTeam: () => dispatch(actions.openTeamAction('Profile', false)),
        closeTeam: () => dispatch(actions.closeTeamAction()),
        openProfile: () => dispatch(actions.openProfileAction()),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        getSeasons: (token) => dispatch(actions.getSeasons(token)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(season));
