import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { MenuItem } from "@material-ui/core";

class panel extends React.Component {
    render() {
        let { id, title } = this.props;

        if (!id) {
            id = 'id'
        }

        if (!title) {
            title = 'title'
        }

        return (
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="label">{this.props.label}</InputLabel>
                <Select
                    labelId="select_label"
                    id="select"
                    value={this.props.value}
                    style={{ width: '100%', textAlign: 'left' }}
                    disabled={this.props.disabled}
                    onChange={this.props.change}>
                    {
                        this.props.items.map(g => (
                            <MenuItem key={`${g[id]}`} value={g[id]}
                                style={{ padding: '5px 5px 0 5px', margin: 0 }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {g.image_url &&
                                        <div style={{ paddingRight: 10 }}><img alt={g.title} width={30} className="Circle"
                                            src={g.image_url} /></div>}
                                    <div style={{ flex: 1 }}>{g[title]}</div>
                                </div>
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }
}

export default panel;
