import React, { Component } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BooleanView from "../../../../../components/UI/BooleanView/index";
import {
    ADD_AND_REMOVE_MEMBERS_FROM_SQUAD,
    DELETE_SQUAD,
    EDIT_SQUAD_APPEARANCE,
    MANAGE_EVENTS_ON_SQUAD,
    SEND_CHATS_TO_SQUAD,
    SEND_EMAILS_TO_SQUAD
} from "../../../../../constants/permissions";
import RemoveIcon from "@material-ui/icons/Delete";


class row extends Component {
    removeMember = () => {

    };

    render() {
        const rights = this.props.model.permissions;

        return (
            <TableRow key={`${this.props.model.id}`}>
                <TableCell align="left"><img width={50} className="Circle" alt={this.props.model.full_name} src={this.props.model.member_image_url} /></TableCell>
                <TableCell align="left">{this.props.model.full_name}</TableCell>
                <TableCell align="left"><BooleanView value={(rights & ADD_AND_REMOVE_MEMBERS_FROM_SQUAD) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(rights & DELETE_SQUAD) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(rights & EDIT_SQUAD_APPEARANCE) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(rights & SEND_CHATS_TO_SQUAD) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(rights & SEND_EMAILS_TO_SQUAD) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(rights & MANAGE_EVENTS_ON_SQUAD) !== 0} /></TableCell>
                <TableCell align="left"><RemoveIcon onClick={this.removeMember} /></TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(row));
