import React from 'react';

import './Error.css';

const error = (props) => {
    return (
        <div className={'Error'}>
            {props.children}
        </div>
    );
};

export default error;
