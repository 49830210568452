import React, { Component } from 'react';
import Alert from '../Alert/Alert';
import { checkEmail } from "../../functions";

class FormComponent extends Component {

    reset = () => {
        this.setState({
            ...this.state,
            success: null,
            error: null,
        });
    }

    onBlurEmail = (event) => {
        if (this.state.email.valid) {
            if (!this.state.username.value) {
                this.checkUsername(this.state.email.value.split('@')[0]);
            }
        }
    };

    checkUsername = (username) => {
        if (username && username.length > 10) {
            username = username.replace('+', '');
            this.setState({...this.state, username: {...this.state.username, value: username, valid: false, message: 'Must be less than ' + 10 + ' characters or less.'}})
        } else {
            username = username.replace('+', '');
            this.setState({...this.state, username: {...this.state.username, value: username, valid: true, message: ''}})
        }
    } 

    onRemoveEmail = (email) => {
        this.reset()
        
        const { emails } = this.state;

        const i = emails.findIndex(e => e.toLowerCase() === email.toLowerCase());

        if (i >= 0) {
            emails.splice(i, 1);
        }

        this.reset();
        this.setState({ ...this.state, emails: emails });
    };

    onEnterEmail = (event) => {
        if (event.key === 'Enter') {
            this.reset();

            if (checkEmail(event.target.value.toLowerCase())) {
                event.stopPropagation();
                event.preventDefault();

                this.addEmail(event.target.value);

                this.setState({ ...this.state, email: { ...this.state.email, value: '' } });
            }
        }
    };

    onCheckEmail = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.reset();

        if (checkEmail(event.target.value.toLowerCase())) {
            this.addEmail(event.target.value);

            this.setState({ ...this.state, email: { ...this.state.email, value: '' } });
        }
    };

    onAddEmail = (email) => {
        this.reset();

        this.addEmail(email);
    };

    addEmail = (email) => {
        const { emails } = this.state;

        const i = emails.findIndex(e => e.toLowerCase() === email.toLowerCase());

        if (i < 0) {
            emails.push(email);
        } else {
            console.log(i);
            console.log('email in the list.');
        }

        this.reset();
        this.setState({ ...this.state, emails: emails });
    };

    removeEmail = (i) => {
        const emails = this.state.emails;
        emails.splice(i, 1);
        this.setState({ ...this.state, emails: emails });

        if (emails.length <= 0) {
            this.addEmail();
        }
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeDate = (event, field) => {
        this.reset();
        console.log(event.getTime())
        this.setState({ ...this.state, [field]: event });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    onChangeColor = (value, field) => {
        this.reset();
        this.setState({...this.state, [field]: value});
    };

    onCheck = name => event => {
        this.reset();
        this.setState({...this.state, [name]: event.target.checked});
    };

    setSuccess(message) {
        this.setState({
            ...this.state,
            success: message,
            error: null,
        });
    }

    setError(message) {
        this.setState({
            ...this.state,
            success: null,
            error: message,
        });
    }

    getAlert = () => {
        let alert = null;

        if (this.state.success) {
            alert = (
                <Alert alertType={'Green'}>{this.state.success}</Alert>
            );
        }

        if (this.state.error) {
            alert = (
                <Alert alertType={'Red'}>{this.state.error}</Alert>
            );
        }

        return alert;
    }
}

export default FormComponent;
