import React from 'react';

const filesize = ( props ) => {
    const size = props.size ? Number(props.size) : 0;
    const compare = props.compare ? Number(props.compare) : 0;
    const factor = props.factor ? Number(props.factor) : 100;

    let value = 0;
    let type;

    if ((size > (1024 * 1024 * 1024 * 1024 * 1024)) || (compare > (1024 * 1024 * 1024 * 1024 * 1024))) {
        value = (Math.round(size / (1024 * 1024 * 1024 * 1024 * 1024) * factor)) / factor;
        type = 'PB';
    } else if ((size > (1024 * 1024 * 1024 * 1024)) || (compare > (1024 * 1024 * 1024 * 1024))) {
        value = (Math.round(size / (1024 * 1024 * 1024 * 1024) * factor)) / factor;
        type = 'TB';
    } else if ((size > (1024 * 1024 * 1024)) || (compare > (1024 * 1024 * 1024))) {
        value = (Math.round(size / (1024 * 1024 * 1024) * factor)) / factor;
        type = 'GB';
    } else if ((size > (1024 * 1024)) || (compare > (1024 * 1024))) {
        value = (Math.round(size / (1024 * 1024) * factor)) / factor;
        type = 'MB';
    } else if ((size > 1024) || (compare > 1024)) {
        value = (Math.round(size / (1024) * factor)) / factor;
        type = 'KB';
    } else {
        value = size;
        if (size > 0) {
            type = 'b';
        }
    }

    return (
        <div>
            {value}{type}
        </div>
    );
};

export default filesize;
