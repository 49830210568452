import React, { Component } from 'react';
import { doDelete, doPut } from '../../../axios-main';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getToken, canManageTeam } from '../../../components/functions';
import Spinner from "../../../components/UI/Spinner/Spinner";
import CloseIcon from '../../../components/UI/CloseIcon/CloseIcon';
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import InviteRow from "./Row/Invite";
import Button from "@material-ui/core/Button";
import Create from "./Create/Create"
import Snackbar from '@material-ui/core/Snackbar';
import "./Invites.css";

class invites extends Component {
    state = {
        is_detail: false,
        is_create: false,
        snackbar: false,
        snackbar_message: '',
        participant: {}
    };

    remove = (e, model) => {
        e.stopPropagation()

        doDelete(this.props.token,
            `/participants/invites?season_index=${model.season_index}&level_index=${model.level_index}&participant_index=${model.participant_index}&email_address=${model.email_address}`,
            (s) => {
                this.props.reload()
            },
            (e) => {
                console.log('Unable to remove the invite at this time.');
            }
        )
    }

    closeSnackbar = () => {
        this.setState({ ...this.state, snackbar: false, snackbar_message: '' })
    }

    resend = (e, model) => {
        if (e) {
            e.stopPropagation()
        }

        doPut(this.props.token, `/participants/invites/resend`, {
            email_address: model.email_address,
            level_index: model.level_index,
            participant_index: model.participant_index,
            season_index: model.season_index,
        },
            (s) => {
                this.setState({...this.state, snackbar: true, snackbar_message: 'The invite has been sent to ' + model.email_address + '.'})
            }, (r) => {
                console.log('ERROR', r);
                this.setState({...this.state, snackbar: true, snackbar_message: 'Unable to send the invite.'})
            });
    };

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true })
    };

    onDetail = (e, model) => {
        e.stopPropagation()
    };

    onClose = (e, created) => {
        if (e) {
            e.stopPropagation()
        }

        if (created) {
            this.props.created()
        }

        this.setState({ ...this.state, is_create: false, is_detail: false, model: {} })
    };

    render() {
        let table = <Spinner />;

        if (!this.state.loading) {
            table = <h2>Send out some invites to your family members to share how you are participating on the team.</h2>
        }

        const { participant } = this.props
        const { invites } = participant

        if (invites && invites.length > 0) {
            table = <Paper>
                <Table aria-label="team members table" size={'small'} className={'Table'}>
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left"><div>Manage</div><div>Billing</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Team</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Photos</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Videos</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Members</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Videos</div></TableCell>
                            {this.props.manage_team && <TableCell align="right"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invites.map(row => {
                            return (
                                <InviteRow onClick={(e, model) => this.onDetail(e, model)}
                                    remove={(e, model) => this.remove(e, model)}
                                    resend={(e, model) => this.resend(e, model)}
                                    manage_team={this.props.manage_team}
                                    key={`${row.email_address}${row.created_on}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        }

        return (
            <div>
                {(this.props.manage_team || this.props.team_member_relationship?.invite_others) && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> Invite Family </Button>
                </div>}

                {table}

                <Create open={this.state.is_create} close={(e, created) => this.onClose(e, created)} participant={this.props.participant} />
                <Snackbar
                    open={this.state.snackbar}
                    autoHideDuration={5000}
                    onClose={this.closeSnackbar}
                    message={this.state.snackbar_message}
                    action={<>
                        <div className={'Close'} variant="contained" color="primary" onClick={this.closeSnackbar}>
                            <CloseIcon style={{ marginRight: 0 }} aria-label="close" />
                        </div>
                    </>}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(invites));
