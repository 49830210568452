import React, { Component } from 'react';
import Alert from '../../components/UI/Alert/Alert';

class FormContainer extends Component {

    reset = () => {
        this.setState({
            ...this.state,
            success: null,
            error: null,
        });
    }

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    getAlert = () => {
        let alert = null;

        if (this.state.success) {
            alert = (
                <Alert alertType={'Green'}>{this.state.success}</Alert>
            );
        }

        if (this.state.error) {
            alert = (
                <Alert alertType={'Red'}>{this.state.error}</Alert>
            );
        }

        return alert;
    }
}

export default FormContainer;
