import React, {Component} from 'react';
import * as actions from "../../store/actions";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import Create from "../Roster/Create/Create";
import Edit from "../Roster/Edit/Edit";
import RosterList from "../Roster/List/List";

class index extends Component {
    path = '';

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    render() {
        const {team_id, season_id} = this.props.match.params;

        let path;
        if (team_id && season_id) {
            path = `/${team_id}/${season_id}/roster/`;
        }

        this.path = path;

        return (
            <div>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <div style={{flexGrow: 1}}>
                        <Switch>
                            <Switch>
                                <Route path={`${path}create`} component={Create}/>
                                <Route path={`${path}:participant_id`} component={Edit}/>
                                <Route path={`${path}`} render={(props) => (<RosterList key={`${props.match.params.season_id}`} {...props} />)}
                    />
                            </Switch>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.members.loading,
        error: state.members.error,
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
        list: state.members.items,
        user: state.auth.user,
        team: state.teams.team,
        season: state.seasons.season,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
