import React, { Component } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import EditIcon from '@material-ui/icons/Edit';

class row extends Component {
    render() {
        let row = this.props.model;

        return (
            <TableRow style={{ cursor: "pointer", background: row.background_color }} onClick={(event) => this.props.onClick(event, row)} key={`${row.id}`}>
                <TableCell align="left">
                    <img alt={`${row.title}`} width={50} src={row.image_url} className="Circle" />
                </TableCell>
                <TableCell align="left" style={{ color: row.text_color }}>
                    {row.title}
                </TableCell>
                <TableCell align="left" style={{ color: row.text_color }}>
                    <EditIcon />
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(row);
