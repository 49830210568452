import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import * as actions from '../../../../store/actions/index';
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { doGet, doDelete } from "../../../../axios-main";
import { getToken, fieldSorter, canManageVideos } from "../../../../components/functions";
import Row from "../Row/Row";
import Create from "../Create/Create"
import Detail from "../Detail/Detail"
import Upload from "../Upload/Upload"
import Organize from "../Organize/Organize"
import SelectPanel from "../../../../components/UI/SelectPanel/SelectPanel";
import BlockUsage from "../../../../components/BlockUsage/BlockUsage";
import Duration from "../../../../components/Duration/Duration";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogAppBar from '../../../../components/UI/DialogAppBar/DialogAppBar';

class list extends Component {
    state = {
        items: [],
        item: [],
        season_index: '0',
        is_edit: false,
        is_detail: false,
        is_organize: false,
        is_remove: false,
    };

    componentDidMount() {
        setTimeout(() => this.props.getSeasons(this.props.token), 0);

        let i = setInterval(() => {
            if (this.props.team) {
                this.setState({ ...this.state, season_index: this.props.match?.params?.season_id ? this.props.match?.params?.season_id : '0' });
                setTimeout(() => this.onLoad(), 0);
                clearInterval(i);
            }
        }, 0);

    }

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    onLoad = () => {
        this.setLoading(true);
        doGet(this.props.token, '/videos?season_index=' + this.state.season_index,
            (r) => {
                this.setState({ ...this.state, items: r.data });
                this.setLoading(false);
            },
            (r) => {
                console.log('ERROR', r);
                this.setLoading(false);
            });
    };

    getItem = () => {
        return this.state.items.find((i) => i.video_id === this.props.video.video_id)
    }

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.props.openTeam(true)
    };

    onEdit = (e, item) => {
        e.stopPropagation()
        this.setState({ ...this.state, is_detail: true, item: this.getItem() })
    };

    onOrganize = (e, item) => {
        e.stopPropagation()
        this.setState({ ...this.state, is_organize: true, item: this.getItem() })
    };

    onUpload = (e, item) => {
        e.stopPropagation()

        this.props.openManageVideo(this.getItem())
    };

    onRemove = (e, item) => {
        e.stopPropagation()
        console.log('REMOVE', this.getItem())
        this.setState({ ...this.state, is_remove: true, item: this.getItem() })
    };

    select = (e, item) => {
        e.stopPropagation()
        if (this.props.video?.video_id === item.video_id) {
            this.props.selectManageVideo(null)
        } else {
            this.props.selectManageVideo(item)
        }
    };

    onClose = (e, load) => {
        if (e) {
            e.stopPropagation()
        }

        if (load) {
            this.onLoad()
        }

        this.setState({
            ...this.state,
            is_detail: false,
            is_organize: false,
            is_remove: false,
        })

        this.props.openTeam(false);
        this.props.closeManageVideo();
    };

    onChangeSeason = (event, field) => {
        this.setState({ ...this.state, [field]: event.target.value });
        this.props.selectManageVideo(null)
        setTimeout(() => this.onLoad(), 0);
    };

    confirm = (e) => {
        const item = this.getItem()
        
        doDelete(this.props.token, `/videos?season_index=${item.season_index}&video_id=${item.video_id}`,
            () => {
                const {items} = this.state

                const i = items.findIndex(i => i.video_id === item.video_id)

                if (i >= 0) {
                    items.splice(i, 1);
                }

                this.setState({ ...this.state, items: items })

                this.onClose();
            },
            (r) => {
                console.log('ERROR', r.response.data.message);
            });
    }

    render() {
        const { items } = this.state;

        let total = 0
        let total_size = 0
        let total_duration = 0
        let total_files = 0

        items.map(row => {
            total++
            total_size += row.total_size
            total_duration += row.total_duration
            total_files += row.total_files
            return 0;
        })

        return (
            <div>
                <div style={{ display: 'flex', paddingBottom: 15 }}>
                    {(this.props.seasons && (this.props.seasons.length > 0)) &&
                        <div style={{ width: '32em' }}>
                            <SelectPanel label={'Seasons'} value={this.state.season_index}
                                id='season_index' title='season_title' items={this.props.seasons}
                                change={(event) => this.onChangeSeason(event, 'season_index')} />
                        </div>
                    }

                    {this.props.manage_video && <div style={{ padding: '12px 0 0 10px' }}>
                        <Button variant="contained" color="primary" onClick={this.onCreate}> New Video </Button>
                    </div>}
                </div>

                {this.props.manage_video &&
                    <div style={{ padding: '0 0 5px 0' }}>
                        {this.props.manage_video && <Button disabled={!this.props.video?.video_id} variant="contained" color="primary" onClick={this.onUpload} style={{ marginRight: 3 }}> Upload </Button>}
                        {this.props.manage_video && <Button disabled={!this.props.video?.video_id} variant="contained" color="primary" onClick={this.onRemove} style={{ marginRight: 3 }}> Remove </Button>}
                        {this.props.manage_video && <Button disabled={!this.props.video?.video_id} variant="contained" color="primary" onClick={this.onEdit} style={{marginRight: 3}}> Edit </Button>}
                        {/* <Button disabled={!this.props.video?.video_id} variant="contained" color="primary" onClick={this.onOrganize} style={{marginRight: 3}}> Organize </Button> */}
                    </div>
                }

                <Table aria-label="season table">
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left" style={{ paddingLeft: 62 }}>
                                Title
                            </TableCell>
                            <TableCell align="left">Kind</TableCell>
                            <TableCell align="left">Date</TableCell>
                            <TableCell align="left">Size</TableCell>
                            <TableCell align="left">Duration</TableCell>
                            <TableCell align="left">Clips</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.sort(fieldSorter(['-occurred_on', 'title', '-created_on', 'video_id'])).map(row => {
                            return (
                                <Row checked={this.props.video?.video_id} key={`${row.video_id}`} model={row} select={(e, model) => this.select(e, model)} />
                            )
                        })}
                    </TableBody>
                    <TableBody className='PrimaryTableFooter'>
                        <TableRow>
                            <TableCell colSpan={3} align="left">
                                <div style={{ paddingLeft: 42 }}>TOTAL ({total})</div>
                            </TableCell>
                            <TableCell align="left">
                                <BlockUsage size={total_size} />
                            </TableCell>
                            <TableCell align="left">
                                <Duration duration={total_duration} />
                            </TableCell>
                            <TableCell align="left">
                                {total_files}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Create open={this.props.new_video} close={this.onClose} season_index={this.state.season_index} />
                <Detail open={this.state.is_detail} close={this.onClose} item={this.props.video} />
                <Upload open={this.props.is_upload} close={this.onClose} item={this.props.video} />
                <Organize open={this.state.is_organize} close={this.onClose} item={this.props.video} />

                <Dialog fullWidth={true} maxWidth={'sm'} scroll={'paper'} onClose={this.onClose} open={this.state.is_remove} disableBackdropClick>
                    <DialogAppBar title={'Remove Video?'} close={this.onClose} />
                    <DialogContent>
                        <h3>Remove {this.getItem()?.video_title}?</h3>
                        <Button size="small" variant="contained" color="primary" onClick={this.confirm}> YES </Button>
                    </DialogContent>
                </Dialog >
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        team: state.teams.team,
        seasons: state.seasons.seasons,
        loading: state.seasons.loading,
        success: state.seasons.success,
        error: state.seasons.error,
        manage_video: canManageVideos(state),
        new_video: state.teams.new_video ? true : false,
        is_upload: state.teams.is_upload ? true : false,
        video: state.teams.video ? state.teams.video : {},
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSeasons: (token) => dispatch(actions.getSeasons(token)),
        openTeam: (new_video) => dispatch(actions.openTeamAction('Video', new_video)),
        selectManageVideo: (video) => dispatch(actions.selectVideoManageAction(video)),
        openManageVideo: (video) => dispatch(actions.openVideoManageAction('Video', video)),
        closeManageVideo: () => dispatch(actions.closeVideoManageAction()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
