import React, {Component} from 'react';

class Height extends Component {
    state = {
        feet: 0,
        inches: 0,
    };

    componentDidMount() {
        if (this.props.value) {
            if (this.props.value && Number(this.props.value) > 0) {
                const f = Math.floor(Number(this.props.value) / 12);
                const i = Number(this.props.value) - (f * 12);
                this.setState({...this.state, feet: f, inches: i});
            }
        }
    }

    render() {
        let v = '';
        if (this.state.feet) {
            v = v + this.state.feet + '\' ';
        }

        if (this.state.inches) {
            v = v + this.state.inches + '" ';
        }

        return (
            <div>{v}</div>
        );
    }
}

export default Height;
