import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    team_member: null,
    loading: false
};

const selectTeamMemberReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        team_member: action.team_member,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_TEAM_MEMBER:
            return selectTeamMemberReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
