import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import authReducer from './store/reducers/auth';
import homeReducer from './store/reducers/home';
import membersReducer from './store/reducers/members';
import teamsReducer from './store/reducers/teams';
import participantsReducer from './store/reducers/participants';
import videosReducer from './store/reducers/videos';
import seasonsReducer from './store/reducers/seasons';
import memberTeamsReducer from './store/reducers/member_teams';
import teamMembersReducer from './store/reducers/team_members';
import enumsReducer from './store/reducers/enums';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    members: membersReducer,
    participants: participantsReducer,
    teams: teamsReducer,
    seasons: seasonsReducer,
    videos: videosReducer,
    member_teams: memberTeamsReducer,
    team_members: teamMembersReducer,
    enums: enumsReducer,
});

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk)
));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
registerServiceWorker();
