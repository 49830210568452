import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { authReset } from "../../store/actions/auth";
import { Route, Switch } from "react-router-dom";
import Location from "./Location/Location";
import Opponent from "./Opponent/Opponent";
import Season from "./Season/Season";
import UpdateTeam from "../Team/Update/Update";
import UpdateTeamImage from "../Team/Image/Image";
import Tooltip from "@material-ui/core/Tooltip";
import UpdateIcon from "@material-ui/icons/Update";
import { newPath } from "../../components/functions";
import SeasonsIcon from "@material-ui/icons/BeachAccess";
import InvitesIcon from "@material-ui/icons/CardGiftcard";
import MembersIcon from "@material-ui/icons/SupervisorAccount";
import ImageIcon from "@material-ui/icons/Image";
import '../../App.css';

class index extends Component {
    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    render() {
        const {team} = this.props;
        const {season} = this.props;
        const {team_id} = team ? team : {};
        const {season_id} = season ? season : {};

        let path;
        if (team_id) {
            path = `/${team_id}/${season_id}/manage/`;
        }

        this.path = path;

        let title = "Details";

        if (this.props.location.pathname.endsWith('/locations')) {
            title = "Locations";
        }

        if (this.props.location.pathname.endsWith('/opponents')) {
            title = "Opponents";
        }

        if (this.props.location.pathname.endsWith('/team-image')) {
            title = "Image";
        }

        if (this.props.location.pathname.endsWith('/seasons')) {
            title = "Seasons";
        }

        if (this.props.location.pathname.endsWith('/invites')) {
            title = "Invites";
        }

        if (this.props.location.pathname.endsWith('/members')) {
            title = "Members";
        }

        return (
            <div className={'Outer'} style={{ background: 'white' }}>
                <div style={{ display: 'flex', background: 'black', padding: 3 }}>
                    <h3 style={{flex: '1 1 auto', color: 'white', padding: '5px 5px 0 5px', margin: 0}}>
                        {this.props.team ? this.props.team.team_title : ''} - {title}
                    </h3>
                    <div>
                        <Tooltip title="Update">
                            <UpdateIcon className="PageAction" onClick={() => newPath(this.props, path)} />
                        </Tooltip>
                        <Tooltip title="Image">
                            <ImageIcon className="PageAction" onClick={() => newPath(this.props, `${path}team-image`)} />
                        </Tooltip>
                        {/* <Tooltip title="Locations">
                            <LocationsIcon className="PageAction" onClick={() => newPath(this.props, `${path}locations`)} />
                        </Tooltip> */}
                        {/* <Tooltip title="Opponents">
                            <OpponentsIcon className="PageAction" onClick={() => newPath(this.props, `${path}opponents`)} />
                        </Tooltip> */}
                        <Tooltip title="Seasons">
                            <SeasonsIcon className="PageAction" onClick={() => newPath(this.props, `${path}seasons`)} />
                        </Tooltip>
                        <Tooltip title="Invites">
                            <InvitesIcon className="PageAction" onClick={() => newPath(this.props, `${path}invites`)} />
                        </Tooltip>
                        <Tooltip title="Members">
                            <MembersIcon className="PageAction" onClick={() => newPath(this.props, `${path}members`)} />
                        </Tooltip>
                    </div>
                </div>
                <div className={'Inner'}>
                    <Switch>
                        <Route path={`${this.props.match.path}`} component={UpdateTeam} exact />
                        <Route path={`${this.props.match.path}/team-image`} component={UpdateTeamImage} />
                        <Route path={`${this.props.match.path}/locations`} component={Location} />
                        <Route path={`${this.props.match.path}/opponents`} component={Opponent} />
                        <Route path={`${this.props.match.path}/seasons`} component={Season} />
                    </Switch>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
