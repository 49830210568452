import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import BlockUsage from '../../../components/BlockUsage/BlockUsage';
import * as actions from "../../../store/actions"
import { VIDEO_KIND, VIDEO_QUALITY } from '../../../constants/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faVolumeUp} from '@fortawesome/free-solid-svg-icons/faVolumeUp'
import {faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'

class row extends Component {
    onUpload = (e) => {
        e.stopPropagation()
        this.props.openManageVideo(this.props.model)
    }

    getVideoKind(level) {
        let g = VIDEO_KIND.find(g => Number(g.id) === Number(level));
        if (!g) {
            return '';
        } else {
            return g.title;
        }
    }

    getVideoQuality(value) {
        let g = VIDEO_QUALITY.find(g => Number(g.id) === Number(value));
        if (!g) {
            return '';
        } else {
            return g.title;
        }
    }

    onView(event, row) {
        event.stopPropagation()
        event.preventDefault()

        this.props.onView(event, row)
    }

    render() {
        let row = this.props.model;

        const d = new Date(0);
        d.setMilliseconds(row.occurred_on);

        return (
            <TableRow key={`${row.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, row)}>
                <TableCell align="left">{row.video_title}</TableCell>
                <TableCell align="center">{this.getVideoKind(row.video_kind)}</TableCell>
                <TableCell align="center" style={{whiteSpace: "nowrap"}}>
                    <div>{d.toLocaleDateString()}</div>
                    <div>{d.toLocaleTimeString()}</div>
                </TableCell>
                <TableCell align="center">{row.total_files}</TableCell>
                <TableCell align="center"><BlockUsage size={row.total_size} /></TableCell>
                <TableCell align="center">{this.getVideoQuality(row.video_quality)}</TableCell>
                <TableCell align="center">{row.remove_audio ? <></> : <FontAwesomeIcon icon={faVolumeUp} />}</TableCell>
                {this.props.manage_video && <TableCell align="center"><FontAwesomeIcon onClick={this.onUpload} icon={faCloudUploadAlt} /></TableCell>}
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
        season: state.seasons.season,
        team: state.teams.team,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openManageVideo: (video) => dispatch(actions.openVideoManageAction('Video', video)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(row));
