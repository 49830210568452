import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import List from "./List/List";
import Create from "./Create/Create";
import Detail from "./Detail/Detail";

class location extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.path}/`} component={List}/>
                <Route path={`${this.props.match.path}/create`} component={Create}/>
                <Route path={`${this.props.match.path}/:location_id`} component={Detail}/>
            </Switch>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(location);
