import React, {Component} from 'react';

import './ColorPicker.css';
import {SketchPicker} from 'react-color'

class ColorPicker extends Component {
    state = {
        displayColorPicker: false,
    };

    handleClick = () => {
        if (!this.props.disabled) {
            this.setState({displayColorPicker: !this.state.displayColorPicker})
        }
    };

    handleClose = () => {
        this.setState({displayColorPicker: false})
    };

    handleChange = (color) => {
        this.setState({color: color.hex})
        setTimeout(() => {
            this.props.change(color.hex, this.props.field);
        }, 0);
    };

    render() {
        let position;
        switch (this.props.position) {
            case "bottom":
                position = {bottom: 0, left: 0};
                break;
            default:
                position = {top: 0, left: 0};
        }

        return (
            <div style={{display: "flex"}}>
                <div style={{position: "relative"}}>
                    <div className={'swatch'} onClick={this.handleClick}>
                        <div className={'color'} style={{
                            background: `${this.props.disabled ? 'pink' : this.props.value}`,
                        }}/>
                    </div>
                    {this.state.displayColorPicker ? <div className={'popover'} style={position}>
                        <div className={'cover'} onClick={this.handleClose}/>
                        <SketchPicker color={this.state.color} onChange={this.handleChange}/>
                    </div> : null}
                </div>
                <div className={'Label'}>{this.props.label}</div>
            </div>
        );
    }
}

export default ColorPicker;
