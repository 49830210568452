export const SPORT = [
    { key: "Football", id: 1, title: "Football" },
    { key: "Lacrosse", id: 2, title: "Lacrosse" },
    { key: "Wrestling", id: 3, title: "Wrestling" },
    { key: "Soccer", id: 4, title: "Soccer" },
    { key: "Baseball", id: 5, title: "Baseball" },
    { key: "Softball", id: 6, title: "Softball" },
    { key: "Swimming", id: 7, title: "Swimming" },
];

export const GRADE_LEVEL = [
    { key: "Preschool", id: "5", title: "Preschool" },
    { key: "Kindergarten", id: "10", title: "Kindergarten" },
    { key: "FirstGrade", id: "20", title: "1st Grade" },
    { key: "SecondGrade", id: "25", title: "2nd Grade" },
    { key: "ThirdGrade", id: "30", title: "3rd Grade" },
    { key: "FourthGrade", id: "35", title: "4th Grade" },
    { key: "FifthGrade", id: "40", title: "5th Grade" },
    { key: "SixthGrade", id: "45", title: "6th Grade" },
    { key: "SeventhGrade", id: "50", title: "7th Grade" },
    { key: "EighthGrade", id: "55", title: "8th Grade" },
    { key: "HSFreshman", id: "60", title: "High School - Freshman" },
    { key: "HSSophmore", id: "65", title: "High School - Sophomore" },
    { key: "HSJunior", id: "70", title: "High School - Junior" },
    { key: "HSSenior", id: "75", title: "High School - Senior" },
    { key: "CollegeFreshman1", id: "80", title: "College - Freshman (1)" },
    { key: "CollegeFreshman2", id: "85", title: "College - Freshman (2)" },
    { key: "CollegeSophmore1", id: "90", title: "College - Sophomore (1)" },
    { key: "CollegeSophmore2", id: "95", title: "College - Sophomore (2)" },
    { key: "CollegeJunior", id: "100", title: "College - Junior" },
    { key: "CollegeSenior", id: "105", title: "College - Senior" },
    { key: "CollegePostGrad", id: "110", title: "College - Post Grad" },
];

export const PARTICIPANT_RELATIONSHIP = [
    { id: 1, title: "Father", key: "Father" },
    { id: 2, title: "Mother", key: "Mother" },
    { id: 3, title: "Sibling", key: "Sibling" },
    { id: 4, title: "Grand-Father", key: "GrandFather" },
    { id: 5, title: "Grand-Mother", key: "GrandMother" },
    { id: 6, title: "Step-Dad", key: "StepDad" },
    { id: 7, title: "Step-Mom", key: "StepMom" },
    { id: 8, title: "Myself", key: "Myself" },
    { id: 100, title: "Other", key: "Other" },
];

export const VIDEO_KIND = [
    { id: 10, title: "Game", key: "Game" },
    { id: 20, title: "Practice", key: "Practice" },
    { id: 30, title: "Scout", key: "Scout" },
    { id: 40, title: "Scrimmage", key: "Scrimmage" },
    { id: 100, title: "Other", key: "Other" },
];

export const VIDEO_QUALITY = [
    { id: 10, title: "Poor", key: "Poor" },
    { id: 20, title: "Low", key: "Low" },
    { id: 30, title: "Medium", key: "Medium" },
    { id: 40, title: "Recommended", key: "Recommended" },
    { id: 50, title: "High", key: "High" },
    { id: 60, title: "Very High", key: "Very High" },
    { id: 70, title: "Lossless", key: "Lossless" },
];

export const PRODUCT_KIND = [
    { id: 1, title: "Blocks", key: "Blocks", cost: "3.0" },
];


