import React from 'react';

import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import DateInput from "../../../../components/UI/DateInput/DateInput";
import { doPut } from "../../../../axios-main";
import { withRouter } from "react-router-dom";
import { empty, getToken, getLocalDate } from "../../../../components/functions";
import "./Detail.css";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from '../../../../components/UI/FormComponent/FormComponent';
import SubmitButton from '../../../../components/UI/SubmitButton/SubmitButton';

class detail extends FormComponent {
    state = {
        loading: false,
        season_title: {
            id: 'season_title',
            label: 'Season Title',
            value: '',
            valid: true,
            focus: true,
            validate: {blank: false, max_length: 25}
        },
        season_start_date: new Date(),
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.season && this.props.season) {
            this.reset();

            const { season } = this.props;

            this.setState({
                ...this.state,
                season_title: { ...this.state.season_title, value: empty(season.season_title) },
                season_start_date: new Date(`${season.start_date[0]}-${season.start_date[1]}-${season.start_date[2]}`),
            });
        }
    }

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.season_title.valid) {
            this.setState({ ...this.state, season_title: { ...this.state.season_title, message: 'Required' } });
        } else {
            this.setLoading(true);

            doPut(this.props.token, `/seasons`, {
                season_index: this.props.season.season_index,
                season_title: this.state.season_title.value,
                season_start_date: getLocalDate(this.state.season_start_date),
            },
                () => {
                    this.reset();
                    this.setLoading(false);
                    this.props.close(null, true);
                },
                (r) => {
                    this.setLoading(false);
                    this.setError(r.response.data.message);
                });
        }
    };

    onClose = (event) => {
        this.reset();
        this.props.close(event);
    }

    render() {
        let form = (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Update a Season" close={this.onClose} />
                <Container style={{ background: 'white', padding: 20 }}>
                    <Form onSubmit={this.submitHandler}>
                        {this.getAlert()}

                        <TextInput disabled={this.state.loading} info={this.state.season_title}
                            change={(event, info) => this.onChange(event, info)} />

                        <DateInput disabled={this.state.loading} id="season_start_date" label="Season Start Date" value={this.state.season_start_date}
                            onChange={(event) => this.onChangeDate(event, 'season_start_date')} />

                        <div className={'Buttons'}>
                            <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                        </div>
                    </Form>
                </Container>
            </Dialog>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        team: state.teams.team,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(detail));
