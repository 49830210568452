import React from 'react';

const Duration = ( props ) => {
    const duration = props.duration ? Number(props.duration) : 0
    const seconds = Math.floor((duration / 1000) % 60)
    const minutes = Math.floor((duration / (1000 * 60)) % 60)
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    return (
        <>
            {hours > 0 && <>{hours}h</>}
            {minutes > 0 && <>{minutes}m</>}
            {seconds > 0 && <>{seconds}s</>}
        </>
    );
};

export default Duration;
