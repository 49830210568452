import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';

import { withRouter } from "react-router-dom";

import Summary from "./Summary";
import ChangePassword from "./ChangePassword";
import Image from "./Image";
import Invites from "./Invites";
import Payment from "./Payment/Payment";

import DialogNavigationItem from '../../components/UI/DialogNavigationItem/DialogNavigationItem'
import DialogAppBar from '../../components/UI/DialogAppBar/DialogAppBar';

class dialog extends Component {
    state = {
        view: 'summary',
    };

    signout = () => {
        this.props.closeProfile();
        this.props.history.push('/logout')
    };

    show = (e, view) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ ...this.state, view: view, anchorEl: null });
    };

    setAnchorEl = (el) => {
        this.setState({ ...this.state, anchorEl: el });
    }

    handleClick = event => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    render() {
        const { user } = this.props
        const { member } = user ? user : {}
        const { first_name, last_name, image_url } = member ? member : {}

        const name = String((first_name ? first_name : '') + ' ' + (last_name ? last_name : '')).trim()

        return (
            <Dialog fullScreen scroll={'paper'} onClose={this.props.closeProfile} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={'My Account'} section={this.state.view} close={this.props.closeProfile} name={name} image={image_url} />
                <DialogContent>
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: 15, display: 'flex', flexDirection: 'column' }}>
                            <DialogNavigationItem active={this.state.view} title="Summary" icon="summary" click={(e) => this.show(e, 'summary')} />
                            <DialogNavigationItem active={this.state.view} title="Image" icon="image" click={(e) => this.show(e, 'image')} />
                            <DialogNavigationItem active={this.state.view} title="Password" icon="password" click={(e) => this.show(e, 'password')} />
                            <DialogNavigationItem active={this.state.view} title="Invites" icon="invites" click={(e) => this.show(e, 'invites')} />
                            <DialogNavigationItem active={this.state.view} title="Payment" icon="payment" click={(e) => this.show(e, 'payment')} />
                            <DialogNavigationItem active={this.state.view} title="Sign Out" icon="signout" click={(e) => this.signout()} />
                        </div>
                        <div style={{ flex: '1 1 auto' }}>
                            {this.state.view === 'summary' && <Summary />}
                            {this.state.view === 'password' && <ChangePassword />}
                            {this.state.view === 'image' && <Image />}
                            {this.state.view === 'invites' && <Invites />}
                            {this.state.view === 'payment' && <Payment />}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        closeProfile: () => dispatch(actions.closeProfileAction()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(dialog));
