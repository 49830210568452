import React, { Component } from "react";

import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import DialogContent from "@material-ui/core/DialogContent";
import { getToken } from "../../../../components/functions";
import Button from "@material-ui/core/Button";
import Part from "./Part/Part";
import axios, { doGet, doPost, doPut } from "../../../../axios-main";
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const ColorLinearProgress = withStyles({
    root: {
        height: 10
    },
    colorPrimary: {
        backgroundColor: 'lightgrey',
    },
    barColorPrimary: {
        backgroundColor: 'green',
    },
})(LinearProgress);

class VideoFile {
    status = "Pending";
    amountUploaded = 0;
    remoteItem = null;
    errorMessage = null;

    constructor(file, addIndex) {
        this.file = file;
        this.addIndex = addIndex;
        this.nameIndex = this.extractNameIndex(this.file.name);
    }

    assignError(error) {
        error = String(error).trim;
        if (error.length <= 0) {
            error = null;
        }
        this.errorMessage = error;
        this.status = "Error";
    }

    compareTo(against) {
        const result = this.nameIndex - against.nameIndex;
        return result === 0 ? (this.addIndex - against.addIndex) : result;
    };

    extractNameIndex(name) {
        if (!name) {
            return 0;
        }

        name = name.trim();

        const pos = name.lastIndexOf(".");
        if (pos >= 0) {
            name = name.substring(0, pos).trim();
            if (name.length <= 0) {
                return 0;
            }
        }

        let lastIndex = name.length - 1;
        let finished = false;
        while (lastIndex >= 0 && !finished) {
            const ch = name.charAt(lastIndex);

            if (ch >= "0" && ch <= "9") {
                finished = true;
            } else {
                lastIndex--;
            }
        }

        if (lastIndex < 0) {
            return 0;
        }

        let firstIndex = lastIndex - 1;
        finished = false;
        while (firstIndex >= 0 && !finished) {
            const ch = name.charAt(firstIndex);

            if (ch < "0" || ch > "9") {
                finished = true;
            } else {
                firstIndex--;
            }
        }

        return +name.substring(firstIndex + 1, lastIndex + 1);
    }
}

class Upload extends Component {
    refreshTimer = null;

    refreshRunning = false;

    state = {
        maxUploads: 3,
        allFiles: [],
        bytesUploaded: 0,
        bytesError: 0,
        bytesTotal: 0,
        totalPending: 0,
        totalUploading: 0,
        totalUploaded: 0,
        totalImporting: 0,
        totalFinished: 0,
        totalError: 0
    };

    componentWillUnmount() {
        this.stopRefreshFileStatuses();
    }


    addFiles(srcFiles) {
        if (!srcFiles) {
            return;
        }

        const files = [];
        for (let fileIndex = 0; fileIndex < srcFiles.length; fileIndex++) {
            const srcFile = srcFiles[fileIndex];
            const vf = new VideoFile(srcFile, fileIndex);
            files.push(vf);
        }

        files.sort((f1, f2) => f1.compareTo(f2));

        let allFiles = this.state.allFiles;
        let bytesTotal = this.state.bytesTotal;
        let totalPending = this.state.totalPending;
        for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
            const vf = files[fileIndex];
            vf.addIndex = allFiles.length;
            allFiles.push(vf);
            bytesTotal += vf.file.size;
            totalPending++;
        }

        this.setState({ ...this.state, allFiles: allFiles, bytesTotal: bytesTotal, totalPending: totalPending });
        setTimeout(() => this.uploadNextFile(), 0);
    }

    calculateProgress() {
        const total = this.state.bytesTotal - this.state.bytesError;

        if (total <= 0) {
            return 0;
        }

        const result = this.state.bytesUploaded / total;

        return result * 100;
    }

    firstFileWithStatus(status) {
        for (let index = 0; index < this.state.allFiles.length; index++) {
            let vf = this.state.allFiles[index];
            if (vf.status === status) {
                return vf;
            }
        }
        return null;
    }

    markAsUploaded(index, remoteItem) {
        let allFiles = this.state.allFiles;
        let videoFile = allFiles[index];

        videoFile.status = "Uploaded";

        if (this.refreshTimer == null) {
            this.refreshTimer = setInterval(() => this.refreshFileStatuses(), 2000);
        }

        this.setState({
            ...this.state,
            allFiles: allFiles,
            bytesUploaded: (this.state.bytesUploaded - videoFile.amountUploaded) + videoFile.file.size,
            totalUploading: this.state.totalUploading - 1,
            totalUploaded: this.state.totalUploaded + 1
        });
        setTimeout(() => this.uploadNextFile(), 50);
    }

    onFilesSelected = (e) => {
        e.stopPropagation();
        this.addFiles(e.target.files);
    };

    performUpload(index, remoteItem) {
        let allFiles = this.state.allFiles;
        let videoFile = allFiles[index];
        videoFile.remoteItem = remoteItem;

        const options = {
            headers: {
                "Content-Type": "application/octet-stream",
            },

            onUploadProgress: (progressEvent) => {
                this.updateUploadProgress(videoFile.addIndex, progressEvent.loaded);
            },
        };

        axios
            .put(videoFile.remoteItem.upload_url, videoFile.file, options)
            .then((r) => {
                this.uploadFinished(videoFile.addIndex);
            })
            .catch(() => {
                this.uploadingFailed(videoFile.addIndex, null);
            });

        this.setState({ ...this.state, allFiles: allFiles });
    }

    refreshFileStatuses() {
        if (this.refreshRunning) {
            return;
        }

        let allFiles = this.state.allFiles;
        let importIds = [];
        let indexMap = new Map();

        for (let index = 0; index < allFiles.length; index++) {
            let videoFile = allFiles[index];
            if ((videoFile.status === "Uploaded") || (videoFile.status === "Converting") || (videoFile.status === "Importing")) {
                importIds.push(videoFile.remoteItem.video_file_import_id);
                indexMap.set(videoFile.remoteItem.video_file_import_id, videoFile.addIndex);
            }
        }

        if (importIds.length <= 0) {
            this.stopRefreshFileStatuses();
            return;
        }

        doGet(
            this.props.token,
            "/videos/files/imports?video_id=" + this.props.item.video_id + "&season_index=" + this.props.item.season_index + "&video_file_import_ids=" + importIds.join(','),
            (r) => {
                this.refreshRunning = false;
                this.updateFileStatuses(indexMap, r.data);
            },
            (err) => {
                this.refreshRunning = false;
            }
        );

        this.refreshRunning = true;
    }

    retry = (addIndex) => {
        let allFiles = this.state.allFiles
        let videoFile = allFiles[addIndex]
        videoFile.status = 'Pending'
        videoFile.amountUploaded = 0
        videoFile.remoteItem = null
        this.setState({
            ...this.state,
            allFiles: allFiles,
            totalPending: this.state.totalPending + 1,
            totalError: this.state.totalError - 1,
            bytesError: this.state.bytesError - videoFile.file.size,
        })
        setTimeout(() => this.uploadNextFile(), 0)
    };

    selectFiles = () => {
        document.getElementById("file").click();
    };

    stopRefreshFileStatuses() {
        console.log('stopRefreshFileStatuses')
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
            this.refreshTimer = null;
        }
    }

    updateFileStatuses(indexMap, remoteItems) {
        if (!remoteItems) {
            return;
        }

        let allFiles = this.state.allFiles;

        let totalUploaded = this.state.totalUploaded;
        let totalImporting = this.state.totalImporting;
        let totalFinished = this.state.totalFinished;
        let totalError = this.state.totalError;

        for (let index = 0; index < remoteItems.length; index++) {
            let remoteItem = remoteItems[index];
            if (remoteItem.status && (!isNaN(remoteItem.status))) {
                let addIndex = indexMap.get(remoteItem.video_file_import_id);
                if ((!isNaN(addIndex)) && (addIndex >= 0) && (addIndex < allFiles.length)) {
                    let videoFile = allFiles[addIndex];

                    if ((remoteItem.status > 20) && (remoteItem.status < 80)) { // Uploaded, but not ready!                        
                        if (videoFile.status === "Uploaded") {
                            totalUploaded--;
                            totalImporting++;
                        }

                        if (remoteItem.status === 25) {
                            videoFile.status = "Converting";
                        } else if (remoteItem.status === 30) {
                            videoFile.status = "Importing";
                        }
                    } else if (remoteItem.status === 80) { // Finished
                        if (videoFile.status !== "Finished") {
                            if (videoFile.status === "Uploaded") {
                                totalUploaded--;
                            } else {
                                totalImporting--;
                            }
                            totalFinished++;
                            videoFile.status = "Finished";
                        }
                    } else if (remoteItem.status >= 100) { // Error
                        if (videoFile.status !== "Error") {
                            if (videoFile.status === "Uploaded") {
                                totalUploaded--;
                            } else {
                                totalImporting--;
                            }
                            totalError++;
                            videoFile.status = "Error";
                            if (remoteItem.status_message == null) {
                                remoteItem.status_message = 'Oops! We can\'t seem to handle that file.';
                            }
                            videoFile.remoteItem = remoteItem;
                        }
                    }
                }
            }
        }

        this.setState({ ...this.state, allFiles: allFiles, totalUploaded: totalUploaded, totalImporting: totalImporting, totalFinished: totalFinished, totalError: totalError });
    }

    updateUploadProgress(index, amountUploaded) {
        let allFiles = this.state.allFiles;
        let videoFile = allFiles[index];
        let delta = amountUploaded - videoFile.amountUploaded;
        videoFile.amountUploaded = amountUploaded;
        this.setState({ ...this.state, allFiles: allFiles, bytesUploaded: this.state.bytesUploaded + delta });
    }

    uploadFinished(index) {
        let allFiles = this.state.allFiles;
        let videoFile = allFiles[index];

        videoFile.amountUploaded = videoFile.file.size;

        const start = {
            season_index: videoFile.remoteItem.season_index,
            video_file_import_id: videoFile.remoteItem.video_file_import_id,
            video_id: this.props.item.video_id,
        };

        doPut(
            this.props.token,
            "/videos/files/imports",
            start,
            (r) => {
                this.markAsUploaded(index, r.data);
            },
            (r) => {
                this.uploadingFailed(videoFile.addIndex, r.response.data.message)
            }
        );

        this.setState({ ...this.state, allFiles: allFiles });
    }

    uploadNextFile() {
        if (this.state.totalUploading >= this.state.maxUploads) {
            return false;
        }

        const videoFile = this.firstFileWithStatus("Pending");
        if (!videoFile) {
            return false;
        }

        videoFile.amountUploaded = 0;
        videoFile.errorMessage = "";
        videoFile.status = "Uploading";
        videoFile.remoteItem = null;

        const create = {
            angle_index: 0, // todo dynamic
            file_name: videoFile.file.name,
            file_size: videoFile.file.size,
            include_audio: false,
            last_modified_on: videoFile.file.lastModified,
            season_index: this.props.item.season_index,
            sort_order: videoFile.nameIndex >= 0 ? videoFile.nameIndex : videoFile.addIndex,
            video_id: this.props.item.video_id,
        };

        doPost(
            this.props.token,
            "/videos/files/imports",
            create,
            (r) => {
                this.performUpload(videoFile.addIndex, r.data);
            },
            (e) => {
                this.uploadingFailed(videoFile.addIndex, e.response.data.message);
            }
        );

        this.setState({ ...this.state, allFiles: this.state.allFiles, totalPending: this.state.totalPending - 1, totalUploading: this.state.totalUploading + 1 });
        setTimeout(() => this.uploadNextFile(), 1000);
    }

    uploadingFailed(index, error) {
        let allFiles = this.state.allFiles;
        let videoFile = allFiles[index];

        videoFile.assignError(error);

        if (videoFile.remoteItem == null) {
            videoFile.remoteItem = {};
        }

        if (!videoFile.remoteItem.status_message) {
            videoFile.remoteItem.status_message = 'Oops! The squirrels took a bite out of your internet.';
        }

        this.setState({
            ...this.state,
            allFiles: this.state.allFiles,
            bytesError: this.state.bytesError + videoFile.file.size,
            bytesUploaded: this.state.bytesUploaded - videoFile.amountUploaded,
            totalError: this.state.totalError + 1,
            totalUploading: this.state.totalUploading - 1
        });
        setTimeout(() => this.uploadNextFile(), 50);
    }

    render() {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                scroll={"paper"}
                open={this.props.open}
                onClose={this.props.close}
                disableBackdropClick
            >
                <DialogAppBar title={`Upload - ${this.props.item.video_title}`} close={this.props.close} />
                <DialogContent id="player-content">
                    <div>
                        <input
                            id="file"
                            type="file"
                            style={{ display: "none" }}
                            onChange={this.onFilesSelected}
                            multiple="multiple"
                        />
                    </div>

                    {this.state.allFiles.length > 0 &&
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: '1 1 0px' }}>
                                Uploaded: <strong>{this.state.totalUploaded + this.state.totalImporting + this.state.totalFinished}</strong> of <strong>{this.state.allFiles.length - this.state.totalError}</strong>
                            </div>

                            {this.state.totalError > 0 &&
                                <div>Errors: <strong style={{ color: 'red' }}>{this.state.totalError}</strong></div>
                            }
                        </div>
                    }

                    {this.state.allFiles.length > 0 &&
                        <div style={{ padding: "10px 0 0 0" }}><ColorLinearProgress variant="determinate" value={this.calculateProgress()} /></div>
                    }

                    <div style={{ padding: "20px 0 5px 0" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={this.selectFiles}
                        >+</Button>
                    </div>

                    <div>
                        {this.state.allFiles.map((f) => {
                            return (
                                <div key={f.addIndex} style={{ background: f.addIndex % 2 ? "#e1e1e1" : "" }}>
                                    <Part
                                        model={f}
                                        uploaded={f.amountUploaded}
                                        status={f.status}
                                        retry={(addIndex) => { this.retry(addIndex) }}
                                    />
                                </div>
                            );
                        })}
                    </div>

                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: getToken(state),
        user: state.auth.user,
        team: state.teams.team,
        season: state.seasons.season,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Upload);
