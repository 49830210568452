import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';

import { withRouter } from "react-router-dom";

import * as actions from '../../store/actions/index';

import Update from './Update/Update'
import Image from './Image/Image'
import Payment from './Payment/Payment'
import Member from "../Manage/Member/List/List";
import Invite from "../Manage/Invite/List/List";
import Location from "../Manage/Location/Location";
import Opponent from "../Manage/Opponent/Opponent";
import Season from "../Manage/Season/List/List";
import Video from "../Manage/Video/List/List";

import DialogNavigationItem from '../../components/UI/DialogNavigationItem/DialogNavigationItem'
import DialogAppBar from '../../components/UI/DialogAppBar/DialogAppBar';

import { canManageTeam, canManageVideos, canViewVideos, canViewMembers, canManageBilling } from '../../components/functions';

import { Pie } from 'react-chartjs-2';

import styles from './TeamDialog.module.css';


class TeamDialog extends Component {
    show = (e, view) => {
        e.stopPropagation();
        e.preventDefault();

        this.props.openTeam(view);
    };

    getTeamTitle(team, team_member) {
        return team_member?.alternate_team_title ? team_member.alternate_team_title : team?.team_title
    }

    getTeamTheme(team, team_member) {
        return team_member?.alternate_team_theme ? team_member.alternate_team_theme : team?.theme
    }

    toFixed = (num, places) => {
        num = num.toString()
        num = num.slice(0, (num.indexOf(".")) + (places + 1))
        return Number(num)
    }

    render() {
        const { team, team_member } = this.props
        const { image_url } = team ? team : {}

        const factor = 1000 * 1000 * 1000

        const total = team?.storage_limit / factor
        const used = (team?.used_video_storage / factor).toFixed(2)
        const available = total - used

        const data = {
            labels: ['Blocks Used', 'Blocks Available'],
            datasets: [{
                data: [used, available],
                backgroundColor: [
                    team?.theme?.primary_color,
                    team?.theme?.secondary_color,
                ],
                hoverBackgroundColor: [
                    team?.theme?.primary_color,
                    team?.theme?.secondary_color,
                ]
            }]
        }

        const options = {
            legend: {
                display: false,
            }
        }

        return (
            <Dialog fullScreen scroll={'paper'} onClose={this.props.close} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={'Team'} section={this.props.view} close={this.props.close} name={this.getTeamTitle(team, team_member)} image={image_url} theme={this.getTeamTheme(team, team_member)} />
                <DialogContent>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.Menu}>
                            <DialogNavigationItem active={this.props.view} title="Profile" icon="profile" click={(e) => this.show(e, 'Profile')} />
                            <DialogNavigationItem active={this.props.view} title="Image" icon="image" click={(e) => this.show(e, 'Image')} />
                            <DialogNavigationItem active={this.props.view} title="Season" icon="season" click={(e) => this.show(e, 'Season')} />
                            {(this.props.manage_videos || this.props.view_videos) && <DialogNavigationItem active={this.props.view} title="Video" icon="video" click={(e) => this.show(e, 'Video')} />}
                            {/* <DialogNavigationItem active={this.props.view} title="Location" icon="location" click={(e) => this.show(e, 'Location')} /> */}
                            {/* <DialogNavigationItem active={this.props.view} title="Opponent" icon="opponent" click={(e) => this.show(e, 'Opponent')} /> */}
                            {(this.props.manage_team || this.props.view_members) && <DialogNavigationItem active={this.props.view} title="Member" icon="member" click={(e) => this.show(e, 'Member')} />}
                            {(this.props.manage_team || this.props.view_members) && <DialogNavigationItem active={this.props.view} title="Invite" icon="invite" click={(e) => this.show(e, 'Invite')} />}
                            {(this.props.manage_billing || this.props.view_billing) && <DialogNavigationItem active={this.props.view} title="Payment" icon="payment" click={(e) => this.show(e, 'Payment')} />}
                        </div>
                        <div className={styles.Content}>
                            {this.props.view === 'Profile' && <Update />}
                            {this.props.view === 'Image' && <Image />}
                            {this.props.view === 'Season' && <Season />}
                            {this.props.view === 'Video' && <Video />}
                            {this.props.view === 'Location' && <Location />}
                            {this.props.view === 'Opponent' && <Opponent />}
                            {this.props.view === 'Member' && <Member />}
                            {this.props.view === 'Invite' && <Invite />}
                            {this.props.view === 'Payment' && <Payment />}
                        </div>
                        <div style={{ width: 250, paddingLeft: 5, marginLeft: 5 }}>
                            <h3>Storage</h3>
                            <Pie width={160} data={data} options={options} />

                            <div className={styles.Legend} >
                                <div className={styles.LegendRow} >
                                    <div className={styles.LegendColor}></div>
                                    <div className={styles.LegendLabel}>Total Blocks</div>
                                    <div className={styles.LegendValue}>{total}</div>
                                </div>
                                <div className={styles.LegendRow} >
                                    <div className={styles.LegendColor}><div style={{ background: team?.theme?.primary_color }}></div></div>
                                    <div className={styles.LegendLabel}>Used Blocks</div>
                                    <div className={styles.LegendValue}>{used}</div>
                                </div>
                                <div className={styles.LegendRow} >
                                    <div className={styles.LegendColor}><div style={{ background: team?.theme?.secondary_color }}></div></div>
                                    <div className={styles.LegendLabel}>Available Blocks</div>
                                    <div className={styles.LegendValue}>{available}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog >
        );
    }
}

const mapStateToProps = state => {
    return {
        team: state.teams.team,
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        team_member: state.team_members.team_member,
        view: state.teams?.open_team_menu ? state.teams?.open_team_menu : 'Profile',
        isAuthenticated: state.auth.user !== null,
        isPasswordChanged: state.auth.isPasswordChanged,
        authRedirectPath: state.auth.authRedirectPath,
        manage_team: canManageTeam(state),
        manage_billing: canManageBilling(state),
        manage_videos: canManageVideos(state),
        view_videos: canViewVideos(state),
        view_members: canViewMembers(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openTeam: (view) => dispatch(actions.openTeamAction(view, false)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TeamDialog));
