import React, {Component} from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {connect} from "react-redux";

class row extends Component {
    render() {
        let row = this.props.model;

        return (
            <TableRow key={`${row.id}`} style={{cursor: "pointer"}} onClick={(event) => this.props.onClick(event, row)}>
                <TableCell align="left">
                    {row.title}
                </TableCell>
                <TableCell align="left">
                    {row.city}
                </TableCell>
                <TableCell align="left">
                    {row.line1}
                </TableCell>
                <TableCell align="left">
                    {row.line2}
                </TableCell>
                <TableCell align="left">
                    {row.notes}
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        grade_levels: state.enums.grade_levels ? state.enums.grade_levels : [],
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(row);
