export const FETCH_HOME_START = 'FETCH_HOME_START';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAIL = 'FETCH_HOME_FAIL';

export const FETCH_MEMBER_START = 'FETCH_MEMBER_START';
export const FETCH_MEMBER_SUCCESS = 'FETCH_MEMBER_SUCCESS';
export const FETCH_MEMBER_FAIL = 'FETCH_MEMBER_FAIL';
export const LIST_MEMBER_INVITES = 'LIST_MEMBER_INVITES';

export const SELECT_VIDEO_MANAGE = 'SELECT_VIDEO_MANAGE';
export const OPEN_VIDEO_MANAGE = 'OPEN_VIDEO_MANAGE';
export const CLOSE_VIDEO_MANAGE = 'CLOSE_VIDEO_MANAGE';
export const OPEN_TEAM = 'OPEN_TEAM';
export const CLOSE_TEAM = 'CLOSE_TEAM';
export const OPEN_TEAM_CREATE = 'OPEN_TEAM_CREATE';
export const CLOSE_TEAM_CREATE = 'CLOSE_TEAM_CREATE';
export const SELECTING_TEAM = 'SELECTING_TEAM';
export const SELECT_TEAM = 'SELECT_TEAM';
export const SELECT_SEASON = 'SELECT_SEASON';
export const SELECT_MEMBER_TEAM = 'SELECT_MEMBER_TEAM';
export const SELECT_TEAM_MEMBER = 'SELECT_TEAM_MEMBER';
export const RESET_TEAM = 'RESET_TEAM';
export const UPDATE_TEAM = 'UPDATE_TEAM';
export const UPDATE_TEAM_IMAGE = 'UPDATE_TEAM_IMAGE';
export const LOAD_SQUADS = 'LOAD_SQUADS';

export const SET_VIDEO_LIST = 'SET_VIDEO_LIST';
export const SELECT_VIDEO = 'SELECT_VIDEO';
export const SELECT_VIDEO_FILE = 'SELECT_VIDEO_FILE';

export const OPEN_PROFILE = 'OPEN_PROFILE';
export const CLOSE_PROFILE = 'CLOSE_PROFILE';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_TEAM_UPDATE_SUCCESS = 'AUTH_TEAM_UPDATE_SUCCESS';
export const AUTH_QUICK_SUCCESS = 'AUTH_QUICK_SUCCESS';
export const AUTH_CHANGE_SUCCESS = 'AUTH_CHANGE_SUCCESS';
export const AUTH_VERIFY_SUCCESS = 'AUTH_VERIFY_SUCCESS';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_COOKIE_START = 'AUTH_COOKIE_START';
export const AUTH_COOKIE_SUCCESS = 'AUTH_COOKIE_SUCCESS';
export const AUTH_TOUCH_SUCCESS = 'AUTH_TOUCH_SUCCESS';
export const AUTH_TOUCH_ERROR = 'AUTH_TOUCH_ERROR';
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER';
export const AUTH_TWO_STEP = 'AUTH_TWO_STEP';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_SETUP_TWO_STEP = 'AUTH_SETUP_TWO_STEP';
export const AUTH_TWO_STEP_SETUP = 'AUTH_TWO_STEP_SETUP';
export const AUTH_TWO_STEP_CANCEL = 'AUTH_TWO_STEP_CANCEL';
export const AUTH_CHANGE_PASSWORD = 'AUTH_CHANGE_PASSWORD';
export const AUTH_CHANGED_PASSWORD = 'AUTH_CHANGED_PASSWORD';
export const AUTH_CHANGED_SUMMARY = 'AUTH_CHANGED_SUMMARY';
export const AUTH_PASSWORD_RESET = 'AUTH_PASSWORD_RESET';
export const AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD';
export const AUTH_FORM_SUCCESS = 'AUTH_FORM_SUCCESS';
export const AUTH_FORM_FAIL = 'AUTH_FORM_FAIL';
export const AUTH_COOKIE_FAIL = 'AUTH_COOKIE_FAIL';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_TEAMS = 'SET_TEAMS';
export const UPDATE_SELECTED_TEAM = 'UPDATE_SELECTED_TEAM';
export const UPDATE_TEAMS_IMAGE = 'UPDATE_TEAMS_IMAGE';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const LIST_PARTICIPANT_INVITES = 'LIST_PARTICIPANT_INVITES';
export const LIST_PARTICIPANT_START = 'LIST_PARTICIPANT_START';
export const LIST_PARTICIPANT_SUCCESS = 'LIST_PARTICIPANT_SUCCESS';
export const LIST_PARTICIPANT_FAIL = 'LIST_PARTICIPANT_FAIL';
export const CREATE_PARTICPANT = 'CREATE_PARTICPANT';

export const LOAD_VIDEO_FILE_STATUS = 'LOAD_VIDEO_FILE_STATUS';
export const LOAD_GRADE_LEVEL_SUCCESS = 'LOAD_GRADE_LEVEL_SUCCESS';
export const LOAD_GAME_KIND_SUCCESS = 'LOAD_GAME_KIND_SUCCESS';
export const LOAD_EVENT_KIND_SUCCESS = 'LOAD_EVENT_KIND_SUCCESS';
export const LOAD_VIDEO_KIND_SUCCESS = 'LOAD_VIDEO_KIND_SUCCESS';
export const LOAD_SPORT_SUCCESS = 'LOAD_SPORT_SUCCESS';
export const LOAD_RELATIONSHIP_SUCCESS = 'LOAD_RELATIONSHIP_SUCCESS';
export const LOAD_TEAM_MEMBER_SECURITY_RIGHTS_SUCCESS = 'LOAD_TEAM_MEMBER_SECURITY_RIGHTS_SUCCESS';
export const LOAD_SQUAD_SECURITY_RIGHTS_SUCCESS = 'LOAD_SQUAD_SECURITY_RIGHTS_SUCCESS';

export const LOADING_SEASONS = 'LOADING_SEASONS';
export const LOAD_SEASONS = 'LOAD_SEASONS';
export const LOAD_SEASONS_ERROR = 'LOAD_SEASONS_ERROR';

export const LOADING_TEAM_MEMBERS = 'LOADING_TEAM_MEMBERS';
export const LOAD_TEAM_MEMBERS = 'LOAD_TEAM_MEMBERS';
export const LOAD_TEAM_MEMBERS_ERROR = 'LOAD_TEAM_MEMBERS_ERROR';

export const LOADING_TEAM_INVITES = 'LOADING_TEAM_INVITES';
export const LOAD_TEAM_INVITES = 'LOAD_TEAM_INVITES';
export const LOAD_TEAM_INVITES_ERROR = 'LOAD_TEAM_INVITES_ERROR';

export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';

