export const MANAGE_BILLING = 0x000001;
export const MANAGE_TEAM = 0x000002;
export const MANAGE_PHOTOS = 0x000004;
export const MANAGE_VIDEOS = 0x000008;
export const VIEW_MEMBERS = 0x000010;
export const VIEW_VIDEOS = 0x000020;

export const member_permissions = [
    {permission: MANAGE_BILLING, label: 'MANAGE BILLING'},
    {permission: MANAGE_TEAM, label: 'MANAGE TEAM'},
    {permission: MANAGE_PHOTOS, label: 'MANAGE PHOTOS'},
    {permission: MANAGE_VIDEOS, label: 'MANAGE VIDEOS'},
    {permission: VIEW_MEMBERS, label: 'VIEW MEMBERS'},
    {permission: VIEW_VIDEOS, label: 'VIEW VIDEOS'},
];

export const ADD_AND_REMOVE_MEMBERS_FROM_SQUAD = 0x000001;
export const DELETE_SQUAD = 0x000002;
export const EDIT_SQUAD_APPEARANCE = 0x000004;
export const SEND_CHATS_TO_SQUAD = 0x000008;
export const SEND_EMAILS_TO_SQUAD = 0x000010;
export const MANAGE_EVENTS_ON_SQUAD = 0x000020;

export const squad_permissions = [
    {permission: ADD_AND_REMOVE_MEMBERS_FROM_SQUAD, label: 'ADD & REMOVE MEMBERS'},
    {permission: DELETE_SQUAD, label: 'DELETE'},
    {permission: EDIT_SQUAD_APPEARANCE, label: 'EDIT APPEARANCE'},
    {permission: SEND_CHATS_TO_SQUAD, label: 'SEND CHATS'},
    {permission: SEND_EMAILS_TO_SQUAD, label: 'SEND EMAILS'},
    {permission: MANAGE_EVENTS_ON_SQUAD, label: 'MANAGE EVENTS'},
];


