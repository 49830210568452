import React from 'react';

import { connect } from "react-redux";
import { getToken, getLocalDate } from "../../../../components/functions";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import FormComponent from "../../../../components/UI/FormComponent/FormComponent";
import SubmitButton from '../../../../components/UI/SubmitButton/SubmitButton';
import TextInput from "../../../../components/UI/TextInput/TextInput";
import DateInput from "../../../../components/UI/DateInput/DateInput";
import Container from "@material-ui/core/Container";
import { doPost } from '../../../../axios-main';
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";

class CreateSeason extends FormComponent {
    state = {
        season_title: {
            id: 'season_title',
            label: 'Season Title',
            value: '',
            valid: true,
            focus: true,
            validate: { blank: false, max_length: 25 },
        },
        season_start_date: new Date(),
        success: null,
        error: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.season && this.props.season) {
            this.reset();
            this.clear();
        }
    }

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.season_title.value.trim()) {
            this.setState({
                ...this.state,
                season_title: { ...this.state.season_title, message: 'Required' },
            });
        } else if (!this.state.season_title.valid) {
            this.setError('Please fix the form fields indicated.')
        } else {
            this.setLoading(true);

            doPost(this.props.token, '/seasons', {
                season_title: this.state.season_title.value,
                season_start_date: getLocalDate(this.state.season_start_date),
            }, () => {
                this.setSuccess('Season created.');
                this.reset();
                this.clear();
                this.props.close(null, true);
                this.setLoading(false);
            }, (e) => {
                this.setError(e.response.data.message);
                this.setLoading(false);
            });
        }
    };

    onClose = (event) => {
        this.reset();
        this.clear();
        this.props.close(event);
    }

    clear = () => {
        this.setState({
            ...this.state,
            season_title: { ...this.state.season_title, value: '', valid: true, focus: true },
            season_start_date: new Date(),
            success: null,
            error: null,
        })
    }

    render() {
        let form = (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title="Set Up a Season" close={this.onClose} />
                <Container style={{ background: 'white', padding: 20 }}>
                    <Form onSubmit={this.submitHandler}>
                        {this.getAlert()}

                        <TextInput disabled={this.state.loading} info={this.state.season_title}
                            change={(event, info) => this.onChange(event, info)} />

                        <DateInput disabled={this.state.loading} id="season_start_date" label="Season Start Date" value={this.state.season_start_date}
                            onChange={(event) => this.onChangeDate(event, 'season_start_date')} />

                        <div className={'Buttons'}>
                            <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                        </div>
                    </Form>
                </Container>
            </Dialog>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
    };
};

export default connect(mapStateToProps)(CreateSeason);
