import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';
import {authReset} from "../../../store/actions/auth";
import { getToken } from "../../../components/functions";
import Card from "../../../components/UI/Card/Card";
import Cropper from "../../../components/UI/Cropper/Cropper";
import Header from "../../../components/UI/Header/Header";

class Image extends Component {
    state = {
        file: null,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    onComplete = result => {
        // todo trigger to update images?
        // console.log(result);
    };

    render() {
        let cropper;
        if (this.props.team_member) {
            cropper = <Cropper can_update={true} url={`/squads/${this.props.squad.id}/image`} onComplete={result => {
                this.onComplete(result)
            }}/>
        }

        return (
            <Card>
                <Header>Image</Header>
                <div>
                    {cropper}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        isAuthenticated: state.auth.user !== null,
        isPasswordChanged: state.auth.isPasswordChanged,
        user: state.auth.user,
        authRedirectPath: state.auth.authRedirectPath,
        team: state.teams.team,
        team_member: state.team_members.team_member,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onChangeSummary: (user, first_name, last_name, nick_name, phone_number) => dispatch(actions.changeSummary(user, first_name, last_name, nick_name, phone_number)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Image);
