import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import { fieldSorter } from '../../components/functions';

const initialState = {
    user: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    isCode: null,
    success: null,
    type: null,
};

const authReset = (state, action) => {
    return updateObject(state, { type: action.type, loading: false, success: null, error: null, verify: null, password: null });
};

const authStart = (state, action) => {
    return updateObject(state, { type: action.type, loading: true, success: null, error: null, verify: null, password: null });
};

const authSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        teams: action.user.teams,
        session: action.user.session,
        error: null,
        loading: false
    });
};

const authTeamUpdateSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        teams: action.user.teams,
        error: null,
        loading: false
    });
};

const authVerifySuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        verify: action.verify,
        error: null,
        loading: false,
        success: null,
    });
};

const authQuickSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        quick: action.quick,
        error: null,
        loading: false,
        success: null,
    });
};

const authChangeSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        change: action.change,
        error: null,
        loading: false,
        success: null,
    });
};

const authForgotPasswordSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        password: action.password,
        error: null,
        loading: false,
        success: null,
    });
};

const authCookieStart = (state, action) => {
    return updateObject(state, { type: action.type, cookie: true, success: null, error: null });
};

const authCookieSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        error: null,
        session: action.user.session,
        cookie: false
    });
};

const authCookieFail = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: action.error,
        cookie: false
    });
};

const authTouchSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        session: action.session,
        touch: action.touch,
        touch_message: action.touch_message,
    });
};

const authTouchError = (state, action) => {
    return updateObject(state, {
        type: action.type,
        session: action.session,
        touch: action.touch,
        touch_message: action.touch_message,
    });
};

const authTwoStepCancel = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        error: null,
        session: action.user.session,
        loading: false,
        code: null,
    });
};

const authTwoStep = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        error: null,
        session: action.user.session,
        loading: false
    });
};

const authError = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: action.error,
        success: null,
        loading: false
    });
};

const authSetupTwoStep = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        success: null,
        loading: false,
        code: action.code
    });
};

const authTwoStepSetup = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        success: action.success,
        loading: false,
        code: null
    });
};

const authChangePassword = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        loading: false
    });
};

const authPasswordReset = (state, action) => {
    return updateObject(state, {
        type: action.type,
        username: action.username,
        error: null,
        loading: false
    });
};

const authChangedPassword = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        success: action.success,
        loading: false,
    });
};

const authChangedSummary = (state, action) => {
    return updateObject(state, {
        type: action.type,
        user: action.user,
        error: null,
        success: action.success,
        loading: false,
    });
};

const authForgotPassword = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        loading: false,
        isCode: true,
        success: action.success
    });
};

const authFormSuccess = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: null,
        loading: false,
        success: action.success
    });
};

const authFail = (state, action) => {
    return updateObject(state, {
        type: action.type,
        error: action.error,
        loading: false,
        success: null,
    });
};

const openProfileReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        profile: true
    });
};

const closeProfileReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        profile: false
    });
};

const setTeams = (state, action) => {
    const { user } = state ? state : {};

    user.teams = action.teams.sort(fieldSorter(['-last_entered_on', 'team_title', 'team_id']));

    return updateObject(state, {
        type: action.type,
        user: user,
        teams: user.teams
    });
};

const updateTeamsImage = (state, action) => {
    const { user } = state ? state : {};

    let teams = user.teams;

    let i = teams.findIndex(t => t.team_id === action.team.team_id);

    if (i >= 0) {
        teams[i] = {...teams[i], team_image_url: action.image.small}
    }

    teams = teams.sort(fieldSorter(['-last_entered_on', 'team_title', 'team_id']))

    user.teams = teams

    return updateObject(state, {
        type: action.type,
        user: {...user},
        teams: {...teams}
    });
}

const updateSelectedTeam = (state, action) => {
    const { user } = state ? state : {};

    let teams = user.teams;

    let i = teams.findIndex(t => t.team_id === action.team.team_id);

    if (i >= 0) {
        teams[i] = {...teams[i], last_entered_on: new Date().getTime()}
    }

    teams = teams.sort(fieldSorter(['-last_entered_on', 'team_title', 'team_id']))

    user.teams = teams

    return updateObject(state, {
        type: action.type,
        user: {...user},
        teams: {...teams}
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { type: action.type, user: null });
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { type: action.type, authRedirectPath: action.path })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_PROFILE:
            return openProfileReducer(state, action);
        case actionTypes.CLOSE_PROFILE:
            return closeProfileReducer(state, action);
        case actionTypes.AUTH_RESET:
            return authReset(state, action);
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_COOKIE_START:
            return authCookieStart(state, action);
        case actionTypes.AUTH_COOKIE_SUCCESS:
            return authCookieSuccess(state, action);
        case actionTypes.AUTH_COOKIE_FAIL:
            return authCookieFail(state, action);
        case actionTypes.AUTH_SETUP_TWO_STEP:
            return authSetupTwoStep(state, action);
        case actionTypes.AUTH_TWO_STEP_SETUP:
            return authTwoStepSetup(state, action);
        case actionTypes.AUTH_CHANGE_PASSWORD:
            return authChangePassword(state, action);
        case actionTypes.AUTH_CHANGED_PASSWORD:
            return authChangedPassword(state, action);
        case actionTypes.AUTH_CHANGED_SUMMARY:
            return authChangedSummary(state, action);
        case actionTypes.AUTH_PASSWORD_RESET:
            return authPasswordReset(state, action);
        case actionTypes.AUTH_FORGOT_PASSWORD:
            return authForgotPassword(state, action);
        case actionTypes.AUTH_FORM_SUCCESS:
            return authFormSuccess(state, action);
        case actionTypes.AUTH_FORM_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_TEAM_UPDATE_SUCCESS:
            return authTeamUpdateSuccess(state, action);
        case actionTypes.AUTH_VERIFY_SUCCESS:
            return authVerifySuccess(state, action);
        case actionTypes.AUTH_QUICK_SUCCESS:
            return authQuickSuccess(state, action);
        case actionTypes.AUTH_CHANGE_SUCCESS:
            return authChangeSuccess(state, action);
        case actionTypes.AUTH_FORGOT_PASSWORD_SUCCESS:
            return authForgotPasswordSuccess(state, action);
        case actionTypes.AUTH_TOUCH_SUCCESS:
            return authTouchSuccess(state, action);
        case actionTypes.AUTH_TOUCH_ERROR:
            return authTouchError(state, action);
        case actionTypes.AUTH_TWO_STEP_CANCEL:
            return authTwoStepCancel(state, action);
        case actionTypes.AUTH_TWO_STEP:
            return authTwoStep(state, action);
        case actionTypes.AUTH_ERROR:
            return authError(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action);
        case actionTypes.SET_TEAMS:
            return setTeams(state, action);
        case actionTypes.UPDATE_SELECTED_TEAM:
            return updateSelectedTeam(state, action);
        case actionTypes.UPDATE_TEAMS_IMAGE:
            return updateTeamsImage(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        default:
            return state;
    }
};

export default reducer;
