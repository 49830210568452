import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/UI/Spinner/Spinner';
import Error from '../../components/UI/Alert/Error';
import Success from '../../components/UI/Alert/Success';
import * as actions from '../../store/actions/index';
import logo from "../../assets/images/logo.svg";
import { authReset } from "../../store/actions/auth";
import * as actionTypes from "../../store/actions/actionTypes";
import SignInForm from "./SignInForm";
import SignInReset from "./SignInReset";
import Card from '../../components/UI/Card/Card';
import AuthTabs from './AuthTabs';

class SignIn extends Component {
    state = {
        value: 0
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    goToSignUp = () => {
        this.props.onSwitch();
        this.props.history.push('/sign-up');
    }

    handleChange = (event, value) => {
        event.stopPropagation()
        if (value === 1) {
            this.goToSignUp();
        }
    };

    render() {
        let form = <SignInForm />;

        if (this.props.type === actionTypes.AUTH_PASSWORD_RESET) {
            form = <SignInReset />
        }

        let spinner;
        if (this.props.loading) {
            spinner = <Spinner delay={250} />
        }

        let alert = null;

        if (this.props.error) {
            alert = (
                <div style={{ marginTop: 10 }}><Error>{this.props.error}</Error></div>
            );
        }

        if (this.props.success) {
            alert = (
                <div style={{ marginTop: 10 }}><Success>{this.props.success}</Success></div>
            );
        }

        return (
            <Card>
                <div><img src={logo} alt="SportsHub" width={'50%'} /></div>
                <AuthTabs value={this.state.value} change={this.handleChange} />
                <div>
                    {alert}
                    {spinner}
                    {form}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        success: state.auth.success,
        isSignedUp: state.auth.isSignedUp,
        isAuthenticated: state.auth.user !== null,
        authRedirectPath: state.auth.authRedirectPath,
        type: state.auth.type
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSwitch: () => dispatch(authReset()),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
