import React, { Component } from 'react';
import { connect } from 'react-redux';
import Error from '../../components/UI/Alert/Error';
import Spinner from '../../components/UI/Spinner/Spinner';
import logo from "../../assets/images/logo.svg";
import Card from "../../components/UI/Card/Card";
import { authSuccess } from '../../store/actions/auth';
import { Redirect } from 'react-router-dom';
import * as actions from '../../store/actions/index';

class Verify extends Component {
    state = {
        error: '',
    };

    componentDidMount() {
        this.props.onVerify(this.props.match.params.token);
    }

    render() {
        let form;

        if (this.props.loading) {
            form = <Spinner />
        }

        let alert = null;

        if (this.props.verify) {
            this.props.onUser(this.props.verify);
            form = <Redirect to="/"/>
        }

        if (this.props.error) {
            alert = (
                <Error>{this.props.error}</Error>
            );
        }

        return (
            <Card>
                <div><img src={logo} alt="SportsHub.io" width={'50%'} /></div>
                {alert}
                {form}
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        verify: state.auth.verify,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onVerify: (token) => dispatch(actions.verify(token)),
        onUser: (user) => dispatch(authSuccess(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Verify);
