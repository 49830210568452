import React from 'react';

const form = (props) => {
    const keyHandler = (e) => {
        if (e.charCode === 13) {
            props.onSubmit(e);
        }
    };

    return (
        <form noValidate autoComplete="off" onSubmit={props.onSubmit}
              onKeyPress={keyHandler}>{props.children}</form>
    );
};

export default form;
