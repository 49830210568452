import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    video: null,
    video_file: null,
    loading: false
};

const setVideoListReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        videos: action.videos,
        loading: action.loading,
    });
};

const selectVideoReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        video: action.video,
        loading: action.loading,
    });
};

const selectVideoFileReducer = (state, action) => {
    return updateObject(state, {
        type: action.type,
        video_file: action.video_file,
        loading: action.loading,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_VIDEO_LIST:
            return setVideoListReducer(state, action);
        case actionTypes.SELECT_VIDEO:
            return selectVideoReducer(state, action);
        case actionTypes.SELECT_VIDEO_FILE:
            return selectVideoFileReducer(state, action);
        default:
            return state;
    }
};

export default reducer;
