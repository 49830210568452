import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import { getToken } from "../../../components/functions";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Header from "../../../components/UI/Header/Header";

import { withRouter } from "react-router-dom";
import Success from "../../../components/UI/Alert/Success";
import Error from "../../../components/UI/Alert/Error";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import { doPut } from "../../../axios-main";
import Card from "../../../components/UI/Card/Card";
import "../../../components/UI/Styles.module.css";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";

class create extends Component {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        background_color: '#606060',
        text_color: '#CC0000',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (Number(this.props.squad) && (this.props.squad !== prevProps.squad)) {
            const squad = this.props.squads.find(s => s.id === Number(this.props.squad));
            if (squad) {
                this.setState({ ...this.state, background_color: '#ffffff', text_color: '#000000' })
            }

            this.loadSquad(this.props.squads, this.props.squad);
        }
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        let i = setInterval(() => {
            if (this.props.squads) {
                this.loadSquad(this.props.squads, this.props.squad);
                clearInterval(i);
            }
        }, 0);
    }

    loadSquad = (squads, squad) => {
        if (squads && squad) {
            const s = squads.find(s => s.id === Number(squad));
            if (s) {
                this.setState({
                    ...this.state,
                    title: { ...this.state.title, value: s.title },
                    background_color: s.background_color,
                    text_color: s.text_color
                })
            }
        }
    };

    onChangeColor = (value, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: value });
    };

    onChange = (event, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    set_success = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'success': squad });
    };

    set_error = (squad) => {
        this.reset();
        this.setState({ ...this.state, 'error': squad });
    };

    reset = () => {
        this.setState({ ...this.state, 'error': null });
        this.setState({ ...this.state, 'success': null });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.reset();

        const state = this.state;

        doPut(this.props.token, `/squads/${this.props.squad}/update-appearance`, {
            title: state.title.value,
            background_color: state.background_color,
            text_color: state.text_color,
        }, () => {
            this.set_success("Squad updated.");
            this.props.loadSquads(this.props.token);
        }, (r) => {
            console.log('ERROR', r);
            this.set_error(r.response.data.squad);
        });
    };

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let form = (
            <Card style={{ margin: 10, padding: 10 }}>
                <Header> UPDATE A SQUAD </Header>

                {error}
                {success}

                <Form onSubmit={this.submitHandler}>
                    <TextInput info={this.state.title}
                        change={(event, info) => this.onChange(event, info)} />

                    <ColorPicker label="Primary Color" value={this.state.background_color} field="background_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />
                    <ColorPicker label="Secondary Color" value={this.state.text_color} field="text_color"
                        change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />

                    <div className={'Buttons'}>
                        <Button variant="contained" color="primary" type="submit"> UPDATE </Button>
                    </div>
                </Form>
            </Card>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        squads: state.teams.squads,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        getSquads: (session) => dispatch(actions.getSquads(session)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
