import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import List from "../Opponent/List/List";
import Create from "../Opponent/Create/Create";
import Detail from "../Opponent/Detail/Detail";

class opponent extends Component {
    render() {
        return (
            <Switch>
                <Route exact path={`${this.props.match.path}/`} component={List}/>
                <Route path={`${this.props.match.path}/create`} component={Create}/>
                <Route path={`${this.props.match.path}/:opponent_id`} component={Detail}/>
            </Switch>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(opponent);
