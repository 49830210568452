export const state = {
    loading: false,
    title: {
        id: 'title',
        label: 'Title',
        value: ' ',
        valid: true,
        focus: true,
        validate: { blank: false, max_length: 100 }
    },
    coach_name: {
        id: 'coach_name',
        label: 'Coach Name',
        value: ' ',
        valid: false,
        validate: { max_length: 100 }
    },
    coach_email: {
        id: 'coach_email',
        label: 'Coach Email',
        value: '',
        valid: false,
        validate: { email: true, max_length: 200 }
    },
    coach_phone_number: {
        id: 'coach_phone_number',
        label: 'Coach Phone Number',
        value: ' ',
        valid: false,
        validate: { max_length: 50 }
    },
    mascot: {
        id: 'mascot',
        label: 'Mascot',
        value: '',
        valid: true,
        validate: { blank: false, max_length: 50 }
    },
    notes: {
        id: 'notes',
        label: 'Notes',
        value: '',
        valid: false,
        validate: { max_length: (1024 * 128) }
    },
    background_color: '#606060',
    text_color: '#CC0000',
};