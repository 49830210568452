import React from 'react';
import { connect } from 'react-redux';

import Button from "@material-ui/core/Button";
import * as actions from '../../store/actions/index';
import Form from "../../components/UI/Form/Form";
import TextInput from "../../components/UI/TextInput/TextInput";
import { authStart, authSuccess, authFormSuccess, authFail } from '../../store/actions/auth';
import { doPost, doDelete } from '../../axios-main';
import SelectPanel from "../../components/UI/SelectPanel/SelectPanel";
import CheckPanel from "../../components/UI/CheckPanel/CheckPanel";
import FormComponent from '../../components/UI/FormComponent/FormComponent';

class SignUpForm extends FormComponent {
    state = {
        username: {
            id: 'username',
            label: 'Username',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 25, username: true }
        },
        email: {
            id: 'email',
            label: 'Email Address',
            value: '',
            valid: false,
            validate: { blank: false, max_length: 200, email: true }
        },
        first_name: {
            id: 'first_name',
            label: 'First Name',
            value: '',
            valid: false,
            validate: { max_length: 100 }
        },
        last_name: {
            id: 'last_name',
            label: 'Last Name',
            value: '',
            valid: false,
            validate: { max_length: 100 }
        },
        password: {
            id: 'password',
            label: 'Password',
            type: 'password',
            valid: false,
            value: '',
            validate: { blank: false, min_length: 6, max_length: 100 }
        },
        error: '',
        remember_me: false,
        hide_contact_info: false,
        relationship: '',
        relationship_other: {
            id: 'relationship_other',
            label: 'Other',
            value: '',
            valid: true,
            validate: {}
        },
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        if (this.props.invite.email_address) {
            this.setState({...this.state, email: {...this.state.email, valid: true, value: this.props.invite.email_address}})
            setTimeout(() => {
                this.checkUsername(this.props.invite.email_address.split('@')[0])
            }, 0)
        }
    }

    onDecline = (event) => {
        doDelete('', '/members/invites/participants?token=' + decodeURIComponent(this.props.match.params.token), 
        () => {
            this.setSuccess('Invite declined.')
        },
        () => {
            this.setError('Unable to decline invite.')
        })
    }

    onAccept = (event) => {
        event.preventDefault();

        this.setState({
            ...this.state,
            loading: true,
            username: { ...this.state.username, message: '' },
            password: { ...this.state.password, message: '' },
            email: { ...this.state.email, message: '' },
            first_name: { ...this.state.first_name, message: '' },
            last_name: { ...this.state.last_name, message: '' },
        });

        if (!this.state.username.valid) {
            this.setState({ ...this.state, username: { ...this.state.username, message: 'Required' } });
        } else if (this.state.email.value && !this.state.email.valid) {
            this.setState({ ...this.state, email: { ...this.state.email, message: 'Required' } });
        } else if (!this.state.password.valid) {
            this.setState({ ...this.state, password: { ...this.state.password, message: 'Required' } });
        } else if (!this.state.first_name.value.trim() && !this.state.last_name.value.trim()) {
            this.setState({
                ...this.state,
                first_name: { ...this.state.first_name, message: 'First and / or last name is required.' }
            });
        } else if ((this.state.first_name.value.trim().length + this.state.last_name.value.trim().length) > 100) {
            this.setState({
                ...this.state,
                first_name: { ...this.state.first_name, message: 'First and last name together is too long.' }
            });
        } else {
            this.props.authStart();

            let email

            if (this.props.invite.email_address.toLowerCase() !== this.state.email.value.toLowerCase()) {
                email = this.state.email.value;
            }

            doPost('', '/members/invites/participants', {
                user_name: this.state.username.value.trim(),
                password: this.state.password.value,
                first_name: this.state.first_name.value,
                last_name: this.state.last_name.value,
                relationship: this.state.relationship,
                relationship_other: this.state.relationship_other.value,
                invite_token: decodeURIComponent(this.props.token),
                hide_contact_info: this.state.hide_contact_info,
                override_email_address: email,
                remember_me: this.state.remember_me,
            }, (r) => {
                if (this.state.remember_me && r.data.cookie) {
                    localStorage.setItem('sign_in_store', r.data.cookie)
                }

                if (r.data.session) {
                    this.props.authSuccess(r.data);

                    this.props.history.push('/')
                } else {
                    this.props.authFormSuccess('An email has been sent to verify your email. Please follow the link to complete the setup.');
                }
            }, (r) => {
                this.props.authFail(r);
                console.log('ERROR 3', r);
            });
        }
    };

    onRememberMe = name => event => {
        this.setState({ ...this.state, [name]: event.target.checked });
    };

    render() {
        const relationships = this.props.relationships ? this.props.relationships : []

        return (
            <div>
                <Form onSubmit={this.onAccept}>
                
                    {this.getAlert()}

                    <TextInput disabled={this.props.loading} info={this.state.email}
                        change={(event, info) => this.onChange(event, info)} blur={() => this.onBlurEmail()}/>
                    <TextInput disabled={this.props.loading} info={this.state.username}
                        change={(event, info) => this.onChange(event, info)} />
                    <TextInput disabled={this.props.loading} info={this.state.password}
                        change={(event, info) => this.onChange(event, info)} />
                    <TextInput disabled={this.props.loading} info={this.state.first_name}
                        change={(event, info) => this.onChange(event, info)} />
                    <TextInput disabled={this.props.loading} info={this.state.last_name}
                        change={(event, info) => this.onChange(event, info)} />

                    <SelectPanel label={'Relationship'} value={this.state.relationship}
                        items={relationships}
                        change={(event) => this.onChangeField(event, 'relationship')} />
                    {100 === this.state.relationship && <TextInput info={this.state.relationship_other} change={(event, info) => this.onChange(event, info)} />}

                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('remember_me')} label='Remember Me' checked={this.state.remember_me} />
                    </div>
                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('hide_contact_info')} label='Hide Contact Info' checked={this.state.hide_contact_info} />
                    </div>

                    <Button style={{ marginRight: 2 }} disabled={this.props.loading} variant="contained" color="secondary"
                        onClick={this.onDecline}> DECLINE </Button>
                    <Button style={{ marginLeft: 2 }} disabled={this.props.loading} variant="contained" color="primary"
                        onClick={this.onAccept}> ACCEPT </Button>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        isAuthenticated: state.auth.user !== null,
        isCode: state.auth.isCode,
        authRedirectPath: state.auth.authRedirectPath,
        relationships: state.enums.relationships,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        authStart: () => dispatch(authStart()),
        authSuccess: (d) => dispatch(authSuccess(d)),
        authFormSuccess: (msg) => dispatch(authFormSuccess(msg)),
        authFail: (r) => dispatch(authFail(r)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
