import React, { Component } from 'react'
import { connect } from "react-redux"
import Spinner from "../../../../components/UI/Spinner/Spinner"
import VideoFile from './VideoFile/VideoFile'
import * as actions from "../../../../store/actions"
import { getToken, clearPath } from "../../../../components/functions"

class view extends Component {
    state = {
        video_file: null,
    }

    next = (e) => {
        if (e) {
            e.stopPropagation()
        }

        const vfi = this.props.video.files.findIndex(vf => Number(vf.video_file_index) === Number(this.props.match.params.video_file_id))

        let vf = this.props.video.files[vfi + 1]

        if (!vf) {
            vf = this.props.video.files[0]
        }

        clearPath(this.props, `${this.props.team.team_id}/${this.props.season.season_index}/videos/${this.props.video.video_id}/${vf.video_file_index}`)
    }

    previous = (e) => {
        e.stopPropagation()

        const vfi = this.props.video.files.findIndex(vf => Number(vf.video_file_index) === Number(this.props.match.params.video_file_id))

        let vf = this.props.video.files[vfi - 1]

        if (!vf) {
            vf = this.props.video.files[this.props.video.files.length - 1]
        }

        clearPath(this.props, `${this.props.team.team_id}/${this.props.season.season_index}/videos/${this.props.video.video_id}/${vf.video_file_index}`)
    }

    selected = (index) => {
        let vf = this.props.video.files[index - 1]

        if (!vf) {
            vf = this.props.video.files[this.props.video.files.length - 1]
        }

        clearPath(this.props, `${this.props.team.team_id}/${this.props.season.season_index}/videos/${this.props.video.video_id}/${vf.video_file_index}`)
    }

    // goTo = (video_file_index) => {
    //     const vf = this.props.video.files.find(vf => vf.video_file_index === video_file_index)

    //     if (vf) {
    //         this.props.selectVideoFile(vf)
    //     }
    // }

    render() {
        let form = <VideoFile previous={this.previous} next={this.next} selected={this.selected} total={this.props.video ? this.props.video.files.length : 0} />
        if (!this.props.video) {
            form = <Spinner />
        }

        // let nav
        // if (this.props.video && this.props.video.files) {
        //     nav = this.props.video.files.map((f, i) => {
        //         return (
        //             <SideNavItem key={f.video_file_index}
        //                 url={`/${this.props.team.team_id}/${this.props.season.season_index}/videos/${this.props.video.video_id}/${f.video_file_index}`}>{i + 1}</SideNavItem>
        //         )
        //     }
        //     )
        // }

        return (
            <div style={{ display: 'flex', justifyContent: 'center', justifyItems: 'center', height: '100vh' }}>
                <div style={{ flex: '1 1 0px' }}>
                    {form}
                </div>
                {/* <div style={{ overflowX: 'hidden' }}>
                    {nav}
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
        user: state.auth.user,
        team: state.teams.team,
        season: state.seasons.season,
        video: state.videos.video,
        video_file: state.videos.video_file,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectVideoFile: (video_file) => dispatch(actions.selectVideoFile(video_file)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(view)
