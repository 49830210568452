import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ImageComponent from "../../../components/UI/ImageComponent/index";

import * as actions from '../../../store/actions/index';

import './Team.css';
import { NavLink } from "react-router-dom";
import { getToken } from '../../../components/functions';
import { connect } from 'react-redux';
import { doGet } from '../../../axios-main';

class Team extends Component {
    state = {relationships: []}

    loadRelationship = () => {
        const team = this.props.teams.find(t => t.team_id === this.props.team.team_id)

        if (!team.relationships) {
            doGet(this.props.token, '/members/participants?season_index=' + this.props.team.last_entered_season_index + '&team_id=' + this.props.team.team_id,
                (s) => {
                    const {teams} = this.props

                    const i = teams.findIndex(t => t.team_id === this.props.team.team_id)

                    teams[i].relationships = s.data

                    this.props.setTeams(teams)

                    this.setState({relationships: s.data})
                },
                () => {
                    const {teams} = this.props

                    const i = teams.findIndex(t => t.team_id === this.props.team.team_id)

                    teams[i].relationships = []

                    this.props.setTeams(teams)

                    this.setState({relationships: []})
                })
        } else {
            this.setState({relationships: this.props.team.relationships})
        }
    }

    onSelectTeam = () => {
        const team = this.props.team;

        return '/' + team.team_id + '/' + team.last_entered_season_index + '/roster';
    };

    render() {
        const { team, active } = this.props;

        let c;
        if (active && team && active.team_id === team.team_id) {
            c = 'Active';
        }

        return (
            <NavLink onMouseOver={() => this.loadRelationship()} to={this.onSelectTeam()} className={['Nav', c].join(' ')} activeClassName="Active" onClick={() => this.props.click(team)}>
                <Tooltip style={{ marginBottom: 5 }} placement="right" arrow title={
                    <>
                        <div style={{ fontSize: '0.8rem', padding: '5px 0'}}>{team.alternate_team_title ? team.alternate_team_title : team.team_title}</div>
                        {(this.state.relationships && this.state.relationships.length > 0) &&
                            <div style={{ borderTop: '1px solid grey', marginBottom: '5px 0' }} >
                                {this.state.relationships.map(relationship => {
                                    return <div key={relationship.participant_index} style={{ display: 'flex', alignItems: 'center', marginTop: 5 }} >
                                        <ImageComponent size={24} theme={team.alternate_team_theme ? team.alternate_team_theme : team.theme} first={relationship.first_name} last={relationship.last_name} image_url={relationship.image_url} />
                                        <div style={{ marginLeft: 5 }}>{relationship.first_name} {relationship.last_name}</div>
                                    </div>
                                })}
                            </div>
                        }
                    </>
                }>
                    <div className={'Team'}>
                        <ImageComponent theme={team.alternate_team_theme ? team.alternate_team_theme : team.theme} first={team.alternate_team_title ? team.alternate_team_title : team.team_title} image_url={team.team_image_url} />
                    </div>
                </Tooltip>
            </NavLink>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        teams: state.auth.user?.teams,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTeams: (teams) => dispatch(actions.setTeams(teams)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Team);
