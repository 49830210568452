import React from 'react';

import Alert from '../Alert/Alert';

const error = (props) => {
    return (
        <Alert alertType={'Red'}>
            {props.children}
        </Alert>
    );
};

export default error;
