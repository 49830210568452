import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Update from "../Update/Update";
import Image from "../Image/Image";
import Invites from "../Invites/Invites";
import Family from "../Invites/Family";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { getToken, canManageTeam } from "../../../components/functions";

import DialogNavigationItem from '../../../components/UI/DialogNavigationItem/DialogNavigationItem'
import DialogAppBar from '../../../components/UI/DialogAppBar/DialogAppBar';
import { doGet } from '../../../axios-main';

class edit extends Component {
    state = {
        display: 'Summary',
        item: {},
        participant: null,
        team_member_relationship: null,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.participant?.participant_index !== this.props.participant?.participant_index) {
            if (this.props.participant?.participant_index >= 0) {
                this.onLoad()
            }
        }
    }

    onLoad = () => {
        doGet(this.props.token, `/participants/${this.props.participant.season_index}/${this.props.participant.level_index}/${this.props.participant.participant_index}?include_invites=true&include_relationships=true`,
            (s) => {
                this.setState({
                    ...this.state,
                    loading: false,
                    participant: s.data,
                    team_member_relationship: s.data.relationships.find(r => r.member_id === this.props.team_member?.member_id)
                })
            }, (r) => {
                console.log('ERROR', r);
                this.setState({ loading: false })
            });
    };

    onChange = (event, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onCreated = () => {
        this.onLoad()
        this.setState({ ...this.state, display: 'Invites' });
    };

    onClose = (event, load) => {
        this.props.close(event, load);
        this.setState({ ...this.state, display: 'Summary' });
    };

    onDisplay = (e, display) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ ...this.state, display: display, anchorEl: null });
    };

    handleClick = event => {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    setAnchorEl = (el) => {
        this.setState({ ...this.state, anchorEl: el });
    }

    refresh = (participant) => {
        this.setState({ ...this.state, participant: participant });
    }

    render() {
        const { team, team_member } = this.props

        const { theme } = team ? team : {}
        const { alternate_team_theme } = team_member ? team_member : {}

        const { participant } = this.state.participant ? this.state : this.props
        const { first_name, last_name, image_url } = participant ? participant : {}

        let name = ''
        if (first_name) {
            name = name + first_name
        }

        if (last_name) {
            name = name + ' ' + last_name
        }

        return (
            <Dialog fullScreen scroll={'paper'} open={this.props.open} onClose={this.onClose} disableBackdropClick>
                <DialogAppBar title={'Update Player'} section={this.state.display} close={this.onClose} name={name.trim()} image={image_url} theme={alternate_team_theme ? alternate_team_theme : theme} />

                <DialogContent id="player-content">
                    <div style={{ display: 'flex' }}>
                        <div style={{ paddingRight: 15, display: 'flex', flexDirection: 'column' }}>
                            <DialogNavigationItem active={this.state.display} title="Summary" icon="summary" click={(e) => this.onDisplay(e, 'Summary')} />
                            <DialogNavigationItem active={this.state.display} title="Image" icon="image" click={(e) => this.onDisplay(e, 'Image')} />
                            <DialogNavigationItem active={this.state.display} title="Family" icon="family" click={(e) => this.onDisplay(e, 'Family')} />
                            <DialogNavigationItem active={this.state.display} title="Invites" icon="invites" click={(e) => this.onDisplay(e, 'Invites')} />
                        </div>
                        <div style={{ flex: '1 1 auto' }}>
                            {this.state.display === 'Image' &&
                                <Image participant={participant} team_member_relationship={this.state.team_member_relationship} manage_team={this.props.manage_team} />}
                            {this.state.display === 'Family' &&
                                <Family participant={participant} team_member_relationship={this.state.team_member_relationship} manage_team={this.props.manage_team} created={() => this.onCreated()} />}
                            {this.state.display === 'Invites' &&
                                <Invites participant={participant} team_member_relationship={this.state.team_member_relationship} manage_team={this.props.manage_team} created={() => this.onCreated()} reload={this.onLoad}/>}
                            {this.state.display === 'Summary' &&
                                <Update participant={participant} team_member_relationship={this.state.team_member_relationship} refresh={(participant) => this.refresh(participant)} manage_team={this.props.manage_team} />}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        team: state.teams.team,
        team_member: state.team_members.team_member,
        season: state.seasons.season,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(edit));
