import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    members: [],
    loading: false
};

const fetchMemberStart = (state, action) => {
    return updateObject(state, {loading: true});
};

const listMemberInvites = (state, action) => {
    return updateObject(state, {
        invites: action.invites,
        loading: false
    });
};

const fetchMemberSuccess = (state, action) => {
    return updateObject(state, {
        teams: action.teams,
        loading: false
    });
};

const fetchMemberFail = (state, action) => {
    return updateObject(state, {loading: false});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LIST_MEMBER_INVITES:
            return listMemberInvites(state, action);
        case actionTypes.FETCH_MEMBER_START:
            return fetchMemberStart(state, action);
        case actionTypes.FETCH_MEMBER_SUCCESS:
            return fetchMemberSuccess(state, action);
        case actionTypes.FETCH_MEMBER_FAIL:
            return fetchMemberFail(state, action);
        default:
            return state;
    }
};

export default reducer;
