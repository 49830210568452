import React, { Component } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";

class row extends Component {
    render() {
        let row = this.props.model;

        return (
            <TableRow key={`${row.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, row)}>
                <TableCell align="left">
                    <img alt={`${row.title}`} width="50px" src={row.image_url} className="Circle" />
                </TableCell>
                <TableCell align="left">
                    {row.title}
                </TableCell>
                <TableCell align="left">
                    {row.coach_name}
                </TableCell>
                <TableCell align="left">
                    {row.mascot}
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        grade_levels: state.enums.grade_levels ? state.enums.grade_levels : [],
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(row);
