import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    teams: [],
    loading: false
};

const fetchHomeStart = (state, action) => {
    return updateObject(state, {loading: true});
};

const fetchHomeSuccess = (state, action) => {
    return updateObject(state, {
        teams: action.teams,
        loading: false
    });
};

const fetchHomeFail = (state, action) => {
    return updateObject(state, {loading: false});
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_HOME_START:
            return fetchHomeStart(state, action);
        case actionTypes.FETCH_HOME_SUCCESS:
            return fetchHomeSuccess(state, action);
        case actionTypes.FETCH_HOME_FAIL:
            return fetchHomeFail(state, action);
        default:
            return state;
    }
};

export default reducer;
