import React from 'react';

import { connect } from "react-redux";
import FormComponent from "../../../../components/UI/FormComponent/FormComponent";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import { doPost } from "../../../../axios-main";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import DateTimeInput from "../../../../components/UI/DateTimeInput/DateTimeInput";
import { withRouter } from "react-router-dom";
import SelectPanel from "../../../../components/UI/SelectPanel/SelectPanel";
import { getToken } from "../../../../components/functions";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogAppBar from '../../../../components/UI/DialogAppBar/DialogAppBar';
import CheckPanel from "../../../../components/UI/CheckPanel/CheckPanel";
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import SubmitButton from '../../../../components/UI/SubmitButton/SubmitButton';

class create extends FormComponent {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 61 }
        },
        occurred_on: new Date(),
        video_kind: 10,
        remove_audio: false,
        quality: 40,
        marks: [
            { value: 10, label: "Poor" },
            { value: 20, label: "Low" },
            { value: 30, label: "Medium" },
            { value: 40, label: "Recommended" },
            { value: 50, label: "High" },
            { value: 60, label: "Very High" },
            { value: 70, label: "Lossless" },
        ],
    };

    onClose = (e) => {
        this.reset();
        this.onClear();
        this.props.close();
    }

    onClear = (e) => {
        this.setState({
            ...this.state,
            title: { ...this.state.title, value: '', valid: false, focus: true },
            occurred_on: new Date(),
            video_kind: 10,
            remove_audio: false,
            quality: 40,
        })
    }

    onChangeQuality = (e, quality) => {
        this.setState({ ...this.state, quality: quality })
    }

    submitHandler = (event) => {
        event.preventDefault();

        const pattern = /^[a-zA-Z0-9._$!() -]{1,60}$/

        if (!this.state.title.valid || !pattern.test(this.state.title.value.trim())) {
            this.setState({ ...this.state, title: { ...this.state.title, message: 'Title is not valid.' } });
        } else {
            this.setLoading(true)
            doPost(this.props.token, '/videos', {
                video_title: this.state.title.value,
                video_kind: this.state.video_kind ? Number(this.state.video_kind) : 10,
                season_index: this.props.season_index,
                occurred_on: this.state.occurred_on,
                angles: [], // todo add these some time?
                quality: this.state.quality,
                remove_audio: this.state.remove_audio
            }, (r) => {
                this.setLoading(false)
                this.onClear()
                this.reset()
                this.props.close(null, true)
            }, (r) => {
                this.setLoading(false)
                this.setError(r.response.data.message)
            });
        }
    };


    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                {this.getAlert()}

                <TextInput disabled={this.state.loading} info={this.state.title}
                    change={(event, info) => this.onChange(event, info)} />

                <SelectPanel disabled={this.state.loading} label={'Kind'} value={this.state.video_kind} items={this.props.video_kinds}
                    change={(event) => this.onChangeField(event, 'video_kind')} />

                <DateTimeInput disabled={this.state.loading} id="occurred_on" label="Occurred" value={this.state.occurred_on}
                    onChange={(event) => this.onChangeDate(event, 'occurred_on')} />

                <Typography id="non-linear-slider" gutterBottom>
                    Quality
                    </Typography>
                <div style={{ margin: '0 10px' }}>
                    <Slider
                        disabled={this.state.loading}
                        value={this.state.quality}
                        onChange={this.onChangeQuality}
                        step={null}
                        marks={this.state.marks}
                        valueLabelDisplay="off"
                        min={10}
                        max={70}
                    />
                </div>

                <div style={{ padding: '10px 0' }}>
                    <CheckPanel loading={this.state.loading} change={this.onCheck('remove_audio')} label='Remove Audio' checked={this.state.remove_audio} />
                </div>

                <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={'Video Set Up'} close={this.onClose} />
                <DialogContent id="player-content">
                    {form}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
    };
};

export default withRouter(connect(mapStateToProps)(create));
