import React, {Component} from 'react';

import './Header.css';
import {getColors} from "../../functions";
import * as actions from "../../../store/actions";
import {connect} from "react-redux";

class header extends Component {
    render() {
        return <div className={'Header'} style={{
            color: this.props.text_color.main,
            borderColor: this.props.background_color.main
        }}>{this.props.children}</div>
    }
};

const mapStateToProps = state => {
    const {text_color, background_color} = getColors(state);

    return {
        text_color: text_color,
        background_color: background_color
    };
};

const mapDispatchToProps = dispatch => {
    return {
        selectVideoFile: (video_file) => dispatch(actions.selectVideoFile(video_file)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(header);
