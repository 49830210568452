import React from 'react';
import CheckIcon from '@material-ui/icons/Check';

class view extends React.Component {
    render() {
        let i;

        if (this.props.value) {
            i = <CheckIcon style={{color: 'green'}}/>;
        }

        return (
            <div>{i}</div>
        );
    }
}

export default view;
