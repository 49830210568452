import React, { Component } from 'react';
import * as actions from '../../../../store/actions/index';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MemberRow from "./Row/Member";
import { getToken, canManageTeam } from "../../../../components/functions";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./List.css";
import Create from "./Create"
import Detail from "./Detail"

class list extends Component {
    state = {
        is_detail: false,
        is_create: false,
        model: {},
    };

    componentWillUnmount() {
        this.setState({ ...this.state, members: [], empty: false});
    }

    componentDidMount() {
        this.onLoad();
    }

    onCreate = (e) => {
        if (e) {
            e.stopPropagation()
        }

        this.setState({ ...this.state, is_create: true })
    };

    onDetail = (e, model) => {
        e.stopPropagation()
        if (this.props.manage_team) {
            this.setState({ ...this.state, is_detail: true, model: model })
        }
    };

    onClose = (e, load, model) => {
        if (e) {
            e.stopPropagation()
        }

        if (load) {
            this.onLoad()
        }

        if (model) {
            const list = this.state.members;

            const i = list.findIndex(l => l.member_id === model.member_id);

            if (i >= 0) {
                list[i] = model
            }

            this.onLoad()
        }

        this.setState({ ...this.state, is_create: false, is_detail: false, model: model })
    };

    onLoad = () => {
        this.props.loadTeamMembers(this.props.token)
    };

    render() {
        const list = this.props.team_members ? this.props.team_members : []

        let table = <Spinner />;

        if (list && list.length > 0) {
            table = <Paper style={{ marginBottom: 15 }}>
                <Table aria-label="team members table" size={'small'} className={'Table'}>
                    <TableHead className='PrimaryTable'>
                        <TableRow>
                            <TableCell align="left" style={{ paddingLeft: 60 }}>Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left"><div>Manage</div><div>Billing</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Team</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Photos</div></TableCell>
                            <TableCell align="left"><div>Manage</div><div>Videos</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Members</div></TableCell>
                            <TableCell align="left"><div>View</div><div>Videos</div></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list.map(row => {
                            return (
                                <MemberRow onClick={(e, model) => this.onDetail(e, model)}
                                    alternate_team_theme={this.props.team_member?.alternate_team_theme}
                                    key={`${row.email_address}${row.created_on}`} model={row} />
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        }

        return (
            <div>
                {this.props.manage_team && <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.onCreate}> Invite Member </Button>
                </div>}

                {table}

                <Create open={this.state.is_create} close={(e, load) => this.onClose(e, load)} />
                <Detail open={this.state.is_detail} close={(e, load, model) => this.onClose(e, load, model)} model={this.state.model} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        user: state.auth.user,
        team: state.teams.team,
        team_member: state.team_members.team_member,
        team_members: state.teams.members,
        loading: state.teams.loading,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadTeamMembers: (token) => dispatch(actions.loadTeamMembersAction(token)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(list));
