import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doGet } from "../../axios-main";
import Invite from "./Invite";
import { getToken } from "../../components/functions";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

class Invites extends Component {
    state = {
        items: null,
        loading: null,
        empty: null
    }

    componentDidMount() {
        setTimeout(() => {
            this.onLoad();
        }, 0);
    }

    onLoad = () => {
        this.setLoading(true);

        doGet(this.props.token, '/members/invites',
            (r) => {
                this.setState({
                    ...this.state,
                    items: r.data,
                    empty: r.data.length <= 0,
                    loading: false
                });
            },
            (r) => {
                console.log('ERROR', r.response.data.message);
                this.setLoading(false);
            });
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    render() {
        let items;
        let table = <h3>You have no pending invites.</h3>;

        if (this.state.items && this.state.items.length > 0) {
            items = this.state.items.map((i) => {
                return <Invite key={i.invite_token} invite={i} />
            })

            table = <Table>
                <TableHead className='PrimaryTable'>
                    <TableRow>
                        <TableCell align="left">Team</TableCell>
                        <TableCell align="left">Player</TableCell>
                        <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items}
                </TableBody>
            </Table>
        }


        return (
            <>{table}</>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
