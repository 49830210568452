import React, { Component } from 'react';
import FileSize from "../../../../../components/FileSize/FileSize";

import { makeStyles } from '@material-ui/core/styles';

import styles from './Part.module.css';
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo'

// Inspired by the Facebook spinners.
const useStylesFacebook = makeStyles({
    root: {
        position: 'relative',
    },
    top: {
        color: 'lightgrey',
        position: 'absolute',
        right: 0,
        top: 2
    },
    bottom: {
        color: 'green',
        position: 'absolute',
        right: 0,
        top: 2
    },
});

function FacebookProgress(props) {
    const classes = useStylesFacebook();

    return (
        <div className={classes.root}>
            <CircularProgress
                variant="determinate"
                className={classes.top}
                size={25}
                thickness={7}
                {...props}
                value={100}
            />
            <CircularProgress
                variant="determinate"
                className={classes.bottom}
                size={25}
                thickness={7}
                value={0}
                {...props}
            />
        </div>
    );
}

class part extends Component {
    getProgress = () => {
        if (this.props.status === 'Uploaded') {
            return 100;
        }

        if (this.props.status !== 'Uploading') {
            return '';
        }

        if (this.props.model.file.size <= 0) {
            return 100;
        }

        const uploaded = Math.round(100 * (this.props.model.amountUploaded / this.props.model.file.size));
        return uploaded;
    }

    render() {
        const model = this.props.model;

        let progress = this.getProgress();

        const statusClass = styles['Status' + this.props.status];

        console.log(this.props)

        return (
            <div className={styles.Part}>
                <div className={styles.Relative}>
                    <div className={[styles.Flex, styles.Full, styles.Tall].join(' ')}>
                        <div className={styles.TitleCol}>
                            {model.file.name}
                        </div>
                        <div className={styles.SizeCol}>
                            <FileSize size={model.file.size} />
                        </div>
                        <div className={[styles.StatusCol, statusClass].join(' ')}>
                            {this.props.status === 'Error' &&
                                <FontAwesomeIcon icon={faRedo} className={styles.Retry} aria-label="close" />
                            }
                            {this.props.status === 'Uploading' &&
                                <FacebookProgress value={progress} />
                            }
                            {((this.props.status !== 'Error') && (this.props.status !== 'Uploading')) && <div>{this.props.status}</div>}
                        </div>
                    </div>
                </div>
                {this.props.status === 'Error' &&
                    <div style={{ flex: '1 1 0px', textAlign: 'right', fontSize: '0.7rem', color: 'red', fontStyle: 'italic', paddingBottom: 5, marginTop: -5 }}>
                        {model.remoteItem.status_message}
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {

    return {
        video_file_status: state.enums.video_file_status ? state.enums.video_file_status : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(part);
