import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import CloseIcon from '../CloseIcon/CloseIcon';

import styles from './DialogAppBar.module.css';

import ImageComponent from "../ImageComponent/index";

class DialogAppBar extends Component {
    render() {
        return (
            <AppBar style={{ background: 'lightgrey', color: 'black', position: 'relative' }}>
                <Toolbar style={{ display: 'flex', padding: '0 5px' }}>
                    <div className={styles.Close} variant="contained" color="primary" onClick={this.props.close}>
                        <CloseIcon style={{ marginRight: 0 }} aria-label="close" />
                    </div>
                    <div style={{ flex: '1 1 0px' }}><Typography variant="h6">
                        {this.props.title} {this.props.section && <span>- <span style={{ textTransform: 'capitalize' }}>{this.props.section}</span></span>}
                    </Typography>
                    </div>
                    {this.props.name &&
                        <Typography style={{ fontSize: '1rem', fontWeight: 'bold' }}>
                            {this.props.name}
                        </Typography>
                    }
                    {(this.props.name || this.props.image) &&
                        <div style={{ marginLeft: 5 }}>
                            <ImageComponent first={this.props.name} image_url={this.props.image} size={50} theme={this.props.theme} />
                        </div>
                    }
                </Toolbar>
            </AppBar>
        )
    }
}
export default DialogAppBar;
