import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import Season from "./Season";

class team extends Component {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/:season_id`} render={(props) => (<Season key={`${props.match.params.season_id}`} {...props} />)} />
            </Switch>
        );
    }
}

export default team;
