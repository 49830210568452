import React from 'react';

import { doPut } from "../../../../axios-main";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import Form from "../../../../components/UI/Form/Form";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import ColorPicker from "../../../../components/UI/ColorPicker/ColorPicker";
import CheckPanel from "../../../../components/UI/CheckPanel/CheckPanel";
import TeamMemberPermissions from "../../../../components/UI/Permission/TeamMemberPermissions";
import { withRouter } from "react-router-dom";
import { getToken, canManageTeam } from "../../../../components/functions";
import Paper from "@material-ui/core/Paper";
import "../../Member/List/List.css";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from "../../../../components/UI/DialogAppBar/DialogAppBar";
import FormComponent from '../../../../components/UI/FormComponent/FormComponent';

class index extends FormComponent {
    state = {
        loading: false,
        team_title: {
            id: 'team_title',
            label: 'Team Title',
            value: '',
            valid: false,
            focus: true,
            validate: { blank: false, max_length: 50 }
        },
        mobile_number: {
            id: 'mobile_number',
            label: 'Mobile Number',
            value: '',
            valid: false,
            focus: false,
            validate: { max_length: 25 }
        },
        primary_color: '#606060',
        secondary_color: '#CC0000',
        hide_contact_info: false,
        permissions: 0,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.model?.member_id !== this.props.model?.member_id) {
            this.setState({
                ...this.state,
                team_title: { ...this.state.team_title, value: this.props.model?.alternate_team_title ? this.props.model.alternate_team_title : this.props.team.team_title },
                primary_color: this.props.model?.alternate_team_theme?.primary_color ? this.props.model.alternate_team_theme.primary_color : this.props.team.theme.primary_color,
                secondary_color: this.props.model?.alternate_team_theme?.secondary_color ? this.props.model.alternate_team_theme.secondary_color : this.props.team.theme.secondary_color,
                permissions: this.props.model?.permissions ? this.props.model.permissions : 0,
            })
        }
    }

    onChangePermissions = (permissions) => {
        this.setState({
            ...this.state, permissions: permissions
        });
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (this.isCurrentMember() || this.props.manage_team) {
            this.setLoading(true);

            doPut(this.props.token, '/teams/members', {
                member_id: this.props.model.member_id,
                alternate_team_theme: { primary_color: this.state.primary_color, secondary_color: this.state.secondary_color },
                alternate_team_title: this.state.team_title.value,
                hide_contact_info: this.state.hide_contact_info,
                permissions: this.props.manage_team ? this.state.permissions : null,
            }, (r) => {
                this.setLoading(false);
                this.onClose(null, false, r.data);
            }, (r) => {
                this.setLoading(false);
                this.setError(r.response.data.message)
            });
        }
    };

    isCurrentMember = () => {
        return this.props.team_member?.member_id === this.props.model?.member_id;
    }

    onClose = (event, load, model) => {
        this.props.close(event, load, model);
        this.reset();
    }

    render() {
        let form = (
            <Paper style={{ padding: 10 }}>
                <Form onSubmit={this.submitHandler}>
                    {this.getAlert()}
                    <TextInput disabled={this.state.loading} info={this.state.team_title}
                        change={(event, info) => this.onChange(event, info)} />

                    <div style={{ textAlign: 'left' }}>
                        <CheckPanel change={this.onCheck('hide_contact_info')} label='Hide Contact Info' checked={this.state.hide_contact_info} />
                    </div>

                    <ColorPicker disabled={this.state.loading} label="Primary Color" value={this.state.primary_color} field="primary_color"
                        position={'bottom'} change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />
                    <ColorPicker disabled={this.state.loading} label="Secondary Color" value={this.state.secondary_color} field="secondary_color"
                        position={'bottom'} change={(value, field) => {
                            this.onChangeColor(value, field)
                        }} />

                    {!this.isCurrentMember() && <TeamMemberPermissions permissions={this.state.permissions} change={(permissions) => {
                        this.onChangePermissions(permissions)
                    }} />}

                    {(this.isCurrentMember() || this.props.manage_team) && <Button variant="contained" color="primary" type="submit"> SUBMIT </Button>}
                </Form>
            </Paper>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={`Update Member - ${this.props.model?.first_name} ${this.props.model?.last_name}`} close={this.onClose} />
                {form}
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        team: state.teams.team,
        team_member: state.team_members.team_member,
        season: state.seasons.season,
        manage_team: canManageTeam(state),
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
