import React, { Component } from 'react';
import { connect } from "react-redux";
import { getToken } from "../../../../components/functions";
import Error from "../../../../components/UI/Error/Error";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { doPost } from "../../../../axios-main";
import Row from "../Row/Row";
import Create from "../Create/Create";
import Edit from "../Detail/Detail";

class list extends Component {
    state = {
        loading: false,
        empty: false,
        items: [],
        item: {},
        open: false,
        edit: false,
    };

    componentDidMount() {
        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.onLoad();
                clearInterval(i);
            }
        }, 0);
    }

    close = (e, load) => {
        if (load === true) {
            this.onLoad();
        }

        this.setState({
            ...this.state,
            loading: false,
            empty: false,
            item: {},
            open: false,
            edit: false
        });
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    loadSuccess = (r) => {
        this.setState({ ...this.state, items: r.data.items });
        this.setLoading(false);
    };

    loadError = (r) => {
        console.log('ERROR', r);
        this.setLoading(false);
    };

    onLoad = () => {
        this.setLoading(true);
        doPost(this.props.token, '/locations/list', {}, this.loadSuccess, this.loadError);
    };

    createHandler = (e) => {
        e.stopPropagation();
        this.setState({ ...this.state, open: true });
    };

    onItem = (e, item) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ ...this.state, edit: true, item: item });
    };

    render() {
        let alert = null;

        if (this.props.error) {
            alert = (
                <Error>{this.props.error}</Error>
            );
        }

        return (
            <div>
                <div style={{ padding: '5px 0 15px 0' }}>
                    <Button variant="contained" color="primary" onClick={this.createHandler}> New Location </Button>
                </div>

                {alert}

                <Paper style={{ margin: '10px' }}>
                    <Table aria-label="location table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">City</TableCell>
                                <TableCell align="left">Line 1</TableCell>
                                <TableCell align="left">Line 2</TableCell>
                                <TableCell align="left">Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.state.items.map(row => {
                                    if (row.id) {
                                        return (
                                            <Row onClick={(e, item) => this.onItem(e, item)}
                                                key={`${row.id}`} model={row} />
                                        )
                                    } else {
                                        console.log('EMPTY', row);
                                        return '';
                                    }
                                }
                                )
                            }
                        </TableBody>
                    </Table>
                </Paper>

                <Create open={this.state.open} close={(e, load) => this.close(e, load)} />

                <Edit open={this.state.edit} item={this.state.item}
                    close={(e, load) => this.close(e, load)} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        season: state.seasons.season,
        team: state.teams.team,
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(list);
