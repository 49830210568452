import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ImageComponent from "../../../../../components/UI/ImageComponent/index";
import BooleanView from "../../../../../components/UI/BooleanView/index";
import {
    MANAGE_BILLING,
    MANAGE_TEAM,
    MANAGE_PHOTOS,
    MANAGE_VIDEOS,
    VIEW_MEMBERS,
    VIEW_VIDEOS,
} from "../../../../../constants/permissions";

class row extends Component {
    render() {
        const { permissions, first_name, last_name, image_url, email_address } = this.props.model;
        const { theme } = this.props.team;
        const { alternate_team_theme } = this.props;

        return (
            <TableRow key={`${this.props.model.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, this.props.model)}>
                <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ImageComponent theme={alternate_team_theme ? alternate_team_theme : theme} size={50} first={first_name} last={last_name} image_url={image_url} />
                        <div style={{ flex: 1, marginLeft: 10 }}>{first_name} {last_name}</div>
                    </div>
                </TableCell>
                <TableCell align="left">{email_address}</TableCell>
                <TableCell align="left"><BooleanView value={(permissions & MANAGE_BILLING) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(permissions & MANAGE_TEAM) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(permissions & MANAGE_PHOTOS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(permissions & MANAGE_VIDEOS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(permissions & VIEW_MEMBERS) !== 0} /></TableCell>
                <TableCell align="left"><BooleanView value={(permissions & VIEW_VIDEOS) !== 0} /></TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        team: state.teams.team
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(row));
