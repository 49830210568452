import React, { Component } from 'react';

import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Spinner from "../../../../components/UI/Spinner/Spinner";
import ColorPicker from "../../../../components/UI/ColorPicker/ColorPicker";
import Form from "../../../../components/UI/Form/Form";
import TextInput from "../../../../components/UI/TextInput/TextInput";
import { doPut } from "../../../../axios-main";
import { withRouter } from "react-router-dom";
import { empty, getToken } from "../../../../components/functions";
import { state } from "../constants";
import "./Detail.css";
import Dialog from "@material-ui/core/Dialog";
import DialogAppBar from '../../../../components/UI/DialogAppBar/DialogAppBar';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class detail extends Component {
    state = state;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item !== this.props.item) {
            this.setLoading(false);

            const { item } = this.props;

            this.setState({
                ...this.state,
                title: { ...this.state.title, value: empty(item.title) },
                coach_name: { ...this.state.coach_name, value: empty(item.coach_name) },
                coach_email: { ...this.state.coach_email, value: empty(item.coach_email) },
                coach_phone_number: { ...this.state.coach_phone_number, value: empty(item.coach_phone_number) },
                mascot: { ...this.state.mascot, value: empty(item.mascot) },
                notes: { ...this.state.notes, value: empty(item.notes) },
                text_color: empty(item.text_color),
                background_color: empty(item.background_color),
            });
        }
    }

    onChange = (e, info) => {
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeColor = (value, field) => {
        this.setState({ ...this.state, [field]: value });
    };

    setLoading = (loading) => {
        this.setState({ ...this.state, loading: loading });
    };

    submitHandler = (event) => {
        event.preventDefault();

        if (!this.state.title.valid) {
            this.setState({ ...this.state, title: { ...this.state.title, message: 'Required' } });
        } else if (!this.state.mascot.valid) {
            this.setState({ ...this.state, mascot: { ...this.state.mascot, message: 'Required' } });
        } else {
            const put = {
                title: this.state.title.value,
                coach_name: this.state.coach_name.value,
                coach_email: this.state.coach_email.value,
                coach_phone_number: this.state.coach_phone_number.value,
                mascot: this.state.mascot.value,
                notes: this.state.notes.value,
                text_color: this.state.text_color,
                background_color: this.state.background_color,
            };

            this.setLoading(true);

            doPut(this.props.token, `/opponents/${this.props.item.id}`, put, () => {
                this.setLoading(false);
                this.props.close(null, true);
            }, (r) => {
                this.setLoading(false);
                console.log('ERROR', r.response.data.message);
            });
        }
    };

    render() {
        let form = (
            <Form onSubmit={this.submitHandler}>
                <TextInput info={this.state.title}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.coach_name}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.coach_email}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.coach_phone_number}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.mascot}
                    change={(event, info) => this.onChange(event, info)} />
                <TextInput info={this.state.notes}
                    change={(event, info) => this.onChange(event, info)} />

                <ColorPicker label="Primary Color" value={this.state.background_color} field="background_color"
                    position={'bottom'} change={(value, field) => {
                        this.onChangeColor(value, field)
                    }} />
                <ColorPicker label="Secondary Color" value={this.state.text_color} field="text_color"
                    position={'bottom'} change={(value, field) => {
                        this.onChangeColor(value, field)
                    }} />
            </Form>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} scroll={'paper'} open={this.props.open} onClose={this.props.close} disableBackdropClick>
                <DialogAppBar title={'Update Opponent'} close={this.props.close} />
                <DialogContent id="player-content">
                    {form}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.close} variant="contained" color="secondary" type="button"> CLOSE </Button>
                    <Button onClick={this.submitHandler} variant="contained" color="primary" type="button"> SUBMIT </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        season: state.seasons.season,
        team: state.teams.team,
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(detail));
