import React from 'react';

import classes from './Card.module.css';

const Card = (props) => {
    return (
        <div className={[classes.Card, classes[props.cardType]].join(' ')}
             onClick={props.clicked}>{props.children}</div>
    );
};

export default Card;
