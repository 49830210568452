import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Radio from '@material-ui/core/Radio';
import BlockUsage from "../../../../components/BlockUsage/BlockUsage";
import Duration from "../../../../components/Duration/Duration";

class row extends Component {
    select = (e) => {
        e.stopPropagation()
        this.props.select(e, this.props.model);
    };

    render() {
        const { model } = this.props

        const d = new Date(model.occurred_on)

        return (
            <TableRow style={{ cursor: "pointer" }} onClick={this.select}>
                <TableCell align="left">
                    <Radio checked={this.props.checked === model.video_id} name="video-radio" />
                    {model.video_title}
                </TableCell>
                <TableCell align="left">
                    {this.props.video_kinds.find(k => k.id === model.video_kind)?.title}
                </TableCell>
                <TableCell align="center">
                    <div>{d.toLocaleDateString()}</div>
                    <div>{d.toLocaleTimeString()}</div>
                </TableCell>
                <TableCell align="left">
                    <BlockUsage size={model.total_size} />
                </TableCell>
                <TableCell align="left">
                    <Duration duration={model.total_duration} />
                </TableCell>
                <TableCell align="left">
                    {model.total_files}
                </TableCell>
            </TableRow>
        )
    }
}

const mapStateToProps = state => {
    return {
        video_kinds: state.enums.video_kinds ? state.enums.video_kinds : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(row));
