import React, { Component } from 'react';

import { DateTimePicker } from "@material-ui/pickers";

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import Spinner from "../../../components/UI/Spinner/Spinner";
import Header from "../../../components/UI/Header/Header";

import { withRouter } from "react-router-dom";
import Success from "../../../components/UI/Alert/Success";
import Error from "../../../components/UI/Alert/Error";
import Form from "../../../components/UI/Form/Form";
import TextInput from "../../../components/UI/TextInput/TextInput";
import { doPost } from "../../../axios-main";
import Card from "../../../components/UI/Card/Card";
import { getColors, pushPath, getToken } from "../../../components/functions";
import CheckPanel from "../../../components/UI/CheckPanel/CheckPanel";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import ColorPicker from "../../../components/UI/ColorPicker/ColorPicker";

class create extends Component {
    state = {
        title: {
            id: 'title',
            label: 'Title',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        duration_in_minutes: {
            id: 'duration_in_minutes',
            label: 'Duration In Minutes',
            value: '',
            valid: false,
            focus: true,
            validate: {}
        },
        event_kind_other: {
            id: 'event_kind_other',
            label: 'Event Kind Other',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        field_location: {
            id: 'field_location',
            label: 'Field Location',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        game_kind_other: {
            id: 'game_kind_other',
            label: 'Game Kind Other',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        message: {
            id: 'message',
            label: 'Message',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        opponent_team_score: {
            id: 'opponent_team_score',
            label: 'Opponent Team Score',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        this_team_score: {
            id: 'this_team_score',
            label: 'This Team Score',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        zone_id: {
            id: 'zone_id',
            label: 'Zone Id',
            value: Intl.DateTimeFormat().resolvedOptions().timeZone,
            valid: false,
            focus: false,
            validate: {}
        },
        online_url: {
            id: 'online_url',
            label: 'Online URL',
            value: '',
            valid: false,
            focus: false,
            validate: {}
        },
        event_kind: '',
        game_kind: '',
        squads: [],
        squad_values: [],
        manage_squad_values: [],
        location: '',
        locations: [],
        opponent: '',
        opponents: [],
        online: false,
        all_day: false,
        starts_on: new Date(),
        step: 1,
    };

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }

        let i = setInterval(() => {
            if (this.props.team && this.props.season) {
                this.getSquads();
                this.getLocations();
                this.getOpponents();
                clearInterval(i);
            }
        }, 0);
        this.setState({ ...this.state, step: 1 });
    }

    componentWillUnmount() {
        this.setState({ ...this.state, step: 1 });
    }

    getSquads = () => {
        // TODO get squads
        // doPost(this.props.token, '/squads/list', {}, res => this.setState({
        //     ...this.state,
        //     squads: res.data.items
        // }), r => console.log('POST ERROR', r));
    };

    getLocations = () => {
        doPost(this.props.token, '/locations/list', {}, res => this.setState({
            ...this.state,
            locations: res.data.items
        }), r => console.log('POST ERROR', r));
    };

    getOpponents = () => {
        doPost(this.props.token, '/opponents/list', {}, res => this.setState({
            ...this.state,
            opponents: res.data.items
        }), r => console.log('POST ERROR', r));
    };

    onAllSquads = (event) => {
        this.setState({ ...this.state, squad_values: this.selectAll(this.state.squads, event.target.checked) });
    };

    onAllManageSquads = (event) => {
        this.setState({ ...this.state, manage_squad_values: this.selectAll(this.state.manage_squad_values, event.target.checked) });
    };

    onCheckSquads = (id, event) => {
        this.setState({
            ...this.state,
            squad_values: this.getValues(this.state.squad_values, id, event.target.checked)
        });
    };

    onCheckManageSquads = (id, event) => {
        this.setState({
            ...this.state,
            manage_squad_values: this.getValues(this.state.manage_squad_values, id, event.target.checked)
        });
    };

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id, step: 1 });
            } else if (this.props.season.id && !this.state.season_id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id, step: 1 });
            }
        }
    }

    onCancel = (event) => {
        event.stopPropagation();
        pushPath(this.props, 'events');
    };

    onNext = (e) => {
        e.stopPropagation();
        this.setState({ ...this.state, step: this.state.step + 1 });
    };

    onBack = (e) => {
        e.stopPropagation();
        this.setState({ ...this.state, step: this.state.step - 1 });
    };

    onChange = (e, info) => {
        this.reset();
        this.setState({ ...this.state, [info.id]: info });
    };

    onChangeDate = (value) => {
        this.reset();
        this.setState({ ...this.state, starts_on: value });
    };

    onChangeColor = (value, field) => {
        this.setState({ ...this.state, [field]: value });
    };

    onChangeField = (event, field) => {
        this.reset();
        this.setState({ ...this.state, [field]: event.target.value });
    };

    set_success = (event) => {
        this.reset();
        this.setState({ ...this.state, 'success': event });
    };

    set_error = (event) => {
        this.reset();
        this.setState({ ...this.state, 'error': event });
    };

    reset = () => {
        this.setState({ ...this.state, 'error': null });
        this.setState({ ...this.state, 'success': null });
    };

    submitHandler = (e) => {
        e.preventDefault();

        this.store();
    };

    onCheck = (field, e) => {
        this.setState({
            ...this.state,
            [field]: e.target.checked
        });

        setTimeout(() => {
            console.log(this.state);
        }, 0);
    };

    onSend = (e) => {
        e.preventDefault();

        this.store();
    };

    store = () => {
        this.reset();

        const state = this.state;

        doPost(this.props.token, '/events', {
            title: state.title.value,
            duration_in_minutes: state.duration_in_minutes.value,
            event_kind: this.state.event_kind,
            event_kind_other: state.event_kind_other.value,
            field_location: state.field_location.value,
            game_kind: this.state.game_kind,
            game_kind_other: state.game_kind_other.value,
            message: state.message.value,
            location_id: state.location,
            opponent_id: state.opponent,
            opponent_team_score: state.opponent_team_score.value,
            this_team_score: state.this_team_score.value,
            zone_id: state.zone_id.value,
            online_url: state.online_url.value,
            squad_ids: state.squad_values,
            manage_squad_ids: state.manage_squad_values,
            season_id: this.props.season.id,
            starts_on: this.state.starts_on,
            all_day: this.state.all_day,
            online: this.state.online,
        }, () => {
            this.set_success("Event created.");
            pushPath(this.props, `events`);
        }, (r) => {
            console.log(r.response);
            this.set_error(r.response.data.event);
        });
    };

    getValues = (values, id, checked) => {
        const exists = values.findIndex((v) => v === id);

        if (checked) {
            if (exists < 0) {
                values.push(id);
            }
        } else {
            if (exists >= 0) {
                values.splice(exists, 1);
            }
        }

        return values;
    };

    render() {
        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let squads;

        if (this.state.squads && this.state.squads.length > 0) {
            squads = this.state.squads.map(row => {
                if (row.id) {
                    return (
                        <div key={row.id} style={{ padding: '0 30px' }}>
                            <CheckPanel change={(event) => this.onCheckSquads(row.id, event)}
                                label={row.title}
                                checked={(this.state.squad_values.find((v) => v === row.id) > 0)} />
                        </div>
                    )
                } else {
                    console.log('EMPTY ROW', row);
                    return '';
                }
            }
            )
        }

        let manage_squads;

        if (this.state.squads && this.state.squads.length > 0) {
            manage_squads = this.state.squads.map(row => {
                if (row.id) {
                    return (
                        <div key={row.id} style={{ padding: '0 30px' }}>
                            <CheckPanel change={(event) => this.onCheckManageSquads(row.id, event)}
                                label={row.title}
                                checked={(this.state.manage_squad_values.find((v) => v === row.id) > 0)} />
                        </div>
                    )
                } else {
                    console.log('EMPTY ROW', row);
                    return '';
                }
            }
            )
        }

        let form = (
                <Card style={{ margin: 10, padding: 10 }}>
                    <Header> SETUP AN EVENT </Header>

                    {error}
                    {success}

                    <Form onSubmit={this.submitHandler}>
                        {(this.state.step) === 1 && <div>
                            <h3>Step 1 of 3: Event</h3>
                            <TextInput info={this.state.title}
                                change={(event, info) => this.onChange(event, info)} />

                            <DateTimePicker
                                style={{ width: '100%' }}
                                label="Starts"
                                value={this.state.starts_on}
                                onChange={this.onChangeDate}
                            />

                            <div style={{ textAlign: 'left' }}>
                                <CheckPanel change={(event) => this.onCheck('all_day', event)}
                                    label="All Day"
                                    checked={(this.state.all_day)} />
                            </div>

                            <SelectPanel label={'Opponent'} value={this.state.opponent}
                                items={this.state.opponents}
                                change={(event) => this.onChangeField(event, 'opponent')}
                            />

                            <div style={{ paddingTop: 25 }}>
                                <SelectPanel label={'Location'} value={this.state.location}
                                    items={this.state.locations}
                                    change={(event) => this.onChangeField(event, 'location')}
                                />
                            </div>

                            <div style={{ paddingTop: 25 }}>
                                <ColorPicker label="Color" value={this.state.color} field="color"
                                    change={(value, field) => {
                                        this.onChangeColor(value, field)
                                    }} />
                            </div>

                            <TextInput info={this.state.duration_in_minutes}
                                change={(event, info) => this.onChange(event, info)} />

                            <SelectPanel label={'Kind'} value={this.state.event_kind} items={this.props.event_kinds}
                                change={(event) => this.onChangeField(event, 'event_kind')} />

                            {this.state.event_kind === 100 && <TextInput info={this.state.event_kind_other}
                                change={(event, info) => this.onChange(event, info)} />}

                            <TextInput info={this.state.field_location}
                                change={(event, info) => this.onChange(event, info)} />

                            <SelectPanel label={'Game Kind'} value={this.state.game_kind} items={this.props.game_kinds}
                                change={(event) => this.onChangeField(event, 'game_kind')} />

                            <TextInput info={this.state.game_kind_other}
                                change={(event, info) => this.onChange(event, info)} />

                            <TextInput info={this.state.message}
                                change={(event, info) => this.onChange(event, info)} />

                            <TextInput info={this.state.this_team_score}
                                change={(event, info) => this.onChange(event, info)} />

                            <TextInput info={this.state.opponent_team_score}
                                change={(event, info) => this.onChange(event, info)} />

                            <TextInput info={this.state.zone_id}
                                change={(event, info) => this.onChange(event, info)} />

                            <div style={{ textAlign: 'left' }}>
                                <CheckPanel change={(event) => this.onCheck('online', event)}
                                    label="Online"
                                    checked={(this.state.online)} />
                            </div>

                            {this.state.online && <TextInput info={this.state.online_url}
                                change={(event, info) => this.onChange(event, info)} />}
                        </div>}

                        {(this.state.step) === 2 && <div>
                            <h3>Step 2 of 3: Squads</h3>
                            {squads}
                        </div>}

                        {(this.state.step) === 3 && <div>
                            <h3>Step 3 of 3: Manager Squads</h3>
                            {manage_squads}
                        </div>}

                        <Button style={{ margin: '0 2px' }} variant="contained" color="default" type="button"
                            onClick={this.onCancel}> CANCEL </Button>
                        {(this.state.step) > 1 && <Button style={{ margin: '0 2px' }} variant="contained" color="secondary" type="button"
                            onClick={this.onBack}> BACK </Button>}
                        {(this.state.step) < 3 && <Button style={{ margin: '0 2px' }} variant="contained" color="secondary" type="button"
                            onClick={this.onNext}> NEXT </Button>}
                        {(this.state.step) === 3 && <Button style={{ margin: '0 2px' }} variant="contained" color="primary" type="submit"> CREATE </Button>}
                    </Form>
                </Card>
        );

        if (this.props.loading) {
            form = <Spinner />
        }

        return (
            <div>
                {form}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { text_color, background_color } = getColors(state);

    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        text_color: text_color,
        background_color: background_color,
        event_kinds: state.enums.event_kinds ? state.enums.event_kinds : [],
        game_kinds: state.enums.game_kinds ? state.enums.game_kinds : [],
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(create));
