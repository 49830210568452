import React, { Component } from 'react';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ImageComponent from "../../../components/UI/ImageComponent/index";

class row extends Component {
    render() {
        const { model } = this.props;

        return (
            <TableRow key={`${model.id}`} style={{ cursor: "pointer" }} onClick={(event) => this.props.onClick(event, model)}>
                <TableCell align="left">
                    {model.product?.title}
                </TableCell>
                <TableCell align="left">
                    {model.quantity} Storage Blocks
                </TableCell>
                <TableCell align="left">
                    {model.years > 0 && <>{model.years} years</>} {model.days > 0 && <>{model.days} days</>}
                </TableCell>
                <TableCell align="left">
                    {new Date(model.expires_on).toLocaleDateString()}
                </TableCell>
                <TableCell align="left">
                    ${Number(model.product?.cost) * model.quantity}
                </TableCell>
                <TableCell align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 5 }}>
                            <ImageComponent first={model.member_first_name} last={model.member_last_name} image_url={model.member_image_url} />
                        </div>
                        <div>
                            {model.member_first_name} {model.member_last_name}
                        </div>
                    </div>
                </TableCell>
            </TableRow>
        )
    }
}

export default row;
