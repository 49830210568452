import React from 'react';

import Button from "@material-ui/core/Button";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import DialogAppBar from "../../../components/UI/DialogAppBar/DialogAppBar";

import { withRouter } from "react-router-dom";
import HeightInput from "../../../components/UI/HeightInput/HeightInput";
import Success from "../../../components/UI/Alert/Success";
import SelectPanel from "../../../components/UI/SelectPanel/SelectPanel";
import Error from "../../../components/UI/Alert/Error";
import WeightInput from "../../../components/UI/WeightInput/WeightInput";
import TextInput from "../../../components/UI/TextInput/TextInput";
import DateInput from "../../../components/UI/DateInput/DateInput";
import FormComponent from "../../../components/UI/FormComponent/FormComponent";
import { doPost } from "../../../axios-main";
import { getToken, checkEmail, getLocalDate } from "../../../components/functions";
import CheckPanel from "../../../components/UI/CheckPanel/CheckPanel";
import ChipInput from 'material-ui-chip-input';
import SubmitButton from '../../../components/UI/SubmitButton/SubmitButton';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

class CreateParticipant extends FormComponent {
    state = {
        emails: [],
        email_address: {
            id: 'email_address',
            label: 'Email Address',
            value: '',
            valid: false,
            validate: {}
        },
        first_name: {
            id: 'first_name',
            label: 'First Name',
            value: '',
            valid: false,
            focus: true,
            validate: { max_length: 50 }
        },
        last_name: {
            id: 'last_name',
            label: 'Last Name',
            value: '',
            valid: false,
            validate: { max_length: 50 }
        },
        nick_name: {
            id: 'nick_name',
            label: 'Nick Name',
            value: '',
            valid: false,
            validate: { max_length: 25 }
        },
        birth_date: null,
        grade_level: '',
        height_in_inches: '',
        jersey_number: {
            id: 'jersey_number',
            label: 'Jersey Number',
            value: '',
            valid: true,
            validate: { max_length: 25 }
        },
        season_id: '',
        weight_in_ounces: '',
        invites: [],
        optionToggle: false,
        inviteToggle: false,
        permissions: 48,
        invite_others_allowed: true,
        modify_participant_allowed: true,
        step: 1,
    };

    onClose = () => {
        this.reset()
        this.clear()
        setTimeout(() => {
            this.props.close(null, true)
        }, 0)
    }

    clear = () => {
        this.setState({
            ...this.state,
            step: 1,
            season_id: '',
            weight_in_ounces: '',
            invites: [],
            optionToggle: false,
            inviteToggle: false,
            permissions: 48,
            invite_others_allowed: true,
            modify_participant_allowed: true,
            birth_date: null,
            grade_level: '',
            height_in_inches: '',
            emails: [],
            email: { ...this.state.email, value: '' },
            first_name: { ...this.state.first_name, value: '' },
            last_name: { ...this.state.last_name, value: '' },
            nick_name: { ...this.state.nick_name, value: '' },
            jersey_number: { ...this.state.jersey_number, value: '' },
        })
    }

    componentDidMount() {
        this.setState({ ...this.state, step: 1 });

        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }

    componentDidUpdate() {
        if (this.props && this.props.season) {
            if (this.state.season_id !== this.props.season.id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            } else if (this.props.season.id && !this.state.season_id) {
                this.setState({ ...this.state, 'season_id': this.props.season.id });
            }
        }
    }

    componentWillUnmount() {
        this.setState({ ...this.state, step: 1 });
    }

    onBeforeAddEmail = (email) => {
        return true;
    };

    onChangePermissions = (permissions) => {
        this.reset();
        this.setState({
            ...this.state, permissions: permissions
        });
    };

    submitHandler = (event) => {
        event.preventDefault();

        this.reset();

        const { email_address, emails } = this.state;

        const list = [];

        if (email_address && email_address.value && email_address.value.trim()) {
            list.push(email_address.value.trim());
        }

        emails.forEach((e) => {
            if (e.trim()) {
                list.push(e.trim());
            }
        });

        if (!this.state.first_name.value.trim() && !this.state.last_name.value.trim()) {
            this.setState({
                ...this.state,
                first_name: { ...this.state.first_name, message: 'One is required' },
                last_name: { ...this.state.last_name, message: 'One is required' },
                step: 1
            });
        } else {
            const state = this.state;

            let invite = list.length > 0 ? {
                invite_others_allowed: this.state.invite_others_allowed,
                modify_participant_allowed: this.state.modify_participant_allowed,
                team_member_permissions: this.state.permissions,
                email_addresses: list
            } : null

            doPost(this.props.token, '/participants', {
                first_name: state.first_name.value,
                last_name: state.last_name.value,
                season_index: this.props.season.season_index,
                nickname: state.nick_name.value,
                birth_date: getLocalDate(state.birth_date),
                grade_level: state.grade_level ? Number(state.grade_level) : null,
                height_in_inches: state.height_in_inches,
                jersey_number: state.jersey_number.value,
                weight_in_ounces: state.weight_in_ounces,
                invite: invite,
            }, () => {
                this.setSuccess("Participant created.");
                this.reset();
                this.clear();
                this.props.close(null, true);
            }, (r) => {
                console.log('ERROR: ', r);
                this.setError(r.response.data.message);
                this.setState({
                    ...this.state,
                    step: 1
                });
            });
        }
    };

    render() {
        const grade_levels = this.props.grade_levels ? this.props.grade_levels : [];

        let error;
        if (this.state.error) {
            error = <div style={{ padding: '5px 0 0 0' }}><Error>{this.state.error}</Error></div>
        }

        let success;
        if (this.state.success) {
            success = <div style={{ padding: '5px 0 0 0' }}><Success>{this.state.success}</Success></div>
        }

        let form = (
            <>
                {error}
                {success}

                {this.state.step === 1 && <div>
                    <h3>Step 1 of 2: Player Information</h3>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 2, marginRight: 5 }}>
                            <TextInput info={this.state.first_name}
                                change={(event, info) => this.onChange(event, info)} />
                        </div>
                        <div style={{ flex: 2 }}>
                            <TextInput info={this.state.last_name}
                                change={(event, info) => this.onChange(event, info)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <TextInput info={this.state.email_address}
                                change={(event, info) => this.onChange(event, info)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, marginRight: 5 }}>
                            <TextInput info={this.state.nick_name}
                                change={(event, info) => this.onChange(event, info)} />
                        </div>
                        <div style={{ flex: 1, marginRight: 5 }}>
                            <TextInput info={this.state.jersey_number}
                                change={(event, info) => this.onChange(event, info)} />
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 2, paddingTop: 16, marginRight: 5 }}>
                            <SelectPanel label={'Grade Level'} value={this.state.grade_level}
                                items={grade_levels}
                                change={(event) => this.onChangeField(event, 'grade_level')} />
                        </div>

                        <div style={{ flex: 2 }}>
                            <DateInput id="birth_date" label="Birth Date (m/d/y)" value={this.state.birth_date}
                                onChange={(event) => this.onChangeDate(event, 'birth_date')} />
                        </div>

                    </div>

                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, paddingTop: 16 }}>
                            <HeightInput change={(event, value) => {
                                this.onChangeFieldValue(event, 'height_in_inches', value)
                            }} />
                        </div>
                        <div style={{ flex: 1, marginLeft: 5 }}>
                            <WeightInput change={(event, value) => {
                                this.onChangeFieldValue(event, 'weight_in_ounces', value)
                            }} />
                        </div>
                    </div>
                    <Button variant="contained" color="primary" type="button" onClick={() => this.setState({ ...this.state, step: 2 })}> NEXT </Button>
                </div>}

                {this.state.step === 2 && <div>
                    <div style={{ flexGrow: 1 }}>
                        <h3>Step 2 of 2: Invites</h3>
                        <h4>Enter the email addresses of the people to add to this player.</h4>
                        <ChipInput
                            blurBehavior={'add'}
                            allowDuplicates={false}
                            fullWidth={true}
                            label={'Email(s)'}
                            value={this.state.emails}
                            newChipKeyCodes={[13, 32]}
                            newChipKeys={['Enter', 'Space', 'SpaceBar']}
                            onBeforeAdd={(email) => { return (checkEmail(email.toLowerCase()) ? true : false) }}
                            onAdd={(email) => this.onAddEmail(email)}
                            onDelete={(email, index) => this.onRemoveEmail(email, index)}
                        />
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                        <CheckPanel change={this.onCheck('modify_participant_allowed')} label='Update Participant' checked={this.state.modify_participant_allowed} />

                        <CheckPanel change={this.onCheck('invite_others_allowed')} label='Invite Others' checked={this.state.invite_others_allowed} />

                    </div>

                    <div style={{ paddingTop: 10 }}>
                        <Button disabled={this.state.loading} style={{ marginRight: 5 }} variant="contained" color="secondary" type="button" onClick={() => this.setState({ ...this.state, step: 1 })}> BACK </Button>
                        <SubmitButton disabled={this.state.loading} click={this.submitHandler} > SUBMIT </SubmitButton>
                    </div>
                </div>}

            </>
        );

        return (
            <Dialog fullWidth={true} maxWidth={'sm'} onClose={this.onClose} open={this.props.open} disableBackdropClick>
                <DialogAppBar title={'New Player'} close={this.onClose} />
                <DialogContent id="player-content">
                    {form}
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: getToken(state),
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.user.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        user: state.auth.user,
        member_team: state.member_teams.member_team,
        team_member: state.team_members.team_member,
        team: state.teams.team,
        season: state.seasons.season,
        grade_levels: state.enums.grade_levels,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateParticipant));
