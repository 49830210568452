import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import { grey, red } from "@material-ui/core/colors";
import SignIn from './containers/Auth/SignIn';
import Logout from './containers/Auth/Logout/Logout';
import ProfileDialog from './containers/Auth/ProfileDialog';
import * as actions from './store/actions/index';
import Home from "./containers/Home/Home";
import SignUp from "./containers/Auth/SignUp";
import Change from "./containers/Auth/Change";
import Quick from "./containers/Auth/Quick";
import Verify from "./containers/Auth/Verify";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import MemberInvite from "./containers/Home/MemberInvite";
import ParticipantInvite from "./containers/Home/ParticipantInvite";
import CreateTeamDialog from "./containers/Team/Create/Create";
import SideTeam from "./containers/Home/Team/Team";
import Team from "./containers/Team/Team";
import Spinner from "./components/UI/Spinner/Spinner";
import ImageComponent from "./components/UI/ImageComponent/index";
import "./App.css";
import { getToken, fieldSorter } from './components/functions';
import Snackbar from '@material-ui/core/Snackbar';
import { updateSelectedTeam } from './store/actions/auth';

class App extends Component {
    touch = 0;

    componentDidMount() {
        this.touch = setInterval(() => {
            this.props.onTouch(this.props.token);
        }, (60 * 1000 * 1));

        setTimeout(() => {
            this.props.loadEnums();
        }, 1000);

        this.props.onTryAutoSignup();
    }

    componentWillUnmount() {
        this.props.onTryAutoSignup();

        clearInterval(this.touch);
    }

    onSideNav = (team) => {
        this.props.onUpdateSelectedTeam(team)
    }

    render() {
        const theme = createMuiTheme({
            palette: {
                primary: red,
                secondary: grey,
            },
        });

        let routes = (
            <Switch>
                <Route path="/change/:token" component={Change} />
                <Route path="/quick/:token" component={Quick} />
                <Route path="/member_invite/:token" component={MemberInvite} />
                <Route path="/participant_invite/:token" component={ParticipantInvite} />
                <Route path="/verify/:token" component={Verify} />
                <Route path="/forgot/:token" component={ForgotPassword} />
                <Route path="/sign-up" component={SignUp} />
                <Route path="/" component={SignIn} />
                <Redirect to="/" />
            </Switch>
        );

        if (this.props.isAuthenticated) {
            routes = (
                <Switch>
                    <Route path="/change/:token" component={Change} />
                    <Route path="/member_invite/:token" component={MemberInvite} />
                    <Route path="/participant_invite/:token" component={ParticipantInvite} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/:team_id"
                        render={(props) => (<Team key={`${props.match.params.team_id}`} {...props} />)}
                    />
                    <Route path="/" exact component={Home} />
                    <Redirect to="/" />
                </Switch>
            );
        }

        let layout = <Spinner />

        if (!this.props.cookie) {
            if (this.props.isAuthenticated) {
                let teams = this.props.teams.sort(fieldSorter(['-last_entered_on', 'team_title', 'team_id']));

                let active
                let list = []

                if (teams && teams.length > 0) {
                    active = teams[0]

                    for (let i = 1; i < teams.length; i++) {
                        list.push(teams[i])
                    }
                }

                let team_nav
                if (list && list.length > 0) {
                    team_nav = list.map(team => {
                        return <SideTeam key={team.team_id} team={team} active={active} click={(team) => this.onSideNav(team)} />
                    })
                }

                layout =
                    <div style={{ justifyContent: 'center', display: 'flex', height: '100vh', width: '100%' }}>
                        <div className={'Outer'} style={{ height: '100vh' }}>
                            <div className={'Inner'} style={{ background: '#212121' }}>
                                <div style={{ width: 43, height: 65, background: 'black', marginBottom: 15 }}>
                                    {active && <SideTeam team={active} active={active} click={(team) => this.onSideNav(team)} />}
                                </div>
                                {team_nav}
                                <div onClick={this.props.openTeamCreate} className="AddTeam">
                                    +
                                </div>
                            </div>
                            <div style={{ background: 'black', height: 43 }}>
                                <div style={{ margin: 3, padding: 2, width: 33, height: 33, cursor: "pointer" }}
                                    onClick={this.props.openProfile}>
                                    <ImageComponent first={this.props.user.member.first_name} last={this.props.user.member.last_name} image_url={this.props.user.member.image_url} />
                                </div>
                            </div>
                        </div>
                        <div style={{ flexGrow: 1, background: '#BDBDBD' }}>
                            {routes}
                        </div>
                    </div>
            } else {
                layout =
                    <div style={{ display: 'flex', minHeight: '100vh', minWidth: '100%' }}>
                        <div style={{ flexGrow: 1, background: '#BDBDBD' }}>
                            {routes}
                        </div>
                    </div>
            }
        }

        return <ThemeProvider theme={theme}>
            {layout}
            <CreateTeamDialog open={this.props.new_team} />
            <ProfileDialog open={this.props.profile} />
            {this.props.touch_message && <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={!this.props.touch}
                message={this.props.touch_message}
            />}
        </ThemeProvider>
    }
}

const mapStateToProps = state => {
    const { auth } = state;
    const { user } = auth ? auth : {};
    const { teams } = user ? user : {};

    return {
        token: getToken(state),
        cookie: state.auth.cookie,
        loading: state.auth.loading,
        user: state.auth.user,
        session: state.auth.session,
        isAuthenticated: state.auth.user !== null,
        primary: red,
        secondary: grey,
        teams: teams,
        team: state.teams.team,
        new_team: state.teams.new_team === true,
        profile: state.auth.profile === true,
        touch: state.auth.touch,
        touch_message: state.auth.touch_message,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadEnums: () => dispatch(actions.loadEnums()),
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        openTeamCreate: () => dispatch(actions.openTeamCreateAction()),
        openProfile: () => dispatch(actions.openProfileAction()),
        onTouch: (session) => dispatch(actions.onTouch(session)),
        onUpdateSelectedTeam: (team) => dispatch(updateSelectedTeam(team)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
